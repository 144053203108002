import React, { Component } from 'react'
import MPheaderButton from '../../components/MPheaderButton'
import Form from 'react-bootstrap/Form'
import { Container, Row, Col } from 'reactstrap'
import LegalPositionTable from '../../components/DataTable/LegalPositionTable'

export default class PositionLegal extends Component {
    constructor(props){
        super(props)
        this.state={
            nome:"",
            contatto:"",
            company:"",
            stato:"",
            direzione:"",
            foro:"",
            diritto:"",
            livello:"",
            nazione:"",
            autore:"",
            assegnato:"",
            ris:""
        }
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event){
        const{name,value,type,checked}=event.target
        type === "checkbox"?this.setState({[name]:checked}):this.setState({[name]:value})
    }
    handleSubmit=(event)=>{

    }
    render() {
        return (
            <div>
                <MPheaderButton link="./Position/Aggiungi Position" nome="Aggiungi Position"/> 
                <Form onSubmit={this.handleSubmit}>
                    <Form.Row className="Col_margin py-2 px-1">
                        <Col md={3} className="Col_margin px-1">
                            <Form.Label className="checktext">Nome</Form.Label>
                            <Form.Control type="text" placeholder="Nome" onChange={this.handleChange} value={this.state.nome} name="nome"/>
                        </Col>
                        <Col md={3} className="Col_margin px-1">
                            <Form.Label className="checktext">Contatto</Form.Label>
                            <Form.Control type="text" placeholder="Contatto" onChange={this.handleChange} value={this.state.contatto} name="contatto"/>
                        </Col>
                        <Col md={3} className="Col_margin px-1">
                            <Form.Label className="checktext">Company</Form.Label>
                            <Form.Control as="select" onChange={this.handleChange} value={this.state.company} name="company">
                                <option value="Company">Company</option>
                            </Form.Control>
                        </Col>
                        <Col md={3} className="Col_margin px-1">
                            <Form.Label className="checktext">Stato</Form.Label>
                            <Form.Control as="select" onChange={this.handleChange} value={this.state.stato} name="stato">
                                <option value="Stato">Stato</option>
                            </Form.Control>
                        </Col>
                    </Form.Row>
                    <Form.Row className="Col_margin py-2 px-1">
                        <Col md={3} className="Col_margin px-1">
                            <Form.Label className="checktext">Direzione</Form.Label>
                            <Form.Control as="select" onChange={this.handleChange} value={this.state.direzione} name="direzione">
                                <option value="Direzione">Direzione</option>
                            </Form.Control>
                        </Col>
                        <Col md={3} className="Col_margin px-1">
                            <Form.Label className="checktext">Foro</Form.Label>
                            <Form.Control as="select" onChange={this.handleChange} value={this.state.foro} name="foro">
                                <option value="Foro">Foro</option>
                            </Form.Control>
                        </Col>
                        <Col md={3} className="Col_margin px-1">
                            <Form.Label className="checktext">Diritto</Form.Label>
                            <Form.Control as="select" onChange={this.handleChange} value={this.state.diritto} name="diritto">
                                <option value="Diritto">Diritto</option>
                            </Form.Control>
                        </Col>
                        <Col md={3} className="Col_margin px-1">
                            <Form.Label className="checktext">Livello</Form.Label>
                            <Form.Control as="select" onChange={this.handleChange} value={this.state.livello} name="livello">
                                <option value="Livello">Livello</option>
                            </Form.Control>
                        </Col>
                    </Form.Row>
                    <Form.Row className="Col_margin py-2 px-1">
                        <Col md={3} className="Col_margin px-1">
                            <Form.Label className="checktext">Nazione</Form.Label>
                            <Form.Control as="select" onChange={this.handleChange} value={this.state.nazione} name="nazione">
                                <option value="Nazione">Nazione</option>
                            </Form.Control>
                        </Col>
                        <Col md={3} className="Col_margin px-1">
                            <Form.Label className="checktext">Autore</Form.Label>
                            <Form.Control as="select" onChange={this.handleChange} value={this.state.autore} name="autore">
                                <option value="Autore">Autore</option>
                            </Form.Control>
                        </Col>
                        <Col md={3} className="Col_margin px-1">
                            <Form.Label className="checktext">Assegnato a</Form.Label>
                            <Form.Control as="select" onChange={this.handleChange} value={this.state.assegnato} name="assegnato">
                                <option value="Assegnato">Assegnato</option>
                            </Form.Control>
                        </Col>
                        <Col md={3} className="Col_margin px-1">
                            <Form.Label className="checktext">Risultato per pagina</Form.Label>
                            <Form.Control as="select" onChange={this.handleChange} value={this.state.ris} name="ris">
                                <option value="10">10</option>
                                <option value="100">100</option>
                                <option value="1000">1000</option>
                            </Form.Control>
                        </Col>
                    </Form.Row>
                    <Form.Row className="Col_margin px-1 py-2">
                        <button className="myButton" type="submit">Filtra</button>
                    </Form.Row>
                </Form>
                <Container fluid>
                    <Row className="Col_margin px-1">
                        <LegalPositionTable/>
                    </Row>
                </Container>
            </div>
        )
    }
}
