import React, { Component } from 'react'
import 'antd/dist/antd.css';
import Form from 'react-bootstrap/Form'
import {Container,Row,Col} from 'react-grid-system';
import MPheaderButton from '../../components/MPheaderButton'

export default class AggiungiOpportunità extends Component {
    constructor(props){
        super(props)
        this.state={
            nome:"",
            genere:"",
            lingua:"",
            professione:"",
            settore:"",
            jobposition:"",
            privato:"",
            nazione:"",
            tipologyEM:"",
            tipologyPM:"",
            tag:"",
            sector:"",
        }
        this.handleChange = this.handleChange.bind(this)
    }
    handleChangeDue(value, formattedValue) {
        this.setState({
          value: value, // ISO String, ex: "2016-11-19T12:00:00.000Z"
          formattedValue: formattedValue // Formatted String, ex: "11/19/2016"
        })
      }
    handleChange(event){
        const{name,value,type,checked}=event.target
        type === "checkbox"?this.setState({[name]:checked}):this.setState({[name]:value})
    }
    handleSubmit=(event)=>{
        event.preventDefault()
        const data = this.state
        
        console.log(data)
    }
    render() {
        return (
            <div>
                <MPheaderButton link="./Aggiungi PDI" nome="Aggiungi PDI"/>
                <Container fluid className="MainPageRow2">
                    <Row className="Col_margin py-4 px-2">
                        <Col className="Col_margin px-1">
                            <Form onSubmit={this.handleSubmit}>
                                <Form.Row>
                                    <Col md={12} className="Col_margin px-1">
                                        <Form.Label className="checktext">Nome PDI</Form.Label>
                                        <Form.Control type="text" placeholder="" onChange={this.handleChange} value={this.state.nome} name="nome"/>
                                    </Col>
                                    
                                </Form.Row>              
                                <Form.Row>
                                    <Col md={4} className="Col_margin px-1">
                                        <Form.Label className="checktext">Genere</Form.Label>
                                        <Form.Control type="text" placeholder="" onChange={this.handleChange} value={this.state.genere} name="genere"/>
                                    </Col>
                                    <Col md={4} className="Col_margin px-1">
                                        <Form.Label className="checktext">Lingua</Form.Label>
                                        <Form.Control type="text" placeholder="" onChange={this.handleChange} value={this.state.lingua} name="lingua"/>
                                    </Col>
                                    <Col md={4} className="Col_margin px-1">
                                        <Form.Label className="checktext">Professione</Form.Label>
                                        <Form.Control type="text" placeholder="" onChange={this.handleChange} value={this.state.professione} name="professione"/>
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Col md={4} className="Col_margin px-1">
                                        <Form.Label className="checktext">Settore</Form.Label>
                                        <Form.Control type="text" placeholder="" onChange={this.handleChange} value={this.state.settore} name="settore"/>
                                    </Col>
                                    <Col md={4} className="Col_margin px-1">
                                        <Form.Label className="checktext">Job Position</Form.Label>
                                        <Form.Control type="text" placeholder="" onChange={this.handleChange} value={this.state.jobposition} name="jobposition"/>
                                    </Col>
                                    <Col md={4} className="Col_margin px-1">
                                        <Form.Label className="checktext">Privato o Azienda</Form.Label>
                                        <Form.Control type="text" placeholder="" onChange={this.handleChange} value={this.state.privato} name="privato"/>
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Col md={4} className="Col_margin px-1">
                                        <Form.Label className="checktext">Nazione</Form.Label>
                                        <Form.Control type="text" placeholder="" onChange={this.handleChange} value={this.state.nazione} name="nazione"/>
                                    </Col>
                                    <Col md={4} className="Col_margin px-1">
                                        <Form.Label className="checktext">Tipology - Enry's Model</Form.Label>
                                        <Form.Control type="text" placeholder="" onChange={this.handleChange} value={this.state.tipologyEM} name="tipologyEM"/>
                                    </Col>
                                    <Col md={4} className="Col_margin px-1">
                                        <Form.Label className="checktext">Tipology - Parametro</Form.Label>
                                        <Form.Control type="text" placeholder="" onChange={this.handleChange} value={this.state.tipologyPM} name="tipologyPM"/>
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Col md={4} className="Col_margin px-1">
                                        <Form.Label className="checktext">Tag</Form.Label>
                                        <Form.Control type="text" placeholder="" onChange={this.handleChange} value={this.state.tag} name="tag"/>
                                    </Col>
                                    <Col md={4} className="Col_margin px-1">
                                        <Form.Label className="checktext">Sector</Form.Label>
                                        <Form.Control type="text" placeholder="" onChange={this.handleChange} value={this.state.sector} name="sector"/>
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Col md={3} className="Col_margin py-5"> 
                                        <button className="myButton" type="submit" style={{padding:"3px 150px"}}>Salva</button>
                                    </Col>
                                </Form.Row>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}