import React, { Component } from 'react'
import 'antd/dist/antd.css';
import Form from 'react-bootstrap/Form'
import {Container,Row,Col} from 'react-grid-system';
import MPheader from '../../components/MPheader'
import '../../index.css'



export default class AggiungiAccount extends Component {
    constructor(props){
        super(props)
        this.state={
            nome:"",
            mittenteNome:"",
            mittenteEmail:"", 
            host:"",
            username:"",
            password:""

        }
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event){
        const{name,value,type,checked}=event.target
        type === "checkbox"?this.setState({[name]:checked}):this.setState({[name]:value})
    }
    handleSubmit=(event)=>{
        event.preventDefault()
        const data = this.state
        
        console.log(data)
    }
    render() {
        return (
            <div>
                <MPheader/>
                <Container fluid className="MainPageRow2">
                    <Row className="Col_margin py-1 px-2">
                        <Col md={12}  className="Col_margin py-5 px-1">
                            <p className="checktext">NB1: Su alcuni server SMTP più restrittivi l’email del mittente e lo username devono coincidere<br/>
                            Su Server Plan, per fortuna, possiamo usare noreply@XXX (con relativa password), per inviare email del tipo utente1@XXX, utente2@XXX (senza che questi debbano esistere)</p>
                        </Col>
                    </Row>
                    <Row className="Col_margin py-1 px-2">
                        <Col md={12}  className="Col_margin py-5 px-1">
                            <p className="checktext">NB2: Su alcuni server SMTP la password è facoltativa<br/>
                            Su Server Plan è sempre obbligatoria</p>
                        </Col>
                    </Row>
                    <Row className="Col_margin py-4 px-2">
                        <Col className="Col_margin px-1">
                            <Form onSubmit={this.handleSubmit}>
                                <Form.Row>
                                    <Col md={4} className="Col_margin py-1 px-1">
                                        <Form.Label className="checktext">Nome</Form.Label>
                                        <Form.Control type="text" placeholder="" onChange={this.handleChange} value={this.state.nome} name="nome"/>
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Col md={4} className="Col_margin py-1 px-1">
                                        <Form.Label className="checktext">Mittente (Nome)</Form.Label>
                                        <Form.Control type="text" placeholder="" onChange={this.handleChange} value={this.state.mittenteNome} name="mittenteNome"/>
                                    </Col>
                                    <Col md={4} className="Col_margin py-1 px-1">
                                        <Form.Label className="checktext">Mittente (Email) (facoltativo)</Form.Label>
                                        <Form.Control type="text" placeholder="" onChange={this.handleChange} value={this.state.mittenteEmail} name="mittenteEmail"/>
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Col md={4} className="Col_margin py-1 px-1">
                                        <Form.Label className="checktext">Host</Form.Label>
                                        <Form.Control type="text" placeholder="" onChange={this.handleChange} value={this.state.host} name="host"/>
                                    </Col>
                                    <Col md={4} className="Col_margin py-1 px-1">
                                        <Form.Label className="checktext">Username</Form.Label>
                                        <Form.Control type="email" placeholder="" onChange={this.handleChange} value={this.state.username} name="username"/>
                                    </Col>
                                    <Col md={4} className="Col_margin py-1 px-1">
                                        <Form.Label className="checktext">Password (facoltativo su alcuni server)</Form.Label>
                                        <Form.Control type="pasword" placeholder="" onChange={this.handleChange} value={this.state.password} name="password"/>
                                    </Col>
                                </Form.Row>
                            
                                <Form.Row>
                                    <Col md={3} className="Col_margin py-5"> 
                                        <button className="myButton" type="submit" style={{padding:"3px 150px"}}>Salva</button>
                                    </Col>
                                </Form.Row>
                            </Form>
                        </Col>
                    </Row> 
                </Container>
            </div>
        )
    }
}


