import React, { Component } from 'react'
import { Container, Row, Col } from 'react-grid-system';

export default class MarketDiv extends Component {
    constructor(props){
        super(props)
        this.state={}
    }
    render() {
        return (
            <Col md={6} className="Col_margin px-1 py-2">
                <div className="marketDiv">
                    <div className="immagine">
                        Attività: {this.props.attività} <br/>
                        Assets  : {this.props.assets}
                    </div>
                    <div className="testi"><a href="#/#">HUI</a></div>
                    <div className="titolo"><a href="#/#">{this.props.titolo}<br/>{this.props.costo}</a></div>
                    <div className="descrizione">{this.props.descrizione}</div>
                    <div className="descrizione"></div>
                    <button className="myButton" type="submit" style={{marginLeft:"20px",marginBottom:"20px",padding:"3px 70px"}}>Aquista</button>
                </div>
            </Col>
        )
    }
}
