import React, { Component } from 'react'
import MPheaderButton from '../../components/MPheaderButton'
import MappaGoogle from '../../components/MappaGoogle'
import { Container, Row, Col } from 'reactstrap'
import Form from 'react-bootstrap/Form'
import InfluencePRMTable from '../../components/DataTable/InfluencePRMTable'

export default class PRMMap extends Component {
    constructor(props){
        super(props)
        this.state={
            nome:"",
            email:"",
            assets:"",
            parametro1:"",
            parametro2:"",
            tag:"",
            settori:"",
            reachnome:"",
            reachcampo:"",
            reachetaDA:"",
            reachetaA:"",
            reachaudienceDA:"",
            reachaudienceA:"",
            nazione:"",
            ris:""
        }
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event){
        const{name,value,type,checked}=event.target
        type === "checkbox"?this.setState({[name]:checked}):this.setState({[name]:value})
    }
    handleSubmit=(event)=>{

    }
    render() {
        return (
            <div>
                <MPheaderButton link="./PRM Map/Aggiungi Contatto" nome="Aggiungi Contatto"/>
                <Container fluid>
                    <Row className="Col_margin py-4 px-1">
                        <Col className="Col_margin px-1" md={6}><MappaGoogle/></Col>
                        <Col className="Col_margin px-1" md={6}>
                            <Form onSubmit={this.handleSubmit}>
                                <Form.Row className="Col_margin py-2 px-1">
                                    <Col md={6} className="Col_margin px-1">
                                        <Form.Label className="checktext">Nome</Form.Label>
                                        <Form.Control typ  e="text" placeholder="Nome" onChange={this.handleChange} value={this.state.nome} name="nome"/>
                                    </Col>
                                    <Col md={6} className="Col_margin px-1">
                                        <Form.Label className="checktext">Email</Form.Label>
                                        <Form.Control type="text" placeholder="Email" onChange={this.handleChange} value={this.state.email} name="email"/>
                                    </Col>
                                </Form.Row>
                                <Form.Row className="Col_margin py-2 px-1">
                                    <Col md={3} className="Col_margin px-1">
                                        <Form.Label className="checktext">Asset</Form.Label>
                                        <Form.Control as="select" onChange={this.handleChange} value={this.state.asset} name="asset">
                                            <option value="Asse">Asse</option>
                                        </Form.Control>
                                    </Col>
                                    <Col md={3} className="Col_margin px-1">
                                        <Form.Label className="checktext">Parametro 1</Form.Label>
                                        <Form.Control as="select" onChange={this.handleChange} value={this.state.parametro1} name="parametro1">
                                            <option value=""></option>
                                        </Form.Control>
                                    </Col>
                                    <Col md={3} className="Col_margin px-1">
                                        <Form.Label className="checktext">Parametro 2</Form.Label>
                                        <Form.Control as="select" onChange={this.handleChange} value={this.state.parametro2} name="parametro2">
                                            <option value=""></option>
                                        </Form.Control>
                                    </Col>
                                </Form.Row>
                                <Form.Row className="Col_margin py-2 px-1">
                                    <Col md={12} className="Col_margin px-1">
                                        <Form.Label className="checktext">Tag</Form.Label>
                                        <Form.Control type="text" placeholder="Tag" onChange={this.handleChange} value={this.state.tag} name="tag"/>
                                    </Col>
                                    <Col md={12} className="Col_margin px-1">
                                        <Form.Label className="checktext">Settori</Form.Label>
                                        <Form.Control type="text" placeholder="Settori" onChange={this.handleChange} value={this.state.settori} name="settori"/>
                                    </Col>
                                </Form.Row>
                                <Form.Row className="Col_margin py-2 px-1">
                                <Col md={6} className="Col_margin px-1">
                                        <Form.Label className="checktext">Reach-Nome</Form.Label>
                                        <Form.Control as="select" onChange={this.handleChange} value={this.state.reachnome} name="reachnome">
                                            <option value=""></option>
                                        </Form.Control>
                                    </Col>
                                    <Col md={6} className="Col_margin px-1">
                                        <Form.Label className="checktext">Reach-Campo</Form.Label>
                                        <Form.Control as="select" onChange={this.handleChange} value={this.state.reachcampo} name="reachcampo">
                                            <option value=""></option>
                                        </Form.Control>
                                    </Col>
                                </Form.Row>
                                <Form.Row className="Col_margin py-2 px-1">
                                    <Col md={3} className="Col_margin px-1">
                                        <Form.Label className="checktext">Reach-Eta(DA)</Form.Label>
                                        <Form.Control type="number" placeholder="" onChange={this.handleChange} value={this.state.reachetaDA} name="reachetaDA"/>
                                    </Col>
                                    <Col md={3} className="Col_margin px-1">
                                        <Form.Label className="checktext">Reach-Eta(A)</Form.Label>
                                        <Form.Control type="number" placeholder="" onChange={this.handleChange} value={this.state.reachetaA} name="reachetaA"/>
                                    </Col>
                                    <Col md={3} className="Col_margin px-1">
                                        <Form.Label className="checktext">Reach-Audience(per mese)(Da)</Form.Label>
                                        <Form.Control type="number" placeholder="" onChange={this.handleChange} value={this.state.reachaudienceDA} name="reachaudienceDA"/>
                                    </Col>
                                    <Col md={3} className="Col_margin px-1">
                                        <Form.Label className="checktext">Reach-Audience(per mese)(A)</Form.Label>
                                        <Form.Control type="number" placeholder="" onChange={this.handleChange} value={this.state.reachaudienceA} name="reachaudienceA"/>
                                    </Col>
                                </Form.Row>
                                
                                <Form.Row className="Col_margin py-2 px-1">
                                <Col md={6} className="Col_margin px-1">
                                        <Form.Label className="checktext">Nazione</Form.Label>
                                        <Form.Control as="select" onChange={this.handleChange} value={this.state.nazione} name="nazione">
                                            <option value="Tutte le Nazioni">Tutte le Nazioni</option>
                                        </Form.Control>
                                    </Col>
                                <Col md={6} className="Col_margin px-1">
                                
                                        <Form.Label className="checktext">Risultati per Pagina</Form.Label>
                                        <Form.Control as="select" onChange={this.handleChange} value={this.state.ris} name="ris">
                                            <option value="10">10</option>
                                            <option value="100">100</option>
                                            <option value="1000">1000</option>
                                        </Form.Control>
                                </Col>
                                </Form.Row>
                                <Form.Row className="Col_margin px-1 py-2">
                                    <button className="myButton" type="submit">Filtra</button>
                            </Form.Row>
                            </Form>
                        </Col>
                    </Row>
                    <Row className="Col_margin px-1">
                        <InfluencePRMTable/>
                    </Row>
                </Container>
                
            </div>
        )
    }
}
