import React, { Component } from 'react'
import MPheader from '../../components/MPheader'
import { Container, Row, Col } from 'reactstrap'
import Form from 'react-bootstrap/Form'
import Grafico from '../../components/Sales/Grafico'
import nazioneJson from '../../Data/Form/Nazione.json'

export default class Provvigioni extends Component {
    constructor(props){
        super(props)
        this.state={
            listino:"",
            nazione:"",
            inizio:"",
            fine:"",
            aggrega:"",
        }
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event){
        const{name,value,type,checked}=event.target
        type === "checkbox"?this.setState({[name]:checked}):this.setState({[name]:value})
    }
    handleSubmit=(event)=>{
        event.preventDefault()
        const data = this.state
        console.log(data)
    }
    render() {
        let nazioneOption = nazioneJson;
        return (
            <div>
                <MPheader/>

                <Container fluid>
                            <Form onSubmit={this.handleSubmit}>
                                <Form.Row className="Col_margin py-2 px-1">
                                    <Col md={3} className="Col_margin px-1">
                                        <Form.Label className="checktext">Listino</Form.Label>
                                        <Form.Control as="select" onChange={this.handleChange} value={this.state.listino} name="listino">
                                            <option value="Tutti i Listini">Tutti i Listini</option>
                                        </Form.Control>
                                    </Col>
                                    <Col md={3} className="Col_margin px-1">
                                        <Form.Label className="checktext">Nazione</Form.Label>
                                        <Form.Control as="select" onChange={this.handleChange} value={this.state.nazione} name="nazione">
                                            {nazioneOption.map(o => <option key={o.id} value={o.id}>{o.text}</option>)}
                                        </Form.Control>
                                    </Col>
                                </Form.Row>
                                <Form.Row className="Col_margin py-2 px-1">
                                    <Col md={3} className="Col_margin px-1">
                                        <Form.Label className="checktext">Aggrega</Form.Label>
                                        <Form.Control as="select" onChange={this.handleChange} value={this.state.aggrega} name="aggrega">
                                            <option value="giorno">giorno</option>
                                            <option value="settimana">settimana</option>
                                            <option value="mese">mese</option>
                                            <option value="trimestre">trimestre</option>
                                            <option value="anno">anno</option>
                                        </Form.Control>
                                    </Col>
                                </Form.Row>
                                <Form.Row className="Col_margin px-1 py-2">
                                    <button className="myButton" type="submit" style={{padding:"3px 150px"}}>Filtra</button>
                            </Form.Row>
                            </Form>       
                </Container>
                <Container>
                    <Row className="Col_margin py-2 px-1">
                        <Col className="Col_margin px-1">
                            <Grafico/>
                        </Col>
                    </Row>
                </Container>
                
            </div>
        )
    }
}