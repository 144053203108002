import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import '../../index.css';
import MPheader from '../../components/MPheader'
import TextForm from '../../components/Forms/TextForm';
import SelectDa4 from '../../components/Forms/SelectDa4'
import SelectDa3 from '../../components/Forms/SelectDa3'
import Form from 'react-bootstrap/Form'
import Intelligence from '../../components/Intelligence/Intelligence'
import CollapseIntelligence from '../../components/Intelligence/CollapseIntelligence'







class Overview extends React.Component{
    handleSubmit(){
    }
    render(){
        return(
            <div>
            <MPheader/>  
            <Container fluid className="MainPageRow2">
                <Form onSubmit={this.handleSubmit}>
                    <Form.Row className="Col_margin py-2 px-1">
                        <Col md={4} className="Col_margin px-1">
                            <TextForm nome="Ricerca Azienda"/>
                        </Col>
                    </Form.Row>
                    <Form.Row className="Col_margin py-1 px-1">
                        <Col md={3} className="Col_margin px-1">
                            <SelectDa3 nome="Filtra per Azienda" option1="" option2="Enry's Island Italia" option3="HUI" id="azienda"/>
                        </Col>
                        <Col md={3} className="Col_margin px-1">
                            <SelectDa3 nome="Filtra per Plan" option1="" option2="Hui Analycitcs" option3="Prova e" id="plan" />
                        </Col>
                        <Col md={3} className="Col_margin px-1">
                            <SelectDa4 nome="Filtra per per Foglio" option1="All" option2="Ali Toccacieli" option3="autore2" option4="autore3"/>
                        </Col>
                    </Form.Row>
                    <Form.Row className="Col_margin py-1 px-1">
                        <Col md={3} className="Col_margin px-1">
                            <SelectDa4 nome="Filtra per Metrica" option1="All" option2="Ali Toccacieli" option3="persona2" option4="persona3"/>
                        </Col>
                        <Col md={3} className="Col_margin px-1">
                            <SelectDa4 nome="Filtra per Grafico" option1="All" option2="Ali Toccacieli" option3="persona2" option4="persona3"/>
                        </Col>
                        <Col md={3} className="Col_margin px-1">
                            <SelectDa3 nome="Risultati per pagina" option1="10" option2="100" option3="1000"/>    
                        </Col>
                    </Form.Row>
                    <Form.Row className="Col_margin px-1 py-2">
                        <button className="myButton" type="submit" style={{padding:"3px 150px"}}>Filtra</button>
                    </Form.Row>
                </Form>
                <CollapseIntelligence/>
                
            </Container>
            </div> 
            
            )
        }
                    
    }
export default Overview
