import React, { Component } from 'react'
import { Container, Row ,Col} from 'reactstrap'
import NuovaIsolaDiv1 from '../components/Island/NuovaIsolaDiv1'
import NuovaIsolaDiv2 from '../components/Island/NuovaIsolaDiv2'
import MPheader from '../components/MPheader'

export default class NuovaIsola extends Component {
    constructor() {
        super();
        this.state = {
          name: "React",
          showHide1: true,
          showHide2: false
        };
        this.hideComponent = this.hideComponent.bind(this);
      }

      hideComponent(name) {
        console.log(name);
        switch (name) {
          case "showHide1":
            this.setState({ showHide1: true,showHide2: false });
            break;
          case "showHide2":
            this.setState({ showHide2: true,showHide1: false });
            break;
          default:
            this.setState({ showHide1: true });
            break;
        }
      }
    render() {
        const { showHide1, showHide2} = this.state;
        return (
            <div>
                <MPheader></MPheader>
                <Container fluid>
                    <Row className="Col_margin py-5">
                        <Col  className="Col_margin px-1 py-2" xl="content">
                            <button type="button" className="bottoneIsola"  onClick={() => this.hideComponent("showHide1")}>Informazioni Island</button>
                        </Col>
                        <Col  className="Col_margin px-1 py-2" xl="content">
                            <button type="button" className="bottoneIsola"  onClick={() => this.hideComponent("showHide2")}>Edit Cover Island</button>
                        </Col>
                    </Row>
                    <Row className="Col_margin">
                        <Col className="Col_margin">{showHide1 && <NuovaIsolaDiv1/>}{showHide2 && <NuovaIsolaDiv2/>}</Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
