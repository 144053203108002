import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import MPheader from '../components/MPheader'
import MPbenvenuto from '../components/MPbenvenuto'
import { Select } from 'semantic-ui-react'
//import AAADataTable from '../components/DataTable/AAADataTable'

import Form from 'react-bootstrap/Form'
import '../index.css';
import assegnatoJson from '../Data/Ui/assegnato.json'
import statoJson from '../Data/Ui/stato.json'
import filtraJson from '../Data/Ui/filtra.json'
import vistaJson from '../Data/Ui/vista.json'

class MainPage extends React.Component{
    constructor(props){
        super(props)
        this.state={

            assegnato:"",
            stato:"",
            filtra:"",
            vista:"",
            influence:true,
            fundraiser:true,
            sales:true,
            legal:true,
            suppliers:true,
            intelligence:true,
            project:true,
            board:true

        }
        this.handleChange = this.handleChange.bind(this)
        this.handleChangeUi = this.handleChangeUi.bind(this)
    }
    handleChangeUi = (e, result) => {
        const { name, value } = result;
        this.setState({
           [name]: value
          });
      };
    handleChange(event){
        const{name,value,type,checked}=event.target
        type === "checkbox"?this.setState({[name]:checked}):this.setState({[name]:value})
    }

    handleSubmit=(event)=>{
        event.preventDefault()
        const data = this.state
        console.log(data)
    }
    render(){
        return(
            <div>
            <MPheader/>
            <MPbenvenuto nome="Ali Toccacieli" eventi="0" eventi_totali="20"></MPbenvenuto> 
            <Container fluid className="MainPageRow2">
                        <Form onSubmit={this.handleSubmit}>
                            <Form.Row className="py-4 px-5">
                                <Col md={2} className="py-1 px-1">
                                    <Form.Check type="checkbox" inline className="px-5 checktext" checked={this.state.influence} onChange={this.handleChange} name="influence" label="Influence" />
                                </Col>
                                <Col md={2} className="py-1 px-1">
                                    <Form.Check type="checkbox" inline className="px-5 checktext" checked={this.state.fundraiser} onChange={this.handleChange} name="fundraiser" label="FundRaiser" />
                                </Col>
                                <Col md={2} className="py-1 px-1">
                                    <Form.Check type="checkbox" inline className="px-5 checktext" checked={this.state.sales} onChange={this.handleChange} name="sales" label="Sales" />
                                </Col>
                                <Col md={2} className="py-1 px-1">
                                    <Form.Check type="checkbox" inline className="px-5 checktext" checked={this.state.legal} onChange={this.handleChange} name="legal" label="Legal" />
                                </Col>
                                <Col md={2} className="py-1 px-1">
                                    <Form.Check type="checkbox" inline className="px-5 checktext" checked={this.state.suppliers} onChange={this.handleChange} name="suppliers" label="Suppliers" />
                                </Col>
                                <Col md={2} className="py-1 px-1">
                                    <Form.Check type="checkbox" inline className="px-5 checktext" checked={this.state.intelligence} onChange={this.handleChange} name="intelligence" label="Intelligence" />
                                </Col>
                            </Form.Row>
                            <Form.Row className="py-1 px-5">
                                <Col md={2} className="py-1 px-1">
                                    <Form.Check type="checkbox" inline className="px-5 checktext" checked={this.state.board} onChange={this.handleChange} name="board" label="Board" />
                                </Col>
                                <Col md={2} className="py-1 px-1">
                                    <Form.Check type="checkbox" inline className="px-5 checktext" checked={this.state.project} onChange={this.handleChange} name="project" label="Projects" />
                                </Col>

                            </Form.Row>
                            <Form.Row className="py-4 px-5 ">
                                
                                <Col md={3} className="py-1 px-2" >
                                    <Form.Label className="checktext">Assegnato a</Form.Label>
                                    <Select placeholder="" options={assegnatoJson}  onChange={this.handleChangeUi} name="assegnato" fluid/>
                                </Col>
                                
                                <Col md={3} className="py-1 px-2">
                                    <Form.Label className="checktext">Stato</Form.Label>
                                    <Select placeholder="" options={statoJson}  onChange={this.handleChangeUi} name="stato" fluid/>
                                </Col>
                                <Col md={3} className="py-1 px-2">
                                    <Form.Label className="checktext">Filtra per</Form.Label>
                                    <Select placeholder="" options={filtraJson}  onChange={this.handleChangeUi} name="filtra" fluid/>
                                </Col>
                                <Col md={3} className="py-1 px-2">
                                    <Form.Label className="checktext">Vista</Form.Label>
                                    <Select placeholder="" options={vistaJson}  onChange={this.handleChangeUi} name="vista" fluid/>
                                </Col>
                            </Form.Row>
                            <Form.Row className="py-4 px-5">
                                <button className="myButton" type="submit" style={{padding:"3px 150px"}}>Filtra</button>
                            </Form.Row>
                        </Form>  
            </Container>

            
            
            
            <Container fluid className="MainPageRow2">
                <Row className="py-4 px-2 text-center">
                    <Col md={1.6} className="text-center">
                        <p className="checktext"><b>Lunedi</b></p>
                    </Col>
                    <Col md={1.6} className="text-center">
                        <p className="checktext"><b>Martedi</b></p>
                    </Col>
                    <Col md={1.6} className="text-center">
                        <p className="checktext"><b>Mercoledi</b> </p> 
                    </Col>
                    <Col md={1.6} className="text-center">

                        <p className="checktext"><b>Giovedi</b> </p></Col>

                    <Col md={1.6} className="text-center">
                        <p className="checktext"><b>Venerdi</b></p>
                    </Col>
                    <Col md={1.6} className="text-center">
                        <p className="checktext"><b>Sabato</b></p>
                    </Col>
                    <Col md={1.6} className="text-center">
                        <p className="checktext"><b>Domenica</b></p>
                    </Col>
                </Row>
            </Container>  
            </div> 

            )
        }
                    
    }
export default MainPage
