
import React from 'react'

function imageProfile(props){
    return(
        <div class="circleBase"></div>
    )
}

export default imageProfile



