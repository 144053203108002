import React, { Component } from 'react'
import MPheaderButton from '../../components/MPheaderButton'
import Form from 'react-bootstrap/Form'
import {Col } from 'reactstrap'
import DatePicker from "reactstrap-date-picker";
import {FormGroup, Label} from 'reactstrap'

export default class DBNeed extends Component {
    constructor(props){
        super(props)
        this.state={
            nome:"",
            scadenza:new Date().toISOString(),
            assegnato:""
        }
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event){
        const{name,value,type,checked}=event.target
        type === "checkbox"?this.setState({[name]:checked}):this.setState({[name]:value})
    }
    handleChangeDue(value, formattedValue) {
        this.setState({
          value: value, // ISO String, ex: "2016-11-19T12:00:00.000Z"
          formattedValue: formattedValue // Formatted String, ex: "11/19/2016"
        })
      }
    handleSubmit=(event)=>{
        event.preventDefault()
        const data = this.state
        console.log(data)
    }
    render() {
        return (
            <div>
                <MPheaderButton link="./DB Need/Aggiungi DB Need" nome="Aggiungi DB Need"/> 
                <Form onSubmit={this.handleSubmit}>
                    <Form.Row className="Col_margin py-2 px-1">
                        <Col md={3} className="Col_margin px-1">
                            <Form.Label className="checktext">Nome</Form.Label>
                            <Form.Control type="text" placeholder="Nome" onChange={this.handleChange} value={this.state.nome} name="nome"/>
                        </Col>
                    </Form.Row>
                    <Form.Row className="Col_margin py-2 px-1">
                        <Col md={3} className="Col_margin px-1">
                            <Form.Label className="checktext">Assegnato a</Form.Label>
                            <Form.Control as="select" onChange={this.handleChange} value={this.state.assegnato} name="assegnato">
                                <option value="Chiunque">Chiunque</option>
                            </Form.Control>
                        </Col>
                        <Col md={3} className="Col_margin px-1">
                            <FormGroup>
                                <Label className="checktext">Scadenza</Label>
                                <DatePicker id      = "example-datepicker" 
                                            value   = {this.state.scadenza} 
                                            onChange= {(v,f) => this.handleChangeDue(v, f)} />
                            </FormGroup>
                        </Col>
                    </Form.Row>
                    <Form.Row className="Col_margin px-1 py-2">
                        <button className="myButton" type="submit">Filtra</button>
                    </Form.Row>
                </Form>
            </div>
        )
    }
}