import React, { Component } from 'react'
import { Collapse } from 'antd';
const { Panel } = Collapse;

const text = `
  Una prova generica sponsorized by HUI
`;

export default class GeneralCollapse extends Component {
    render() {
        return (
            <div>
                <Collapse accordion>
                    <Panel header="This is panel header 1" key="1">
                    <p>{text}</p>
                    </Panel>
                    <Panel header="This is panel header 2" key="2">
                    <p>{text}</p>
                    </Panel>
                    <Panel header="This is panel header 3" key="3">
                    <p>{text}</p>
                    </Panel>
                </Collapse>
            </div>
        )
    }
}