import React from 'react';
import '../../index.css';



class RGBDiv extends React.Component{
    constructor(props){
        super(props)
        this.state={

        }
    }
    render(){
        return(
            <div className="divrgb">
                <h4 className="hrgb">{this.props.nome}</h4>
            </div> 

            )
        }
                    
    }
export default RGBDiv