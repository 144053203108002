import React, { Component } from 'react'
import MPheaderButton from '../../components/MPheaderButton'
import { Collapse } from 'antd';
import SiNoJson from '../../Data/Form/SiNo.json'
import RicercaJson from '../../Data/Form/Ricerca.json'
import GenereJson from '../../Data/Form/Genere.json'
import LinguaJson from '../../Data/Form/Lingua.json'
import JobPositionJson from '../../Data/Form/JobPosition.json'
import StatusRubricaJson from '../../Data/Form/StatusRubrica.json'
import FatturatoJson from '../../Data/Form/Fatturato.json'
import NumeroDipendentiJson from '../../Data/Form/NumeroDipendenti.json'
import NewsLetterJson from '../../Data/Form/NewsLetter.json'
import '../../index.css';
import { Container,Row, Col } from 'reactstrap';
const { Panel } = Collapse;
const text = `
  Una prova generica sponsorized by HUI
`;

export default class AggiungiLista extends Component {
    render() {
        let RicercaOption = RicercaJson;
        let LinguaOption = LinguaJson;
        let GenereOption = GenereJson;
        let JobPositionOption = JobPositionJson; 
        let StatusRubricaOption = StatusRubricaJson; 
        let SiNoOption = SiNoJson;
        let FatturatoOption = FatturatoJson;
        let NumeroDipendentiOption = NumeroDipendentiJson;
        let NewsLetterOption=NewsLetterJson;
        return (
            <div>
                <MPheaderButton link="./Aggiungi Contatto" nome="Aggiungi Contatto"/> 
                <Collapse accordion>
                    
                    
                    <div className="box">
                        <input type="text" placeholder="Titolo"></input>
                    </div>
                    <Panel className="panel panelColor" header="Ricerca per Altri tool" style={{color:"white"}} key="1">
                    <Container fluid>
                            <Row className="Col_margin px-1">
                                <Col md={3} ClassName="Col_margin px-1">
                                    <div className="box">
                                        <label className="labelNuovo">Cerca nel Data</label>
                                        <select>
                                            {RicercaOption.map(o => <option key={o.id} value={o.id}>{o.text}</option>)}
                                        </select>
                                    </div>
                                </Col>
                                <Col md={3} ClassName="Col_margin px-1">
                                    <div className="box">
                                        <label className="labelNuovo">Cerca nel Influence</label>
                                        <select>
                                            {RicercaOption.map(o => <option key={o.id} value={o.id}>{o.text}</option>)}
                                        </select>
                                    </div>
                                </Col>
                                <Col md={3} ClassName="Col_margin px-1">
                                    <div className="box">
                                        <label className="labelNuovo">Cerca nel Fundraiser</label>
                                        <select>
                                            {RicercaOption.map(o => <option key={o.id} value={o.id}>{o.text}</option>)}
                                        </select>
                                    </div>
                                </Col>
                                <Col md={3} ClassName="Col_margin px-1">
                                    <div className="box">
                                        <label className="labelNuovo">Cerca nel Sales</label>
                                        <select>
                                            {RicercaOption.map(o => <option key={o.id} value={o.id}>{o.text}</option>)}
                                        </select>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="Col_margin px-1">
                                <Col md={3} ClassName="Col_margin px-1">
                                    <div className="box">
                                        <label className="labelNuovo">Cerca nel Legal</label>
                                        <select>
                                            {RicercaOption.map(o => <option key={o.id} value={o.id}>{o.text}</option>)}
                                        </select>
                                    </div>
                                </Col>
                                <Col md={3} ClassName="Col_margin px-1">
                                    <div className="box">
                                        <label className="labelNuovo">Cerca nel Humans</label>
                                        <select>
                                            {RicercaOption.map(o => <option key={o.id} value={o.id}>{o.text}</option>)}
                                        </select>
                                    </div>
                                </Col>
                                <Col md={3} ClassName="Col_margin px-1">
                                    <div className="box">
                                        <label className="labelNuovo">Cerca nel Suppliers</label>
                                        <select>
                                            {RicercaOption.map(o => <option key={o.id} value={o.id}>{o.text}</option>)}
                                        </select>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Panel>
                    
                    
                    
                    
                    
                    
                    <Panel className="panel panelColor" header="Ricerca per Data di creazione" key="2">
                        <Container fluid>
                            <Row className="Col_margin px-1">
                                <Col md={3} ClassName="Col_margin px-1">
                                    
                                </Col>
                                <Col md={3} ClassName="Col_margin px-1">
                                    
                                </Col>
                            </Row>
                        </Container>
                    </Panel>


                    
                    
                    
                    
                    
                    
                    
                    <Panel className="panel panelColor" header="Ricerca per PMF" key="3">
                    <Container fluid>
                            <Row className="Col_margin px-1">
                                <Col md={3} ClassName="Col_margin px-1">
                                    <div className="box">
                                        <label className="labelNuovo">Email</label>
                                        <input type="text" placeholder="Email"></input>
                                    </div>
                                </Col>
                                <Col md={3} ClassName="Col_margin px-1">
                                    <div className="box">
                                        <label className="labelNuovo">Nome o Regione Sociale</label>
                                        <input type="text" placeholder="Nome o Regione Sociale"></input>
                                    </div>
                                </Col>
                                <Col md={3} ClassName="Col_margin px-1">
                                    <div className="box">
                                        <label className="labelNuovo">Genere</label>
                                        <select>
                                            {GenereOption.map(o => <option key={o.id} value={o.id}>{o.text}</option>)}
                                        </select>
                                    </div>
                                </Col>
                                <Col md={3} ClassName="Col_margin px-1">
                                    <div className="box">
                                        <label className="labelNuovo">Optin</label>
                                        <select>
                                            {RicercaOption.map(o => <option key={o.id} value={o.id}>{o.text}</option>)}
                                        </select>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="Col_margin px-1">
                                <Col md={3} ClassName="Col_margin px-1">
                                    <div className="box">
                                        <label className="labelNuovo">Lingua</label>
                                        <select>
                                            {LinguaOption.map(o => <option key={o.id} value={o.id}>{o.text}</option>)}
                                        </select>
                                    </div>
                                </Col>
                                <Col md={3} ClassName="Col_margin px-1">
                                    <div className="box">
                                        <label className="labelNuovo">Telefono Fisso</label>
                                        <input type="text" placeholder=""></input>
                                    </div>
                                </Col>
                                <Col md={3} ClassName="Col_margin px-1">
                                    <div className="box">
                                        <label className="labelNuovo">Telefono Mobile</label>
                                        <input type="text" placeholder=""></input>
                                    </div>
                                </Col>
                                <Col md={3} ClassName="Col_margin px-1">
                                    <div className="box">
                                        <label className="labelNuovo">Partita IVA</label>
                                        <input type="text" placeholder="Partita IVA"></input>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="Col_margin px-1">
                                <Col md={3} ClassName="Col_margin px-1">
                                    <div className="box">
                                        <label className="labelNuovo">Codice Fiscale</label>
                                        <input type="text" placeholder="Codice Fiscale"></input>
                                    </div>
                                </Col>
                                <Col md={3} ClassName="Col_margin px-1">
                                    <div className="box">
                                        <label className="labelNuovo">Website</label>
                                        <input type="text" placeholder="Website"></input>
                                    </div>
                                </Col>
                                <Col md={3} ClassName="Col_margin px-1">
                                    <div className="box">
                                        <label className="labelNuovo">Data</label>
                                        <select>
                                            {SiNoOption.map(o => <option key={o.id} value={o.id}>{o.text}</option>)}
                                        </select>
                                    </div>
                                </Col>
                                <Col md={3} ClassName="Col_margin px-1">
                                    <div className="box">
                                        <label className="labelNuovo">Professione</label>
                                        <input type="text" placeholder=""></input>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="Col_margin px-1">
                                <Col md={3} ClassName="Col_margin px-1">
                                    <div className="box">
                                        <label className="labelNuovo">Messaggio/Note</label>
                                        <input type="text" placeholder=""></input>
                                    </div>
                                </Col>
                                <Col md={3} ClassName="Col_margin px-1">
                                    <div className="box">
                                        <label className="labelNuovo">Job Position</label>
                                        <select>
                                            {JobPositionOption.map(o => <option key={o.id} value={o.id}>{o.text}</option>)}
                                        </select>
                                    </div>
                                </Col>
                                <Col md={3} ClassName="Col_margin px-1">
                                    <div className="box">
                                        <label className="labelNuovo">Lavora Presso (nome azienda)</label>
                                        <input type="text" placeholder="Nome"></input>
                                    </div>
                                </Col>
                                <Col md={3} ClassName="Col_margin px-1">
                                    <div className="box">
                                        <label className="labelNuovo">Status</label>
                                        <select>
                                            {StatusRubricaOption.map(o => <option key={o.id} value={o.id}>{o.text}</option>)}
                                        </select>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="Col_margin px-1">
                                <Col md={3} ClassName="Col_margin px-1">
                                    <div className="box">
                                        <label className="labelNuovo">Email Valida</label>
                                        <select>
                                            {SiNoOption.map(o => <option key={o.id} value={o.id}>{o.text}</option>)}
                                        </select>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Panel>
                    
                    
                    
                    <Panel className="panel panelColor" header="Ricerca per Reach" key="4">
                    <Container fluid>
                            <Row className="Col_margin px-1">
                                <Col md={3} ClassName="Col_margin px-1">
                                    <div className="box">
                                        <label className="labelNuovo">Numero di dipendenti</label>
                                        <select>
                                            {NumeroDipendentiOption.map(o => <option key={o.id} value={o.id}>{o.text}</option>)}
                                        </select>
                                    </div>
                                </Col>
                                <Col md={3} ClassName="Col_margin px-1">
                                    <div className="box">
                                        <label className="labelNuovo">Telefono Fax</label>
                                        <input type="text" placeholder="Telefono Fax"></input>
                                    </div>
                                </Col>
                                <Col md={3} ClassName="Col_margin px-1">
                                    <div className="box">
                                        <label className="labelNuovo">Fatturato</label>
                                        <select>
                                            {FatturatoOption.map(o => <option key={o.id} value={o.id}>{o.text}</option>)}
                                        </select>
                                    </div>
                                </Col>
                                <Col md={3} ClassName="Col_margin px-1">
                                 <div className="box">
                                        <label className="labelNuovo">Facebook</label>
                                        <input type="text" placeholder=""></input>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="Col_margin px-1">
                                <Col md={3} ClassName="Col_margin px-1">
                                    <div className="box">
                                        <label className="labelNuovo">Pinterest</label>
                                        <input type="text" placeholder=""></input>
                                    </div>
                                </Col>
                                <Col md={3} ClassName="Col_margin px-1">
                                    <div className="box">
                                        <label className="labelNuovo">Linkedin</label>
                                        <input type="text" placeholder=""></input>
                                    </div>
                                </Col>
                                <Col md={3} ClassName="Col_margin px-1">
                                    <div className="box">
                                        <label className="labelNuovo">Twitter</label>
                                        <input type="text" placeholder=""></input>
                                    </div>
                                </Col>
                                <Col md={3} ClassName="Col_margin px-1">
                                    <div className="box">
                                        <label className="labelNuovo">Instagram</label>
                                        <input type="text" placeholder=""></input>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="Col_margin px-1">
                                <Col md={3} ClassName="Col_margin px-1">
                                    <div className="box">
                                        <label className="labelNuovo">Email2</label>
                                        <input type="text" placeholder=""></input>
                                    </div>
                                </Col>
                                <Col md={3} ClassName="Col_margin px-1">
                                    <div className="box">
                                        <label className="labelNuovo">Telefono Ufficio</label>
                                        <input type="text" placeholder="Telefono Ufficio"></input>
                                    </div>
                                </Col>
                                <Col md={3} ClassName="Col_margin px-1">
                                    <div className="box">
                                        <label className="labelNuovo">NewsLetter</label>
                                        <select>
                                            {NewsLetterOption.map(o => <option key={o.id} value={o.id}>{o.text}</option>)}
                                        </select>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Panel>




                    <Panel className="panel panelColor" header="Ricerca per Indirizzo" key="5">
                    <p>{text}</p>
                    </Panel>
                    <Panel className="panel panelColor" header="Ricerca per Standard" key="6">
                    <p>{text}</p>
                    </Panel>
                    <Panel className="panel panelColor" header="Ricerca per Additional" key="7">
                    <p>{text}</p>
                    </Panel>
                    <Panel className="panel panelColorRed" header="Ricerca per Tag" key="8">
                    <p>{text}</p>
                    </Panel>
                    <Panel className="panel panelColorRed" header="Ricerca per Settori" key="9">
                    <p>{text}</p>
                    </Panel>
                    <Panel className="panel panelColorRed" header="Ricerca per Origini" key="10">
                    <p>{text}</p>
                    </Panel>
                    <Panel className="panel panelColorRed" header="Ricerca per Utilizzi" key="11">
                    <p>{text}</p>
                    </Panel>
                </Collapse>
            </div>
        )
    }
}
