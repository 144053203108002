import React, { Component } from 'react'
import MPheader from '../../components/MPheader'
import Form from 'react-bootstrap/Form'
import { Container, Row, Col } from 'reactstrap'
import StatiSalesTable from '../../components/DataTable/StatiSalesTable'

export default class StatiSales extends Component {
    constructor(props){
        super(props)
        this.state={
            nome:"",
            ris:""
        }
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event){
        const{name,value,type,checked}=event.target
        type === "checkbox"?this.setState({[name]:checked}):this.setState({[name]:value})
    }
    handleSubmit=(event)=>{
        event.preventDefault()
        const data = this.state
        console.log(data)
    }
    render() {
        return (
            <div>
                <MPheader/> 
                <Form onSubmit={this.handleSubmit}>
                    <Form.Row className="Col_margin py-2 px-1">
                        <Col md={6} className="Col_margin px-1">
                            <Form.Label className="checktext">Nome</Form.Label>
                            <Form.Control type="text" placeholder="Nome" onChange={this.handleChange} value={this.state.nome} name="nome"/>
                        </Col>
                    </Form.Row>
                    <Form.Row className="Col_margin px-1 py-2">
                        <button className="myButton" type="submit" style={{padding:"3px 150px"}}>Filtra</button>
                    </Form.Row>
                </Form>
                <Row className="Col_margin py-5 px-1">
                    <StatiSalesTable/>
                </Row>
                
            </div>
        )
    }
}
