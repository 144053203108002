import React from 'react';
import { HashRouter as Router,Route, Switch } from 'react-router-dom';

import Profilo from './pages/Profilo'
import ModificaProfilo from './pages/ModificaProfilo'
import AttivaCommunity from './pages/AttivaCommunity'
import MarketAggiungi from './components/Community/MarketAggiungi';
import NuovaIsola from './pages/NuovaIsola'

/*.PLAY*/
import Islanders from './pages/Play/Islanders'
import Archivements from './pages/Play/Archivements'
import Marketplace from './pages/Play/Marketplace'
import Wall from './pages/Play/Wall'
import Island from './pages/Play/Island'
import Opportunities from './pages/Play/Opportunities'

/*DASHBOARD*/
import MainPage from './pages/MainPage'
import Todo from './pages/Dashboard/Todo'
import Eventi from './pages/Dashboard/Eventi'
import Gantt from './pages/Dashboard/Gantt'
import Widget from './pages/Dashboard/Widget'
import AggiungiPromemoria from './pages/Dashboard/AggiungiPromemoria'
import AggiungiEvento from './pages/Dashboard/AggiungiEvento'
import AggiungiWidget from './pages/Dashboard/AggiungiWidget'

/*INTELLIGENCE*/
import Overview from './pages/Intelligence/Overview'

/*SALES*/
import CRMap from './pages/Sales/CRMap';
import Opportunità from './pages/Sales/Opportunità';
import Provvigioni from './pages/Sales/Provvigioni';
import TodoSales from './pages/Sales/TodoSales';
import Selling from './pages/Sales/Selling';
import Grafici from './pages/Sales/Grafici';
import ListinoSales from './pages/Sales/ListinoSales';
import ProdottiSales from './pages/Sales/ProdottiSales';
import TipiSales from './pages/Sales/TipiSales';
import StatiSales from './pages/Sales/StatiSales';
import AggiungiContatto from './pages/Sales/AggiungiContatto';
import AggiungiOpportunità from './pages/Sales/AggiungiOpportunità';
import AggiungiTodo from './pages/Sales/AggiungiTodo';
import NuovaSellingTip from './pages/Sales/NuovaSellingTip';
import NuovoListino from './pages/Sales/NuovoListino';
import NuovoProdotto from './pages/Sales/NuovoProdotto';

/*DATA*/
import Rubrica from './pages/Data/Rubrica';
import Liste from './pages/Data/Liste';
import DBNeed from './pages/Data/DBNeed';
import GraficiData from './pages/Data/GraficiData';
import CategorieData from './pages/Data/CategorieData';
import CampiData from './pages/Data/CampiData';
import ValoriData from './pages/Data/ValoriData';
import PDI from './pages/Data/PDI';
import AggiungiContattoData from './pages/Data/AggiungiContattoData'
import AggiungiLista from './pages/Data/AggiungiLista'
import AggiungiDBNeed from './pages/Data/AggiungiDBNeed'
import AggiungiCampo from './pages/Data/AggiungiCampo'
import AggiungiCategoria from './pages/Data/AggiungiCategoria'
import AggiungiPDI from './pages/Data/AggiungiPDI'

/*MAIL*/
import AutoDem from './pages/Mail/AutoDem';
import Manual from './pages/Mail/Manual';
import AccountInvio from './pages/Mail/AccountInvio';
import AggiungiDEM from './pages/Mail/AggiungiDEM';
import AggiungiAccount from './pages/Mail/AggiungiAccount';

/*DOCS*/
import DocumentiDocs from './pages/Docs/DocumentiDocs';

/*FUNDRAISER*/
import FRMMap from './pages/Fundraiser/FRMMap';
import FN from './pages/Fundraiser/FN';
import FRO from './pages/Fundraiser/FRO';
import DocumentiFundraiser from './pages/Fundraiser/DocumentiFundraiser';
import TodoFundraiser from './pages/Fundraiser/TodoFundraiser';
import GraficiFundraiser from './pages/Fundraiser/GraficiFundraiser';
import TipiDocumentoFundraiser from './pages/Fundraiser/TipiDocumentoFundraiser';
import AggiungiFRO from './pages/Fundraiser/AggiungiFRO';
import AggiungiFN from './pages/Fundraiser/AggiungiFN';
import AggiungiTipiFundraiser from './pages/Fundraiser/AggiungiTipiFundraiser';
import AggiungiTodoFundraiser from './pages/Fundraiser/AggiungiTodoFundraiser';
import AggiungiContattoFundraiser from './pages/Fundraiser/AggiungiContattoFundraiser';
import AggiungiDocumentoFundraiser from './pages/Fundraiser/AggiungiDocumentoFundraiser';

/*BOARD*/
import CompanyBoard from './pages/Board/CompanyBoard';
import MilestoneBoard from './pages/Board/MilestoneBoard';
import TodoBoard from './pages/Board/TodoBoard';
import DocumentiBoard from './pages/Board/DocumentiBoard';
import TipiDocumentoBoard from './pages/Board/TipiDocumentoBoard';
import AggiungiTipiDocumentiBoard from './pages/Board/AggiungiTipiDocumentiBoard';
import AggiungiCompany from './pages/Board/AggiungiCompany';
import AggiungiDocumentiBoard from './pages/Board/AggiungiDocumentiBoard';
import AggiungiMilestoneBoard from './pages/Board/AggiungiMilestoneBoard';
import AggiungiTodoBoard from './pages/Board/AggiungiTodoBoard';

/*HUMANS*/
import HRMMap from './pages/Humans/HRMMap';
import OpportunitiesHumans from './pages/Humans/OpportunitiesHumans';
import TodoHumans from './pages/Humans/TodoHumans';
import AggiungiOpportunity from './pages/Humans/AggiungiOpportunity';
import AggiungiContattoHumans from './pages/Humans/AggiungiContattoHumans';

/*LEGAL*/
import LegalMap from './pages/Legal/LegalMap';
import PositionLegal from './pages/Legal/PositionLegal';
import DocumentiLegal from './pages/Legal/DocumentiLegal';
import TodoLegal from './pages/Legal/TodoLegal';
import CompanyLegal from './pages/Legal/CompanyLegal';
import DirezioniLegal from './pages/Legal/DirezioniLegal';
import Fori from './pages/Legal/Fori';
import DirittiLegal from './pages/Legal/DirittiLegal';
import LivelliLegal from './pages/Legal/LivelliLegal';
import TipiDocumentoLegal from './pages/Legal/TipiDocumentoLegal';
import AggiungiContattoLegal from './pages/Legal/AggiungiContattoLegal';
import AggiungiPositionLegal from './pages/Legal/AggiungiPositionLegal';
import AggiungiDocumentoLegal from './pages/Legal/AggiungiDocumentoLegal';
import AggiungiTodoLegal from './pages/Legal/AggiungiTodoLegal';
import AggiungiCompanyLegal from './pages/Legal/AggiungiCompanyLegal';
import AggiungiDirezioniLegal from './pages/Legal/AggiungiDirezioniLegal';
import AggiungiForiLegal from './pages/Legal/AggiungiForiLegal';
import AggiungiDirittiLegal from './pages/Legal/AggiungiDirittiLegal';
import AggiungiLivelliLegal from './pages/Legal/AggiungiLivelliLegal';
import AggiungiTipiLegal from './pages/Legal/AggiungiTipiLegal';

/*PROJECTS*/
import MilestoneProjects from './pages/Projects/MilestoneProjects';
import TodoProjects from './pages/Projects/TodoProjects';
import Attivita from './pages/Projects/Attivita'
import Progetti from './pages/Projects/Progetti'
import AggiungiProgetto from './pages/Projects/AggiungiProgetto'
import AggiungiAttività from './pages/Projects/AggiungiAttività'
import AggiungiMilestoneProjects from './pages/Projects/AggiungiMilestoneProjects'
import AggiungiTodoProjects from './pages/Projects/AggiungiTodoProjects'

/*INFLUENCE*/
import PRMMap from './pages/Influence/PRMMap';
import PRN from './pages/Influence/PRNPage';
import PRO from './pages/Influence/PRO';
import ContenutiInfluence from './pages/Influence/ContenutiInfluence';
import TodoInfluence from './pages/Influence/TodoInfluence';
import GraficiInfluence from './pages/Influence/GraficiInfluence';
import ReachEta from './pages/Influence/ReachEta';
import ReachCampi from './pages/Influence/ReachCampi';
import ReachTipi from './pages/Influence/ReachTipi';
import AggiungiContattoInfluence from './pages/Influence/AggiungiContattoInfluence';
import AggiungiContenutiInfluence from './pages/Influence/AggiungiContenutiInfluence';
import AggiungiPRNInfluence from './pages/Influence/AggiungiPRNInfluence';
import AggiungiPROInfluence from './pages/Influence/AggiungiPROInfluence';
import AggiungiTodoInfluence from './pages/Influence/AggiungiTodoInfluence';

/*Suppliers*/
import SRMMap from './pages/Suppliers/SRMMap';
import Fornitura from './pages/Suppliers/Fornitura';
import TodoSuppliers from './pages/Suppliers/TodoSuppliers';
import GraficiSuppliers from './pages/Suppliers/GraficiSuppliers';
import ListaBeni from './pages/Suppliers/ListaBeni';
import ProdottiSuppliers from './pages/Suppliers/ProdottiSuppliers';
import TipiSuppliers from './pages/Suppliers/TipiSuppliers';
import StatiSuppliers from './pages/Suppliers/StatiSuppliers';
import AggiungiBeniSuppliers from './pages/Suppliers/AggiungiBeniSuppliers';
import AggiungiContattoSuppliers from './pages/Suppliers/AggiungiContattoSuppliers';
import AggiungiFornituraSuppliers from './pages/Suppliers/AggiungiFornituraSuppliers';
import AggiungiProdottoSuppliers from './pages/Suppliers/AggiungiProdottoSuppliers';
import AggiungiTipoSuppliers from './pages/Suppliers/AggiungiTipoSuppliers';
import AggiungiTodoSuppliers from './pages/Suppliers/AggiungiTodoSuppliers';

import NuoviForm from './pages/NuoviForm'




class Routes extends React.Component {
  render() { 
  
    return (
      
        <Switch>
        <Route exact path='/NuoviForm' component={NuoviForm} />
        <Route exact path='/Project/Milestone/:personID' component={Todo} />

        <Route exact path='/' component={MainPage} />
        <Route exact path='/Dashboard/Overview' component={MainPage} />
        <Route exact path='/Dashboard/Todo' component={Todo} />
        <Route exact path='/Dashboard/Eventi' component={Eventi} />
        <Route exact path='/Dashboard/Gantt' component={Gantt} />
        <Route exact path='/Dashboard/Widget' component={Widget} />
        <Route exact path='/Dashboard/Aggiungi promemoria' component={AggiungiPromemoria} />
        <Route exact path='/Dashboard/Aggiungi evento' component={AggiungiEvento} />
        <Route exact path='/Dashboard/Aggiungi widget' component={AggiungiWidget} />



        <Route exact path='/Profilo' component={Profilo}/>   
        <Route exact path="/ModificaProfilo" component={ModificaProfilo}/>  
        <Route exact path="/Nuova Isola" component={NuovaIsola}/> 

        <Route exact path='/Play/Islanders' component={Islanders} />  
        <Route exact path="/Play/Archivements" component={Archivements}/>   
        <Route exact path="/Play/Marketplace" component={Marketplace}/>  
        <Route exact path="/Play/Island" component={Island}/> 
        <Route exact path="/Play/Wall" component={Wall}/> 
        <Route exact path="/Play/Opportunities" component={Opportunities}/> 

        <Route exact path="/Intellingence/Overview" component={Overview}/>  

        <Route exact path="/Marketing/Marketing" component={AttivaCommunity}/>
        <Route exact path="/Marketing/Marketing/Nuovo prodotto" component={MarketAggiungi}/>
        
        <Route exact path="/Sales/CRM Map" component={CRMap}/>
        <Route exact path="/Sales/Opportunità" component={Opportunità}/>
        <Route exact path="/Sales/Provvigioni" component={Provvigioni}/>
        <Route exact path="/Sales/Grafici" component={Grafici}/>
        <Route exact path="/Sales/Listino" component={ListinoSales}/>
        <Route exact path="/Sales/Prodotti" component={ProdottiSales}/>
        <Route exact path="/Sales/Selling" component={Selling}/>
        <Route exact path="/Sales/Stati" component={StatiSales}/>
        <Route exact path="/Sales/Tipi" component={TipiSales}/>
        <Route exact path="/Sales/Todo" component={TodoSales}/>
        <Route exact path="/Sales/CMRMap/Aggiungi Contatto" component={AggiungiContatto}/>
        <Route exact path="/Sales/Opportunità/Aggiungi Opportunità" component={AggiungiOpportunità}/>
        <Route exact path="/Sales/Todo/Aggiungi Todo" component={AggiungiTodo}/>
        <Route exact path="/Sales/Selling Tip/Nuova Selling Tip" component={NuovaSellingTip}/>
        <Route exact path="/Sales/Listino/Nuovo Listino" component={NuovoListino}/>
        <Route exact path="/Sales/Prodotti/Nuovo Prodotto" component={NuovoProdotto}/>

        <Route exact path="/Data/Campi" component={CampiData}/>
        <Route exact path="/Data/Categorie" component={CategorieData}/>
        <Route exact path="/Data/DB Need" component={DBNeed}/>
        <Route exact path="/Data/Grafici" component={GraficiData}/>
        <Route exact path="/Data/Liste" component={Liste}/>
        <Route exact path="/Data/PDI" component={PDI}/>
        <Route exact path="/Data/Rubrica" component={Rubrica}/>
        <Route exact path="/Data/Valori" component={ValoriData}/>
        <Route exact path="/Data/Rubrica/Aggiungi Contatto" component={AggiungiContattoData}/>
        <Route exact path="/Data/Liste/Aggiungi Lista" component={AggiungiLista}/>
        <Route exact path="/Data/DB Need/Aggiungi DB Need" component={AggiungiDBNeed}/>
        <Route exact path="/Data/PDI/Aggiungi PDI" component={AggiungiPDI}/>
        <Route exact path="/Data/Categorie/Aggiungi Categoria" component={AggiungiCategoria}/>
        <Route exact path="/Data/Campi/Aggiungi Campo" component={AggiungiCampo}/>

        <Route exact path="/Mail/Account di Invio" component={AccountInvio}/>
        <Route exact path="/Mail/AutoDem" component={AutoDem}/>
        <Route exact path="/Mail/Manual DEM" component={Manual}/>
        <Route exact path="/Mail/Account di Invio/Aggiungi account" component={AggiungiAccount}/>
        <Route exact path="/Mail/Manual DEM/Aggiungi Dem" component={AggiungiDEM}/>

        <Route exact path="/Docs/Documenti" component={DocumentiDocs}/>

        <Route exact path="/Fundaraiser/Documenti" component={DocumentiFundraiser}/>
        <Route exact path="/Fundaraiser/FN" component={FN}/>
        <Route exact path="/Fundaraiser/FRO" component={FRO}/>
        <Route exact path="/Fundaraiser/FRM Map" component={FRMMap}/>
        <Route exact path="/Fundaraiser/Grafici" component={GraficiFundraiser}/>
        <Route exact path="/Fundaraiser/Tipi di Documento" component={TipiDocumentoFundraiser}/>
        <Route exact path="/Fundaraiser/Todo" component={TodoFundraiser}/>
        <Route exact path="/Fundaraiser/FRO/Aggiungi FRO" component={AggiungiFRO}/>
        <Route exact path="/Fundaraiser/FN/Aggiungi FN" component={AggiungiFN}/>
        <Route exact path="/Fundaraiser/Tipi di Documento/Aggiungi Tipo" component={AggiungiTipiFundraiser}/>
        <Route exact path="/Fundaraiser/Todo/Aggiungi Todo" component={AggiungiTodoFundraiser}/>
        <Route exact path="/Fundaraiser/FRM Map/Aggiungi Contatto" component={AggiungiContattoFundraiser}/>
        <Route exact path="/Fundaraiser/Documenti/Aggiungi Documento" component={AggiungiDocumentoFundraiser}/>

        <Route exact path="/Board/Company" component={CompanyBoard}/>
        <Route exact path="/Board/Documenti" component={DocumentiBoard}/>
        <Route exact path="/Board/Milestone" component={MilestoneBoard}/>
        <Route exact path="/Board/Tipi di Documento" component={TipiDocumentoBoard}/>
        <Route exact path="/Board/Todo" component={TodoBoard}/>
        <Route exact path="/Board/Company/Aggiungi Company" component={AggiungiCompany}/>
        <Route exact path="/Board/Documenti/Aggiungi Documento" component={AggiungiDocumentiBoard}/>
        <Route exact path="/Board/Milestone/Aggiungi Milestone" component={AggiungiMilestoneBoard}/>
        <Route exact path="/Board/Tipi di Documento/Aggiungi Tipo" component={AggiungiTipiDocumentiBoard}/>
        <Route exact path="/Board/Todo/Aggiungi Todo" component={AggiungiTodoBoard}/>

        <Route exact path="/Humans/HRM Map" component={HRMMap}/>
        <Route exact path="/Humans/Opportunities" component={OpportunitiesHumans}/>
        <Route exact path="/Humans/Todo" component={TodoHumans}/>
        <Route exact path="/Humans/HRM Map/Aggiungi Contatto" component={AggiungiContattoHumans}/>
        <Route exact path="/Humans/Opportunities/Aggiungi Opportunity" component={AggiungiOpportunity}/>

        <Route exact path="/Legal/Company" component={CompanyLegal}/>
        <Route exact path="/Legal/Direzioni" component={DirezioniLegal}/>
        <Route exact path="/Legal/Diritti" component={DirittiLegal}/>
        <Route exact path="/Legal/Documenti" component={DocumentiLegal}/>
        <Route exact path="/Legal/Fori" component={Fori}/>
        <Route exact path="/Legal/Legal Map" component={LegalMap}/>
        <Route exact path="/Legal/Livelli" component={LivelliLegal}/>
        <Route exact path="/Legal/Position" component={PositionLegal}/>
        <Route exact path="/Legal/Tipi di Documento" component={TipiDocumentoLegal}/>
        <Route exact path="/Legal/Todo" component={TodoLegal}/>
        <Route exact path="/Legal/Company/Aggiungi Company" component={AggiungiCompanyLegal}/>
        <Route exact path="/Legal/Direzioni/Aggiungi Direzione" component={AggiungiDirezioniLegal}/>
        <Route exact path="/Legal/Diritti/Aggiungi Diritto" component={AggiungiDirittiLegal}/>
        <Route exact path="/Legal/Documenti/Aggiungi Documento" component={AggiungiDocumentoLegal}/>
        <Route exact path="/Legal/Fori/Aggiungi Foro" component={AggiungiForiLegal}/>
        <Route exact path="/Legal/Legal Map/aggiungi Contatto" component={AggiungiContattoLegal}/>
        <Route exact path="/Legal/Livelli/Aggiungi Livello" component={AggiungiLivelliLegal}/>
        <Route exact path="/Legal/Position/Aggiungi Position" component={AggiungiPositionLegal}/>
        <Route exact path="/Legal/Tipi di Documento/Aggiungi Tipo" component={AggiungiTipiLegal}/>
        <Route exact path="/Legal/Todo/Aggiungi Todo" component={AggiungiTodoLegal}/>

        <Route exact path="/Projects/Todo" component={TodoProjects}/>
        <Route exact path="/Projects/Milestone" component={MilestoneProjects}/>
        <Route exact path='/Projects/Attivita' component={Attivita} />
        <Route exact path='/Projects/Progetti' component={Progetti} />
        <Route exact path="/Projects/Todo/Aggiungi Todo" component={AggiungiTodoProjects}/>
        <Route exact path="/Projects/Milestone/Aggiungi Milestone" component={AggiungiMilestoneProjects}/>
        <Route exact path='/Projects/Attivita/Aggiungi Attività' component={AggiungiAttività} />
        <Route exact path='/Projects/Progetti/Aggiungi Progetto' component={AggiungiProgetto} />

        <Route exact path="/Influence/Contenuti" component={ContenutiInfluence}/>
        <Route exact path="/Influence/Grafici" component={GraficiInfluence}/>
        <Route exact path='/Influence/PRM Map' component={PRMMap} />
        <Route exact path='/Influence/PRN' component={PRN} />
        <Route exact path="/Influence/PRO" component={PRO}/>
        <Route exact path='/Influence/Todo' component={TodoInfluence} />
        <Route exact path="/Influence/Reach-Campi" component={ReachCampi}/>
        <Route exact path='/Influence/Reach-Età' component={ReachEta} />
        <Route exact path='/Influence/Reach-Tipi' component={ReachTipi} />
        <Route exact path='/Influence/Contenuti/Aggiungi Contenuto' component={AggiungiContenutiInfluence}/>
        <Route exact path='/Influence/PRM Map/Aggiungi Contatto' component={AggiungiContattoInfluence} />
        <Route exact path='/Influence/PRN/Aggiungi PRN' component={AggiungiPRNInfluence} />
        <Route exact path='/Influence/PRO/Aggiungi PRO' component={AggiungiPROInfluence}/>
        <Route exact path='/Influence/Todo/Aggiungi Todo' component={AggiungiTodoInfluence} />

        <Route exact path="/Suppliers/Fornitura" component={Fornitura}/>
        <Route exact path='/Suppliers/Grafici' component={GraficiSuppliers} />
        <Route exact path='/Suppliers/Lista Beni' component={ListaBeni} />
        <Route exact path="/Suppliers/Prodotti" component={ProdottiSuppliers}/>
        <Route exact path="/Suppliers/SRM Map" component={SRMMap}/>
        <Route exact path='/Suppliers/Tipi' component={TipiSuppliers} />
        <Route exact path='/Suppliers/Stati' component={StatiSuppliers} />
        <Route exact path='/Suppliers/Todo' component={TodoSuppliers} />
        <Route exact path="/Suppliers/Fornitura/Aggiungi Fornitura" component={AggiungiFornituraSuppliers}/>
        <Route exact path='/Suppliers/Lista Beni/Aggiungi Beni' component={AggiungiBeniSuppliers} />
        <Route exact path="/Suppliers/Prodotti/Aggiungi Prodotto" component={AggiungiProdottoSuppliers}/>
        <Route exact path="/Suppliers/SRM Map/Aggiungi Contatto" component={AggiungiContattoSuppliers}/>
        <Route exact path='/Suppliers/Tipi/Aggiungi Tipo' component={AggiungiTipoSuppliers} />
        <Route exact path='/Suppliers/Todo/Aggiungi Todo' component={AggiungiTodoSuppliers } />

                                                                        

        <Route
          render={function() {
            return <h1>Not Found</h1>;
          }}
        />
        </Switch>
    );
  }
}

export default Routes;
