import React, { Component } from 'react'
import { Container, Row, Col } from 'react-grid-system';
import '../../index.css';
import MPheader from '../MPheader'
import Form from 'react-bootstrap/Form'
import TextForm from '../Forms/TextForm';
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/Form'
import '../../index.css';


export default class MarketAggiungi extends Component {
    constructor(props){
        super(props)
        this.state={
            titolo:"",
            costo:"",
            attività:"",
            assets:"",
            descrizione:""
        }
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event){
        const{name,value,type,checked}=event.target
        type === "checkbox"?this.setState({[name]:checked}):this.setState({[name]:value})
    }
    handleSubmit=(event)=>{
        event.preventDefault()
        const data = this.state
        
        console.log(data)
    }
    render() {

        return (
            <div>
                <MPheader/> 
                <Form onSubmit={this.handleSubmit} action="/Marketing/Marketing">
                    <Form.Row className="Col_margin py-1 px-1">
                        <Col md={12} className="Col_margin px-1">
                            <Form.Label className="checktext">Nome</Form.Label>
                            <Form.Control type="text" placeholder="Nome" onChange={this.handleChange} value={this.state.titolo} name="titolo"/>
                        </Col>
                    </Form.Row>
                    <Form.Row className="Col_margin py-1 px-1">
                        <Col md={4} className="Col_margin px-1">
                            <Form.Label className="checktext">Prezzo</Form.Label>
                            <Form.Control type="text" placeholder="Prezzo" onChange={this.handleChange} value={this.state.costo} name="costo"/>
                        </Col>
                        <Col md={4} className="Col_margin px-1">
                            <Form.Label className="checktext">Attività</Form.Label>
                                <Form.Control as="select" onChange={this.handleChange} value={this.state.attività} name="attività">
                                    <option value="Prodotto">Prodotto</option>
                                    <option value="Mercato">Mercato</option>
                                    <option value="Foundraising">Foundraising</option>
                                    <option value="Corporate">Corporate</option>
                                </Form.Control>
                        </Col>
                        <Col md={4} className="Col_margin px-1">
                            <Form.Label className="checktext">Assets</Form.Label>
                                <Form.Control as="select" onChange="Assets" value={this.state.assets} name="assets">
                                    <option value="Concept">Concept</option>
                                    <option value="Conception">Conception</option>
                                    <option value="Capital">Capital</option>
                                    <option value="Capital">Capital</option>
                                </Form.Control>
                        </Col>
                    </Form.Row>
                    <Form.Row className="Col_margin py-1 px-1">
                        <Col md={12} className="Col_margin px-1 py-4">
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Descrizione</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl as="textarea" aria-label="Descrizione" onChange={this.handleChange} value={this.state.descrizione} name="descrizione"/>
                            </InputGroup>
                        </Col>

                    </Form.Row>
                    <Form.Row className="Col_margin px-1 py-2">
                        <button className="myButton" type="submit">Salva</button>
                    </Form.Row>
            </Form>
            </div>
        )
    }
}
