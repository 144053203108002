import React from "react";
import { Bar } from "react-chartjs-2";

export default class Grafico extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        labels: [
          "28 Agosto",
          "29 Agosto",
          "30 Agosto"
        ],
        datasets: [
          {
            label: "Fattura Prodotto",
            backgroundColor: "rgba(0, 102, 204,0.2)",
            borderColor: "rgba(255,99,132,1)",
            borderWidth: 1,
            //stack: 1,
            hoverBackgroundColor: "rgba(0, 102, 204,0.4)",
            hoverBorderColor: "rgba(255,99,132,1)",
            data: [30,30,40,0]
          },

          {
            label: "Provvigione Prodotto",
            backgroundColor: "rgba(0, 153, 51,0.2)",
            borderColor: "rgba(255,99,132,1)",
            borderWidth: 1,
            //stack: 1,
            hoverBackgroundColor: "rgba(0, 153, 51,0.4)",
            hoverBorderColor: "rgba(255,99,132,1)",
            data: [45, 79, 50]
          },
          {
            label: "Fatturato Servizio",
            backgroundColor: "rgba(204, 51, 0,0.2)",
            borderColor: "rgba(255,99,132,1)",
            borderWidth: 1,
            //stack: 1,
            hoverBackgroundColor: "rgba(204, 51, 0,0.4)",
            hoverBorderColor: "rgba(255,99,132,1)",
            data: [45, 79, 50]
          },
          {
            label: "Provvigione Servizio",
            backgroundColor: "rgba(255, 204, 0,0.2)",
            borderColor: "rgba(255,99,132,1)",
            borderWidth: 1,
            //stack: 1,
            hoverBackgroundColor: "rgba(255, 204, 0,0.4)",
            hoverBorderColor: "rgba(255,99,132,1)",
            data: [45, 79, 50]
          }
        ]
      }
    };
  }

  render() {
    const options = {
      responsive: true,
      legend: {
        display: false
      },
      type: "bar"
      //   scales: {
      //     xAxes: [{
      //         stacked: true
      //     }],
      //     yAxes: [{
      //         stacked: true
      //     }]
      // }
    };
    return (
      <Bar
        data={this.state.data}
        width={null}
        height={null}
        options={options}
      />
    );
  }
}