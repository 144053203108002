import React from 'react'
import { Accordion } from 'semantic-ui-react'
import '../../index.css'

const level1Panels = [
  { key: 'panel-1a', title: 'Piano', content: 'Piano Contents' },
  { key: 'panel-ba', title: 'Piano', content: 'Piano Contents' },
]

const Level1Content = (
  <div className="graficiDiv">
    Piani
    <Accordion.Accordion  panels={level1Panels} />
  </div>
)

const level2Panels = [
  { key: 'panel-2a', title: 'Piano', content: 'Piano Contents' },
  { key: 'panel-2b', title: 'Piano', content: 'Piano Contents' },
]

const Level2Content = (
  <div className="graficiDiv">
    Piani
    <Accordion.Accordion  panels={level2Panels} />
  </div>
)

const rootPanels = [
  { key: 'panel-1', title: 'Azienda1', content: { content: Level1Content } },
  { key: 'panel-2', title: 'Azienda2', content: { content: Level2Content } },
]

const Nested = () => (
  <Accordion defaultActiveIndex={0} panels={rootPanels} styled  />
)

export default Nested