import React, { Component } from 'react'
import 'antd/dist/antd.css';
import Form from 'react-bootstrap/Form'
import {Container,Row,Col} from 'react-grid-system';
import MPheader from '../../components/MPheader';
import ProgettoListJson from '../../Data/Form/ProgettoList.json'
import AssegnatoJson from '../../Data/Form/Assegnato.json'
import statoJson from '../../Data/Form/Stato.json'
import {FormGroup, Label} from 'reactstrap'
import DatePicker from "reactstrap-date-picker";



export default class AggiungiMilestoneBoard extends Component {
    constructor(props){
        super(props)
        this.state={
            milestone:"",
            company:"",
            assegnato:"",
            attività:"", 
            progetto:"",
            stato:"",
            scadenza:new Date().toISOString()

        }
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event){
        const{name,value,type,checked}=event.target
        type === "checkbox"?this.setState({[name]:checked}):this.setState({[name]:value})
    }
    handleSubmit=(event)=>{
        event.preventDefault()
        const data = this.state
        
        console.log(data)
    }
    render() {
        let progettoOption = ProgettoListJson;
        let statoOption = statoJson;
        let AssegnatoOption = AssegnatoJson;
        return (
            <div>
                <MPheader/>
                <Container fluid className="MainPageRow2">
                    <Row className="Col_margin py-4 px-2">
                        <Col className="Col_margin px-1">
                            <Form onSubmit={this.handleSubmit}>
                                <Form.Row>
                                    <Col md={4} className="Col_margin py-1 px-1">
                                        <Form.Label className="checktext">Milestone</Form.Label>
                                        <Form.Control type="text" placeholder="" onChange={this.handleChange} value={this.state.milestone} name="milestone"/>
                                    </Col>
                                </Form.Row>
                                    
                                <Form.Row>
                                    <Col md={4} className="Col_margin px-1">
                                        <Form.Label className="checktext">Progetto (del Projects)</Form.Label>
                                        <Form.Control as="select" onChange={this.handleChange} value={this.state.progetto} name="progetto">
                                            {progettoOption.map(o => <option key={o.id} value={o.id}>{o.text}</option>)}
                                        </Form.Control>
                                    </Col>
                                    <Col md={4} className="Col_margin px-1">
                                        <Form.Label className="checktext">Attività (del Projects)</Form.Label>
                                        <Form.Control as="select" onChange={this.handleChange} value={this.state.attività} name="attività">
                                            {progettoOption.map(o => <option key={o.id} value={o.id}>{o.text}</option>)}
                                        </Form.Control>
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Col md={4} className="Col_margin px-1">
                                        <Form.Label className="checktext">Company</Form.Label>
                                        <Form.Control as="select" onChange={this.handleChange} value={this.state.company} name="company">
                                            <option value=""></option>
                                        </Form.Control>
                                    </Col>
                                    <Col md={4} className="Col_margin px-1">
                                        <FormGroup>
                                            <Label className="checktext">Scadenza</Label>
                                            <DatePicker id      = "example-datepicker" 
                                                        value   = {this.state.scadenza} 
                                                        onChange= {(v,f) => this.handleChangeDue(v, f)} />
                                        </FormGroup>
                                    </Col>

                                </Form.Row>
                                <Form.Row>
                                    <Col md={4} className="Col_margin px-1">
                                        <Form.Label className="checktext">Assegnato a</Form.Label>
                                        <Form.Control as="select" onChange={this.handleChange} value={this.state.assegnato} name="assegnato">
                                            {AssegnatoOption.map(o => <option key={o.id} value={o.id}>{o.text}</option>)}
                                        </Form.Control>
                                    </Col>
                                    <Col md={4} className="Col_margin px-1">
                                        <Form.Label className="checktext">Stato</Form.Label>
                                        <Form.Control as="select" onChange={this.handleChange} value={this.state.stato} name="stato">
                                            {statoOption.map(o => <option key={o.id} value={o.id}>{o.text}</option>)}
                                        </Form.Control>
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Col md={3} className="Col_margin py-5"> 
                                        <button className="myButton" type="submit" style={{padding:"3px 150px"}}>Salva</button>
                                    </Col>
                                </Form.Row>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}