import React from 'react';

function Untente(nome,cognome) {
    let nominativo = nome+" "+ cognome
    return {nominativo};
}

export default Untente


