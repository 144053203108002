import { Collapse } from 'antd';
import React, { Component } from 'react'

const { Panel } = Collapse;

function callback(key) {
  console.log(key);
}

const text = `
  HUIHUIHUI
`;


export default class CollapseIntelligence extends Component {
    render() {
        return (
            <div>
                <Collapse onChange={callback}>
                    <Panel header="HUIHUIHUI" key="1">
                    <Collapse defaultActiveKey="1">
                        <Panel header="HUIHUIHUI" key="1">
                        <p>{text}</p>
                        </Panel>
                    </Collapse>
                    </Panel>
                    <Panel header="HUIHUIHUI" key="2">
                    <p>{text}</p>
                    </Panel>
                    <Panel header="HUIHUIHUI" key="3">
                    <p>{text}</p>
                    </Panel>
                </Collapse>
            </div>
        )
    }
}