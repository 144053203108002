import React from 'react';
import { Container, Row,Col} from 'react-grid-system';
import '../index.css';
import MPheader from '../components/MPheader'
import isola from '../assets/profilo.jpg';
import RGBDiv from '../components/ModificaProfilo/RGBDiv'
import MPForm2 from '../components/Forms/MPForm2';
import Assets from '../components/Assets'

class ModificaProfilo extends React.Component{
    constructor() {
        super();
        this.state = {
          name: "React",
          showHideDemo1: true,
          showHideDemo2: false
        };
        this.hideComponent = this.hideComponent.bind(this);
      }

      hideComponent(name) {
        console.log(name);
        switch (name) {
          case "showHideDemo1":
            this.setState({ showHideDemo1: true,showHideDemo2: false });
            break;
          case "showHideDemo2":
            this.setState({ showHideDemo2: !this.state.showHideDemo2,showHideDemo1: false });
            break;
          default:
            this.setState({ showHideDemo1: true });
            break;
        }
      }
      
    render(){
        const { showHideDemo1, showHideDemo2} = this.state;
        return(
            <div>
                <MPheader/>

                <Container fluid>
                    <Row>
                        <Col  className="py-4"><h1 className="headerModificaProfilo">Welcome Ali!</h1></Col>
                    </Row>
                    <Row>
                        <Col className="py-1"><h5 className="underModificaProfilo">Completa la tua registrazione, crea la tua n° Isola e inizia ad utilizzare gratuitamente tutti i tool di HUI</h5></Col>
                    </Row>
                    <Row>
                        <Col><img src={isola} alt="" width="100%"></img></Col>
                    </Row>
                    <Row className="py-3">
                        <Col md={3}  className="Col_Header px-1"><a href="#/s" onClick={() => this.hideComponent("showHideDemo1")} className="none"><RGBDiv nome="Modifica"/></a></Col>
                        <Col md={3}  className="Col_Header px-1"><a href="#/s" onClick={() => this.hideComponent("showHideDemo2")} className="none"><RGBDiv nome="Assets"/></a></Col>
                        <Col md={3}  className="Col_Header px-1"><a href="#/s" className="none"><RGBDiv nome="Profilo.Play"/></a></Col>
                        <Col md={3}  className="Col_Header px-1"><a href="#/s" className="none"><RGBDiv nome="Privacy"/></a></Col>
                    </Row>
                    <Row>
                        <Col>{showHideDemo1 && <MPForm2/>}{showHideDemo2 && <Assets/>}</Col>
                    </Row>
                    
                </Container>
            </div> 

            )
        }
                    
    }
export default ModificaProfilo