import React, { Component } from 'react'
import 'antd/dist/antd.css';
import Form from 'react-bootstrap/Form'
import {Container,Row,Col} from 'react-grid-system';
import MPheader from '../../components/MPheader'
import tipoJson from '../../Data/Ui/tipo.json'
import islandJson from '../../Data/Ui/island.json'
import { Select ,Input,Dropdown} from 'semantic-ui-react'


export default class AggiungiWidget extends Component {
    constructor(props){
        super(props)
        this.state={
            ordine:"",
            tipo:"",
            Island:"",

        }
        this.handleChange = this.handleChange.bind(this)
    }
    
    handleChangeUi = (e, result) => {
        const { name, value } = result;
        this.setState({
           [name]: value
          });
      };

    handleChange(event){
        const{name,value,type,checked}=event.target
        type === "checkbox"?this.setState({[name]:checked}):this.setState({[name]:value})
    }
    handleSubmit=(event)=>{
        event.preventDefault()
        const data = this.state
        
        console.log(data)
    }
    render() {
        return (
            <div>
                <MPheader/>
                <Container fluid className="MainPageRow2">
                    <Row className="Col_margin py-4 px-2">
                        <Col className="Col_margin px-1">
                            <Form onSubmit={this.handleSubmit}>
                                <Form.Row>
                                    <Col md={4} className="Col_margin py-1 px-1">
                                        <Form.Label className="checktext">Island</Form.Label>
                                        <Dropdown placeholder='Scegli una o più isole' fluid multiple search selection options={islandJson}/>
                                    </Col>
                                    <Col md={4} className="Col_margin py-1 px-1">
                                        <Form.Label className="checktext">Tipo</Form.Label>
                                        <Dropdown placeholder='Scegli uno o più tipi' fluid multiple search selection options={tipoJson}/>
                                    </Col>
                                    <Col md={4} className="Col_margin py-1 px-1">
                                        <Form.Label className="checktext">Ordine</Form.Label>
                                        <Input fluid  placeholder='' onChange={this.handleChangeUi} name="ordine" />
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Col md={3} className="Col_margin py-1"> 
                                        <button className="myButton" type="submit" style={{padding:"3px 150px"}}>Salva</button>
                                    </Col>
                                </Form.Row>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
