import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import '../../index.css'; 






class IslandersHeader extends React.Component{
    constructor(props){
        super(props)
        this.state ={

        }
    }
    handleSubmit(){
    }
    render(){
        return(
            <div>
            <Container>
                <Row>
                    <Col className="text-center py-1"><h1 className="Islanders">{this.props.nome}</h1></Col>
                </Row>
                <Row>
                    <Col className="text-center py-1"><h5 className="checktext">{this.props.sottoheader}</h5></Col>
                </Row>
            </Container>  
            </div> 

            )
        }
                    
    }
export default IslandersHeader