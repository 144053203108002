import React from 'react'
import { Container,Col } from 'reactstrap'
import Breadcrumbs from '../components/Breadcrumbs'
import { Link } from 'react-router-dom'
import { BsFillPlusSquareFill} from "react-icons/bs";

function MPheader(props){
    return(
        <Container fluid className="MainPage">
                <Col md={12} className="Col_margin">
                <section className="sectionMain">
                    <Link to={props.link} >
                        <button className="myButton" type="button" style={{padding:"3px 20px",float: "right",zIndex:"1"}}>
                            <BsFillPlusSquareFill/> {props.nome}
                        </button>
                    </Link>
                    <Breadcrumbs/>
                </section>
                </Col>
        </Container>
    )
}

export default MPheader