import React,{Component} from 'react'
import getDate from 'date-fns/getDate'
import { Calendar, Button, Tag, Popover, Whisper, Badge } from 'rsuite';

function getTodoList(date) {
    const day = getDate(date);
  
    switch (day) {
      default:
        return [];
    }
  }
  
  function renderCell(date) {
    const list = getTodoList(date);
    const displayList = list.filter((item, index) => index < 2);
  
    if (list.length) {
      const moreCount = list.length - displayList.length;
      const moreItem = (
        <li>
          <Whisper
            placement="top"
            trigger="click"
            speaker={
              <Popover>
                {list.map((item, index) => (
                  <p key={index}>
                    <b>{item.time}</b> - {item.title}
                  </p>
                ))}
              </Popover>
            }
          >
            <a>{moreCount} more</a>
          </Whisper>
        </li>
      );
  
      return (
        <ul className="calendar-todo-list">
          {displayList.map((item, index) => (
            <li key={index}>
              <Badge /> <b>{item.time}</b> - {item.title}
            </li>
          ))}
          {moreCount ? moreItem : null}
        </ul>
      );
    }
  
    return null;
  }
  
 export default class Calendario extends Component {
    render() {
        return (
            <Calendar bordered renderCell={renderCell} />
        )
    }
}

/*

case 10:
        return [
          { time: '10:30 am', title: 'Meeting' },
          { time: '10:30 am', title: 'Meeting' },

        ];
*/