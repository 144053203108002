import React from 'react';
import MPheaderButton from '../../components/MPheaderButton'
import { Container, Row, Col } from 'react-grid-system';
import '../../index.css';
import Form from 'react-bootstrap/Form' 
import EventiTable from '../../components/DataTable/EventiTable';
import statoJson from '../../Data/Ui/stato.json'
import approvatoJson from '../../Data/Ui/approvato.json'
import direzioneJson from '../../Data/Ui/direzione.json'
import { Select,Input } from 'semantic-ui-react'



class Eventi extends React.Component{
    constructor(props){
        super(props)
        this.state={
            direzione:"",
            stato:"",
            approvato:""

        }
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event){
        const{name,value,type,checked}=event.target
        type === "checkbox"?this.setState({[name]:checked}):this.setState({[name]:value})
    }
    handleChangeUi = (e, result) => {
        const { name, value } = result;
        this.setState({
           [name]: value
          });
      };
    handleSubmit(){
        const data = this.state
    }
    render(){

        return(
            <div>
            <MPheaderButton link="/Dashboard/Aggiungi evento" nome="Aggiungi Evento"/>  
            
            
            <Container fluid className="MainPageRow2">
                <Row className="Col_margin py-4 px-1">
                    <Col className="Col_margin px-1">
                        <Form onSubmit={this.handleSubmit}>
                            <Form.Row>
                                <Col md={4} className="Col_margin px-1">
                                    <Form.Label className="checktext">Direzione</Form.Label>
                                    <Select placeholder="" options={direzioneJson}  onChange={this.handleChangeUi} name="direzione" fluid/>
                                </Col>
                                <Col md={4} className="Col_margin px-1">
                                <Form.Label className="checktext">Approvato/In attesa/Rifiutato</Form.Label>
                                    <Select placeholder="" options={approvatoJson}  onChange={this.handleChangeUi} name="approvato" fluid/>
                                </Col>
                                <Col md={4} className="Col_margin px-1">
                                    <Form.Label className="checktext">Stato</Form.Label>
                                    <Select placeholder="" options={statoJson}  onChange={this.handleChangeUi} name="stato" fluid/>
                                </Col>
                            </Form.Row>
                            <Form.Row className="Col_margin px-1 py-2">
                                    <button className="myButton" type="submit" style={{padding:"3px 150px"}}>Filtra</button>

                            </Form.Row>
                        </Form>
                    </Col>
                </Row>
            </Container>
            <Container fluid className="MainPageRow2">
                <Row className="Col_margin py-4 px-2 text-center">
                    <Col ><EventiTable/></Col>
                    
                </Row>
            </Container>   
        </div>  
            )
        }
                    
    }
export default Eventi

