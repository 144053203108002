import React, { Component } from 'react'
import 'antd/dist/antd.css';
import Form from 'react-bootstrap/Form'
import {Container,Row,Col} from 'react-grid-system';
import MPheader from '../../components/MPheader'
import statoJson from '../../Data/Form/Stato.json'
import assegnatoJson from '../../Data/Form/Assegnato.json'
import {FormGroup, Label} from 'reactstrap'
import DatePicker from "reactstrap-date-picker";

export default class AggiungiTodoFundraiser extends Component {
    constructor(props){
        super(props)
        this.state={
            scadenza: new Date().toISOString(),
            fro: new Date().toISOString(),
            froContattoDocumento:"",
            stato:"",
            todo:"",
            assegnato:"" 
        }
        this.handleChange = this.handleChange.bind(this)
    }
    handleChangeDue(value, formattedValue) {
        this.setState({
          value: value, // ISO String, ex: "2016-11-19T12:00:00.000Z"
          formattedValue: formattedValue // Formatted String, ex: "11/19/2016"
        })
      }
    handleChange(event){
        const{name,value,type,checked}=event.target
        type === "checkbox"?this.setState({[name]:checked}):this.setState({[name]:value})
    }
    handleSubmit=(event)=>{
        event.preventDefault()
        const data = this.state
        
        console.log(data)
    }
    render() {
        let statoOption=statoJson; 
        let assegnatoOption=assegnatoJson;
        return (
            <div>
                <MPheader/>
                <Container fluid className="MainPageRow2">
                    <Row className="Col_margin py-4 px-2">
                        <Col className="Col_margin px-1">
                            <Form onSubmit={this.handleSubmit}>
                                <Form.Row>
                                    <Col md={4} className="Col_margin px-1">
                                            <Form.Label className="checktext">Todo</Form.Label>
                                            <Form.Control type="text" placeholder="" onChange={this.handleChange} value={this.state.todo} name="todo"/>
                                        </Col>
                                    <Col md={4} className="Col_margin px-1">
                                        <Form.Label className="checktext">Assegnato a</Form.Label>
                                        <Form.Control as="select" onChange={this.handleChange} value={this.state.assegnato} name="assegnato">
                                            {assegnatoOption.map(o => <option key={o.id} value={o.id}>{o.text}</option>)}
                                        </Form.Control>
                                    </Col>
                                </Form.Row>              
                                <Form.Row>
                                    <Col md={4} className="Col_margin px-1">
                                        <Form.Label className="checktext">FRO / Contatto / Documento</Form.Label>
                                        <Form.Control as="select" onChange={this.handleChange} value={this.state.froContattoDocumento} name="froContattoDocumento">
                                            <option value="FRO">FRO</option>
                                            <option value="Documento">Documento</option>
                                        </Form.Control>
                                    </Col>
                                    <Col md={4} className="Col_margin px-1">
                                        <FormGroup>
                                            <Label className="checktext">FRO</Label>
                                            <DatePicker id      = "example-datepicker" 
                                                        value   = {this.state.fro} 
                                                        onChange= {(v,f) => this.handleChangeDue(v, f)} />
                                        </FormGroup>
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Col md={4} className="Col_margin px-1">
                                        <FormGroup>
                                            <Label className="checktext">Scadenza</Label>
                                            <DatePicker id      = "example-datepicker" 
                                                        value   = {this.state.scadenza} 
                                                        onChange= {(v,f) => this.handleChangeDue(v, f)} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={4} className="Col_margin px-1">
                                        <Form.Label className="checktext">Stato</Form.Label>
                                        <Form.Control as="select" onChange={this.handleChange} value={this.state.stato} name="stato">
                                            {statoOption.map(o => <option key={o.id} value={o.id}>{o.text}</option>)}
                                        </Form.Control>
                                    </Col>
                                    
                                </Form.Row>
                                <Form.Row>
                                    <Col md={3} className="Col_margin py-5"> 
                                        <button className="myButton" type="submit" style={{padding:"3px 150px"}}>Salva</button>
                                    </Col>
                                </Form.Row>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}