import React, { Component } from 'react'
import { Divider } from 'semantic-ui-react'
import { Container, Row, Col } from 'react-grid-system'
import { RiUploadCloud2Line } from "react-icons/ri";
import CommentiOptions from './CommentiOptions'
import { Form, TextArea,Checkbox } from 'semantic-ui-react'

export default class Commento extends Component {
    constructor(props){
        super(props)
        this.state ={

        }
    }

    hideComponent(name){
        this.props.onClick(name)
    }

    render() {
        return (
            <div className="commentiDiv">
            <section className="commentiSection">
                <CommentiOptions style={{padding:"3px 20px",float: "left",zIndex:"1"}}/>
            </section>
            <Container fluid className="py-3" >
                <Form>
                    <TextArea placeholder='A cosa stai pensando' className="textCommenti" style={{ minHeight: 400, resize: "none" }} />
                
                    <Row className="checktext Col_margin py-1">
                        Subscribe people to receive email notifications
                    </Row>
                    <Row className="checktext Col_margin">
                        The people you select will get an email when you post this post.
                    </Row>
                    <Row className="checktext py-4">
                        <Col md={2}> <Checkbox label='Tutti' defaultChecked/></Col>
                        <Col md={2}> <Checkbox label='Ali Toccacieli' defaultChecked/></Col>
                        <Col md={2}> <Checkbox label="Mario D'Andrea" defaultChecked/></Col>
                    
                    </Row>
                    <Divider/>
                    <Row className="noMar"> 
                        <Col md={2} className="noPad">
                            <button type="button" className="bottoneCommentiAnnulla" onClick={() => this.hideComponent("annullaCommento")}>Annulla</button>
                        </Col>
                        <Col md={2} className="noPad">
                            <button type="submit" className="bottoneCommentiInvia" onClick={() => this.hideComponent("annullaCommento")}>Commenta</button>
                        </Col>
                        <Col md={2} className="noPad">
                            <button type="submit" className="bottoneCommentiAllega"><RiUploadCloud2Line/> Allegato 1</button>
                        </Col>
                        <Col md={2} className="noPad">
                            <button type="submit" className="bottoneCommentiAllega"><RiUploadCloud2Line/> Allegato 2</button>
                        </Col>
                        <Col md={2} className="noPad">
                            <button type="submit" className="bottoneCommentiAllega"><RiUploadCloud2Line/> Allegato 3</button>
                        </Col>
                        <Col md={2} className="noPad">
                            <button type="submit" className="bottoneCommentiAllega"><RiUploadCloud2Line/> Allegato 4</button>
                        </Col>
                    </Row>  
                </Form>  
            </Container>
        </div>
        )
    }
}
