import React from 'react';
import { Container, Row,Col} from 'react-grid-system';
import '../../index.css';
import Form from 'react-bootstrap/Form'
import EnhancedTable from '../../components/DataTable/EnhancedTable';
import assegnatoJson from '../../Data/Ui/assegnato.json'
import statoJson from '../../Data/Ui/stato.json'
import effortJson from '../../Data/Ui/effort.json'
import quotazioneEffortJson from '../../Data/Ui/quotazioneEffort.json'
import MPheaderButton from '../../components/MPheaderButton'
import { Select } from 'semantic-ui-react'


class Todo extends React.Component{
    constructor(props){
        super(props)
        this.state={
            assegnato:"",
            stato:"",
            effort:"",
            quotazione:"",
            influence:false,
            fundraiser:false,
            sales:false,
            legal:false,
            suppliers:false,
            intelligence:false,
            project:false,
            board:false

        }
        this.handleChange = this.handleChange.bind(this)
    }
    handleChangeUi = (e, result) => {
        const { name, value } = result;
        this.setState({
           [name]: value
          });
      };
    handleChange(event){
        const{name,value,type,checked}=event.target
        type === "checkbox"?this.setState({[name]:checked}):this.setState({[name]:value})
    }
    handleSubmit(){
        const data = this.state
    }
    render(){
        return(
            <div>
            <MPheaderButton link="/Dashboard/Aggiungi promemoria" nome="Aggiungi promemoria"/> 
            <Container fluid className="MainPageRow2">
                <Row className="Col_margin py-4 px-2">
                    <Col className="Col_margin px-1">
                    <Form onSubmit={this.handleSubmit}>
                            <Form.Row>
                                <Col md={2} className="Col_margin py-1 px-1">
                                    <Form.Check type="checkbox" inline className="px-5 checktext" checked={this.state.influence} onChange={this.handleChange} name="influence" label="Influence" />
                                </Col>
                                <Col md={2} className="Col_margin py-1 px-1">
                                    <Form.Check type="checkbox" inline className="px-5 checktext" checked={this.state.fundraiser} onChange={this.handleChange} name="fundraiser" label="FundRaiser" />
                                </Col>
                                <Col md={2} className="Col_margin py-1 px-1">
                                    <Form.Check type="checkbox" inline className="px-5 checktext" checked={this.state.sales} onChange={this.handleChange} name="sales" label="Sales" />
                                </Col>
                                <Col md={2} className="Col_margin py-1 px-1">
                                    <Form.Check type="checkbox" inline className="px-5 checktext" checked={this.state.legal} onChange={this.handleChange} name="legal" label="Legal" />
                                </Col>
                                <Col md={2} className="Col_margin py-1 px-1">
                                    <Form.Check type="checkbox" inline className="px-5 checktext" checked={this.state.suppliers} onChange={this.handleChange} name="suppliers" label="Suppliers" />
                                </Col>
                                <Col md={2} className="Col_margin py-1 px-1">
                                    <Form.Check type="checkbox" inline className="px-5 checktext" checked={this.state.intelligence} onChange={this.handleChange} name="intelligence" label="Intelligence" />
                                </Col>
                            </Form.Row>
                            <Form.Row>
                                <Col md={2} className="Col_margin py-1 px-1">
                                    <Form.Check type="checkbox" inline className="px-5 checktext" checked={this.state.board} onChange={this.handleChange} name="board" label="Board" />
                                </Col>
                                <Col md={2} className="Col_margin py-1 px-1">
                                    <Form.Check type="checkbox" inline className="px-5 checktext" checked={this.state.project} onChange={this.handleChange} name="project" label="Projects" />
                                </Col>

                            </Form.Row>
                            <Form.Row className="Col_margin py-4">
                                
                                <Col md={3} className="Col_margin py-1 px-1">
                                    <Form.Label className="checktext">Assegnato a</Form.Label>
                                    <Select placeholder="" options={assegnatoJson}  onChange={this.handleChangeUi} name="assegnato" fluid/>
                                </Col>
                                
                                <Col md={3} className="Col_margin py-1 px-1">
                                    <Form.Label className="checktext">Stato</Form.Label>
                                    <Select placeholder="" options={statoJson}  onChange={this.handleChangeUi} name="stato" fluid/>
                                </Col>
                                <Col md={3} className="Col_margin py-1 px-1">
                                    <Form.Label className="checktext">Effort (Solo PM e GOV)</Form.Label>
                                    <Select placeholder="" options={effortJson}  onChange={this.handleChangeUi} name="effort" fluid/>
                                </Col>
                                <Col md={3} className="Col_margin py-1 px-1">
                                    <Form.Label className="checktext">Quotazione Effort (Solo PM e GOV)</Form.Label>
                                    <Select placeholder="" options={quotazioneEffortJson}  onChange={this.handleChangeUi} name="quotazione" fluid/>
                                </Col>
                            </Form.Row>
                            <Form.Row className="Col_margin px-1">
                                    <button className="myButton" type="submit" style={{padding:"3px 150px"}}>Filtra</button>
                            </Form.Row>
                        </Form>    
                    </Col>
                </Row>
            </Container>
            <Container fluid className="MainPageRow2">
                <Row className="Col_margin py-4 px-2 text-center">
                    <Col><EnhancedTable/></Col>
                    
                </Row>
            </Container> 

            </div> 

            )
        }   
    }
export default Todo
