import React from 'react'
import { Container,Col } from 'reactstrap'

function MPbenvenuto(props){
    return(
        <Container fluid className="MainPage">
                <Col md={12} className="Col_margin "><div className="info"><p class="tab"> Buongiorno {props.nome}, Ci sono <b>{props.eventi}</b> eventi totali ({props.eventi_totali} in tutto) assegnati a <b>te</b> nel profilo azienda <b>HUI</b></p></div></Col>
        </Container> 
    )
}

export default MPbenvenuto