import React, { Component } from 'react'
import MPheader from '../../components/MPheader'
import { Container } from 'semantic-ui-react'
import { Row,Col } from 'reactstrap'
import GeneralCollapse from '../../components/GeneralCollapse'

export default class GraficiSuppliers extends Component {
    render() {
        return (
            <div>
                <MPheader/>
                <Container fluid>
                    <Row className="Col_margin px-1 py-5">
                        <Col className="Col_margin px-1">
                            <GeneralCollapse/>
                        </Col>
                        
                    </Row>
                </Container>
            </div>
        )
    }
}