import React from 'react';
import { Container} from 'react-grid-system';
import '../../index.css';
import IslandersHeader from '../../components/Islanders/IslandersHeader'
import MPheader from '../../components/MPheader'
import ArchivementsDiv from '../../components/Archivement/ArchivementsDiv'
import archivementData from '../../components/Archivement/archivementData'
function Archivements(){
        const archivementComponents = archivementData.map(archivement => <ArchivementsDiv titolo={archivement.titolo} sottoTitolo={archivement.sottoTitolo} titolo_2={archivement.titolo_2} sottoTitolo_2={archivement.sottoTitolo_2}/>)
        return(
            <div>
                <MPheader/>
                <IslandersHeader nome="HUI ACHIEVEMENTS" sottoheader="Explore all the achievements you can unlock by working with HUI!"/>
                <Container>
                    {archivementComponents}
                </Container>
            </div>
            )
        }
                    
export default Archivements