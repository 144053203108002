import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import '../../index.css';
import Form from 'react-bootstrap/Form'
import SelectDa3 from './SelectDa3'
import TextForm from './TextForm'




class MPForm1 extends React.Component{
    constructor() {
        super();
        this.state = {
          name: "React"
        };
      }
    handleSubmit(){
        //const data = this.state
    }
    render(){
        return(
            <div>

            <Container fluid className="MainPageRow2">
                <Row className="Col_margin py-4 px-2">
                    <Col className="Col_margin px-1">
                        <Form onSubmit={this.handleSubmit}>

                                
                            <Form.Row className="Col_margin py-1">
                                <Col md={4} className="Col_margin py-1 px-1">
                                    <TextForm nome="Nome"/>
                                </Col>
                                <Col md={4} className="Col_margin py-1 px-1">
                                    <TextForm nome="Cognome"/>
                                </Col>
                                <Col md={4} className="Col_margin py-1 px-1">
                                    <TextForm nome="Nickname"/>
                                </Col>
                            </Form.Row>
                            <Form.Row className="Col_margin py-1">
                                <Col md={4} className="Col_margin py-1 px-1">
                                    <SelectDa3 nome="Visibilità" option1="Visibile a tutti gli utenti" option2="Pubblico" option3="Visibile solo alle company di cui faccio parte"/>
                                </Col>
                                <Col md={4} className="Col_margin py-1 px-1">
                                    <TextForm nome="Telefono"/>
                                </Col>
                                <Col md={4} className="Col_margin py-1 px-1">
                                    <TextForm nome="SitoWeb"/>
                                </Col>
                            </Form.Row>
                            <Form.Row className="Col_margin py-1">
                                <Col md={4} className="Col_margin py-1 px-1">
                                    <TextForm nome="Via"/>
                                </Col>
                                <Col md={4} className="Col_margin py-1 px-1">
                                    <TextForm nome="Città"/>
                                </Col>
                                <Col md={4} className="Col_margin py-1 px-1">
                                    <TextForm nome="Cap"/>
                                </Col>
                            </Form.Row>
                            <Form.Row className="Col_margin py-1">
                                <Col md={4} className="Col_margin py-1 px-1">
                                    <TextForm nome="Provincia"/>
                                </Col>
                                <Col md={4} className="Col_margin py-1 px-1">
                                    <TextForm nome="Regione"/>
                                </Col>
                                <Col md={4} className="Col_margin py-1 px-1">
                                <SelectDa3 nome="Nazione" option1="Italia" option2="Italia" option3="Italia"/>
                                </Col>
                            </Form.Row>
                            <Form.Row className="Col_margin px-1 py-5">
                                <button className="myButton" type="submit">Salva</button>
                            </Form.Row>
                        </Form>   
                    </Col>
                </Row>
            </Container>

            </div> 

            )
        }
                    
    }
export default MPForm1
