import React from 'react';
import '../index.css';
import { Container, Row, Col } from 'react-grid-system';
import {Logo} from '../components/Header/Logo';
import HeaderProfileDue from '../components/Header/HeaderProfileDue';

class Header extends React.Component{
    render(){
        return(

        <div className="Header">
            <Container fluid>
                <Row>
                    <Col className="py-3"><Logo/></Col>
                    <Col className="py-3 text-right"><HeaderProfileDue/></Col>
                </Row>
            </Container>
        </div>

      )
    }
        
}
export default Header
