import React, { Component } from 'react'
import MPheader from '../../components/MPheader'
import Form from 'react-bootstrap/Form'
import { Container, Row, Col } from 'reactstrap'

export default class DocumentiDocs extends Component {
    constructor(props){
        super(props)
        this.state={
            nome:"",
            ris:"",
            caricato_da:"",
            dal:"",
            al:"",
            influence:false,
            fundraiser:false,
            sales:false,
            legal:false,
            suppliers:false,
            intelligence:false,
            project:false,
            board:false
        }
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event){
        const{name,value,type,checked}=event.target
        type === "checkbox"?this.setState({[name]:checked}):this.setState({[name]:value})
    }
    handleSubmit=(event)=>{

    }
    render() {
        return (
            <div>
                <MPheader/> 
                <Form onSubmit={this.handleSubmit}>
                    <Form.Row className="Col_margin py-2 px-1">
                        <Col md={4} className="Col_margin px-1">
                            <Form.Label className="checktext">Nome</Form.Label>
                            <Form.Control type="text" placeholder="Nome" onChange={this.handleChange} value={this.state.nome} name="nome"/>
                        </Col>
                        <Col md={4} className="Col_margin px-1">
                            <Form.Label className="checktext">Caricato da</Form.Label>
                            <Form.Control as="select" onChange={this.handleChange} value={this.state.caricato_da} name="caricato_da">
                                <option value="10">10</option>
                                <option value="100">100</option>
                                <option value="1000">1000</option>
                            </Form.Control>
                        </Col>
                    </Form.Row>
                    <Form.Row className="Col_margin py-2 px-1">
                        <Col md={4} className="Col_margin px-1">
                            <Form.Label className="checktext">Caricato dal (date da fare)</Form.Label>
                            <Form.Control type="text" placeholder="Nome" onChange={this.handleChange} value={this.state.dal} name="dal"/>
                        </Col>
                        <Col md={4} className="Col_margin px-1">
                            <Form.Label className="checktext">Caricato al(date da fare)</Form.Label>
                            <Form.Control type="text" placeholder="Nome" onChange={this.handleChange} value={this.state.al} name="al"/>
                        </Col>
                        <Col md={4} className="Col_margin px-1">
                            <Form.Label className="checktext">Risultati per Pagina</Form.Label>
                            <Form.Control as="select" onChange={this.handleChange} value={this.state.ris} name="ris">
                                <option value="10">10</option>
                                <option value="100">100</option>
                                <option value="1000">1000</option>
                            </Form.Control>
                        </Col>
                    </Form.Row>
                    <Form.Row>
                                <Col md={2} className="Col_margin py-1 px-1">
                                    <Form.Check type="checkbox" inline className="px-5 checktext" checked={this.state.influence} onChange={this.handleChange} name="influence" label="Influence" />
                                </Col>
                                <Col md={2} className="Col_margin py-1 px-1">
                                    <Form.Check type="checkbox" inline className="px-5 checktext" checked={this.state.fundraiser} onChange={this.handleChange} name="fundraiser" label="FundRaiser" />
                                </Col>
                                <Col md={2} className="Col_margin py-1 px-1">
                                    <Form.Check type="checkbox" inline className="px-5 checktext" checked={this.state.sales} onChange={this.handleChange} name="sales" label="Sales" />
                                </Col>
                                <Col md={2} className="Col_margin py-1 px-1">
                                    <Form.Check type="checkbox" inline className="px-5 checktext" checked={this.state.legal} onChange={this.handleChange} name="legal" label="Legal" />
                                </Col>
                                <Col md={2} className="Col_margin py-1 px-1">
                                    <Form.Check type="checkbox" inline className="px-5 checktext" checked={this.state.suppliers} onChange={this.handleChange} name="suppliers" label="Suppliers" />
                                </Col>
                                <Col md={2} className="Col_margin py-1 px-1">
                                    <Form.Check type="checkbox" inline className="px-5 checktext" checked={this.state.intelligence} onChange={this.handleChange} name="intelligence" label="Intelligence" />
                                </Col>

                            </Form.Row>
                            <Form.Row>
                                <Col md={2} className="Col_margin py-1 px-1">
                                    <Form.Check type="checkbox" inline className="px-5 checktext" checked={this.state.board} onChange={this.handleChange} name="board" label="Board" />
                                </Col>
                                <Col md={2} className="Col_margin py-1 px-1">
                                    <Form.Check type="checkbox" inline className="px-5 checktext" checked={this.state.project} onChange={this.handleChange} name="project" label="Projects" />
                                </Col>

                            </Form.Row>
                    <Form.Row className="Col_margin px-1 py-2">
                        <button className="myButton" type="submit">Filtra</button>
                    </Form.Row>
                </Form>
            </div>
        )
    }
}