import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import image from '../assets/omino.png'

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
}));

export default function SimplePopover() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
        <img src={image} alt="" onClick={handleClick} width="50px">
        </img>
        <Popover 
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}   
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'center',
                horizontal: 'left',
            }}
            >
            <Typography className={classes.typography}>Ciao Ali Toccacieli, benvenuto nel wall. Ecco l'elenco di tutti i post e di tutti i 
            commenti pubblicati da tutti gli Islanders nelle Isole in cui fai parte.<br/> Di ogni post potrai vedere l'autore, la data e l'ora in cui 
            è stato pubblicato, in che app è stato pubblicato e la unit e funzione di riferimento.<br/> Utilizza i filtri per visualizzare più rapidamente 
            il post da te desiderato.
            Pubblica un nuovo post! Clicca qui
            Per qualsiasi altra informazione contattaci o visita i tutorial nel Blog di HUI!</Typography>
        </Popover>
    </div>
  );
}