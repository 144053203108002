/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import '../../index.css';
import image from '../../assets/isola.jpg'
import { Container,Col,Row } from 'reactstrap';
import "react-sweet-progress/lib/style.css";
import { BsArchive} from "react-icons/bs";
import {Progress,Segment } from 'semantic-ui-react'

 






export default class Achivement extends React.Component{
    constructor(props){
        super(props)
        this.state={
            percent:99
        }
    }
    render(){
        return(
            <Row className="py-4">
            <Col md={6} className="py-4">
            <div className="card_2">
                <img src={image} alt="John" width="100%" />
                <div className="archivement">
                    <p><h5>{this.props.titolo}</h5>{this.props.sottoTitolo}</p>
                    <Container className="margin">
                        <Row>
                            <Col md={4} className="Col_margin py-1 px-1 HSoldi " ><p className="oro">H<br/>+500.00</p></Col>
                            <Col md={8} className="Col_margin py-1 px-1 NomeArchivement ">Archivement Premio</Col>
                        </Row>
                    </Container>
                    <div className="margineProgress">
                    <Progress percent={this.state.percent} indicating/>
                    </div>
                </div>
                
            </div>
            </Col>
            <Col md={6} className="py-4">
                <div className="card_2">
                    <img src={image} alt="John" width="100%" />
                    <div className="archivement">
                        <p><h5>{this.props.titolo_2}</h5>{this.props.sottoTitolo_2}</p>
                        <Container className="margin">
                            <Row>
                                <Col md={4} className="Col_margin py-1 px-1 HSoldi " ><p className="oro">H<br/>+500.00</p></Col>
                                <Col md={8} className="Col_margin py-1 px-1 NomeArchivement ">Archivement Premio</Col>
                            </Row>
                        </Container>
                        <div className="margineProgress">
                        <Progress percent={this.state.percent} indicating />
                        </div>
                    </div>
                
                </div>
            </Col></Row>
        )
                    
    }
}