import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import '../../index.css';
import IslandersHeader from '../../components/Islanders/IslandersHeader'
import MPheader from '../../components/MPheader'
import MarketplaceDiv from '../../components/MarketplaceDiv'

class Marketplace extends React.Component{
    render(){
        return(
            <div>
            <MPheader/>
            <IslandersHeader nome="HUI MARKETPLACE" sottoheader="Buy all Hui apps and services and sell your Island’s goods and services"/>
            <Container>
                <Row className="py-5">
                    <Col md={6} className="py-5"><MarketplaceDiv nome="HUI MARKETPLACE" descrizione="Background personalizzato per Video-Call"/></Col>
                    <Col md={6} className="py-5"><MarketplaceDiv nome="HUI MARKETPLACE" descrizione="Background personalizzato per Home"/></Col>
                </Row>
            </Container>
            </div>
            )
        }
                    
    }
export default Marketplace