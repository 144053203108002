import React, { Component } from 'react'
import MPheaderButton from '../../components/MPheaderButton'
import Form from 'react-bootstrap/Form'
import { Container, Row, Col } from 'reactstrap'


export default class ListaBeni extends Component {
    constructor(props){
        super(props)
        this.state={
            nome:"",
            ris:""
        }
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event){
        const{name,value,type,checked}=event.target
        type === "checkbox"?this.setState({[name]:checked}):this.setState({[name]:value})
    }
    handleSubmit=(event)=>{
    }
    render() {
        return (
            <div>
                <MPheaderButton link="./Lista Beni/Aggiungi Beni" nome="Aggiungi Beni"/>
                <Form onSubmit={this.handleSubmit}>
                    <Form.Row className="Col_margin py-2 px-1">
                        <Col md={6} className="Col_margin px-1">
                            <Form.Label className="checktext">Nome</Form.Label>
                            <Form.Control type="text" placeholder="Nome" onChange={this.handleChange} value={this.state.nome} name="nome"/>
                        </Col>
                        <Col md={6} className="Col_margin px-1">
                            <Form.Label className="checktext">Risultati per Pagina</Form.Label>
                            <Form.Control as="select" onChange={this.handleChange} value={this.state.ris} name="ris">
                                <option value="10">10</option>
                                <option value="100">100</option>
                                <option value="1000">1000</option>
                            </Form.Control>
                        </Col>
                    </Form.Row>
                    <Form.Row className="Col_margin px-1 py-2">
                        <button className="myButton" type="submit">Filtra</button>
                    </Form.Row>
                </Form>
            </div>
        )
    }
}
