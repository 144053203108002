import React, { Component } from 'react'
import MPheaderButton from '../../components/MPheaderButton'
import { Container, Row, Col } from 'reactstrap'
import Form from 'react-bootstrap/Form'

export default class DocumentiFundraiser extends Component {
    constructor(props){
        super(props)
        this.state={
            nome:"",
            tipo:"",
            company:"",
            livello:"",
            ris:"",
            autore:""
        }
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event){
        const{name,value,type,checked}=event.target
        type === "checkbox"?this.setState({[name]:checked}):this.setState({[name]:value})
    }
    handleSubmit=(event)=>{

    }
    render() {
        return (
            <div>
                <MPheaderButton link="./Documenti/Aggiungi Documento" nome="Aggiungi Documento"/>   
                <Container fluid>
                        <Form onSubmit={this.handleSubmit}>
                            <Form.Row className="Col_margin py-2 px-1">
                                <Col md={3} className="Col_margin px-1">
                                    <Form.Label className="checktext">Nome</Form.Label>
                                    <Form.Control type="text" placeholder="Nome" onChange={this.handleChange} value={this.state.nome} name="nome"/>
                                </Col>
                                <Col md={3} className="Col_margin px-1">
                                        <Form.Label className="checktext">Tipo</Form.Label>
                                        <Form.Control as="select" onChange={this.handleChange} value={this.state.tipo} name="tipo">
                                            <option value="Tipo">Tipo</option>
                                        </Form.Control>
                                </Col>
                            </Form.Row>
                            <Form.Row className="Col_margin py-2 px-1">
                                <Col md={3} className="Col_margin px-1">
                                        <Form.Label className="checktext">Company</Form.Label>
                                        <Form.Control as="select" onChange={this.handleChange} value={this.state.company} name="company">
                                            <option value="Company">Company</option>
                                        </Form.Control>
                                </Col>
                                <Col md={3} className="Col_margin px-1">
                                        <Form.Label className="checktext">Livello</Form.Label>
                                        <Form.Control as="select" onChange={this.handleChange} value={this.state.livello} name="livello">
                                            <option value="Nazione">Livello</option>
                                        </Form.Control>
                                </Col>
                            </Form.Row>
                            <Form.Row className="Col_margin py-2 px-1">
                                <Col md={3} className="Col_margin px-1">
                                        <Form.Label className="checktext">Autore</Form.Label>
                                        <Form.Control as="select" onChange={this.handleChange} value={this.state.autore} name="autore">
                                            <option value="Autore">Autore</option>
                                        </Form.Control>
                                </Col>
                                <Col md={3} className="Col_margin px-1">
                                        <Form.Label className="checktext">Risultati per pagina</Form.Label>
                                        <Form.Control as="select" onChange={this.handleChange} value={this.state.ris} name="ris">
                                            <option value="10">10</option>
                                            <option value="100">100</option>
                                            <option value="1000">1000</option>
                                        </Form.Control>
                                </Col>
                            </Form.Row>
                            <Form.Row className="Col_margin px-1 py-2">
                                <button className="myButton" type="submit">Filtra</button>
                            </Form.Row>
                    </Form>
            </Container>
                
        </div>
        )
    }
}
