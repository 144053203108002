import React, { Component } from 'react'
import { Container, Row, Col } from 'reactstrap'
import Form from 'react-bootstrap/Form'

export default class NuovaIsolaDiv1 extends Component {
    constructor(props){
        super(props)
        this.state={
            nome:"",
            telefono:"",
            sitoweb:"",
            via:"",
            città:"",
            cap:"",
            provincia:"",
            regione:"",
            nazione:"",
            company:""
        }
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event){
        const{name,value,type,checked}=event.target
        type === "checkbox"?this.setState({[name]:checked}):this.setState({[name]:value})
    }
    handleSubmit=(event)=>{

    }
    render() {
        return (
            <div>
                <Container fluid>
                        <Form onSubmit={this.handleSubmit}>
                            <Form.Row className="Col_margin py-2 px-1">
                                <Col md={4} className="Col_margin px-1">
                                    <Form.Label className="checktext">Nome</Form.Label>
                                    <Form.Control type="text" placeholder="" onChange={this.handleChange} value={this.state.nome} name="nome"/>
                                </Col>
                            </Form.Row>
                            <Form.Row className="Col_margin py-2 px-1">
                                <Col md={4} className="Col_margin px-1">
                                    <Form.Label className="checktext">Telefono</Form.Label>
                                    <Form.Control type="text" placeholder="" onChange={this.handleChange} value={this.state.telefono} name="telefono"/>
                                </Col>
                                <Col md={4} className="Col_margin px-1">
                                    <Form.Label className="checktext">Sito Web</Form.Label>
                                    <Form.Control type="text" placeholder="" onChange={this.handleChange} value={this.state.sitoweb} name="sitoweb"/>
                                </Col>
                            </Form.Row>
                            <Form.Row className="Col_margin py-2 px-1">
                                <Col md={4} className="Col_margin px-1">
                                    <Form.Label className="checktext">Via</Form.Label>
                                    <Form.Control type="text" placeholder="" onChange={this.handleChange} value={this.state.via} name="via"/>
                                </Col>
                                <Col md={4} className="Col_margin px-1">
                                    <Form.Label className="checktext">Città</Form.Label>
                                    <Form.Control type="text" placeholder="" onChange={this.handleChange} value={this.state.città} name="città"/>
                                </Col>
                            </Form.Row>
                            <Form.Row className="Col_margin py-2 px-1">
                                <Col md={4} className="Col_margin px-1">
                                    <Form.Label className="checktext">CAP</Form.Label>
                                    <Form.Control type="text" placeholder="" onChange={this.handleChange} value={this.state.cap} name="cap"/>
                                </Col>
                                <Col md={4} className="Col_margin px-1">
                                    <Form.Label className="checktext">Provincia</Form.Label>
                                    <Form.Control type="text" placeholder="" onChange={this.handleChange} value={this.state.provincia} name="provincia"/>
                                </Col>
                            </Form.Row>
                            <Form.Row className="Col_margin py-2 px-1">
                                <Col md={4} className="Col_margin px-1">
                                    <Form.Label className="checktext">Regione</Form.Label>
                                    <Form.Control type="text" placeholder="" onChange={this.handleChange} value={this.state.regione} name="regione"/>
                                </Col>
                                <Col md={4} className="Col_margin px-1">
                                    <Form.Label className="checktext">Nazione</Form.Label>
                                    <Form.Control type="text" placeholder="" onChange={this.handleChange} value={this.state.nazione} name="nazione"/>
                                </Col>
                            </Form.Row>
                            <Form.Row className="Col_margin py-2 px-1">
                                <Col md={12} className="Col_margin px-1">
                                    <Form.Label className="checktext">Company Widget (for Qualified Investors only)</Form.Label>
                                    <Form.Control type="text" placeholder="" onChange={this.handleChange} value={this.state.telefono} name="telefono"/>
                                </Col>
                            </Form.Row>
                            <Form.Row className="Col_margin px-1 py-2">
                                <button className="myButton" type="submit">Salva</button>
                        </Form.Row>
                    </Form>
                </Container>
                
            </div>
        )
    }
}
