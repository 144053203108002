import React,{Component} from 'react'
import { Container, Row, Col } from 'react-grid-system';
import IslandersHeader from '../../components/Islanders/IslandersHeader'
import MPheader from '../../components/MPheader'
import IslandDiv from '../../components/IslandDiv'

export default class Island extends Component {
    render() {
        return (
            
            <div>
                <MPheader/>
                <IslandersHeader nome="HUI ISLAND" sottoheader="Explore our worldwide diversified ecosystem and discover new exciting Islands"/>
                <Container>
                    <Row className="px-1 py-5">
                        <IslandDiv/>
                        <IslandDiv/>
                        <IslandDiv/>
                    </Row>
                </Container>
            </div>
        )
    }
}
