import React, { Component } from 'react'
import 'antd/dist/antd.css';
import Form from 'react-bootstrap/Form'
import {Container,Row,Col} from 'react-grid-system';
import MPheader from '../../components/MPheader';
import islandJson from '../../Data/Form/Island.json'
import vistaJson from '../../Data/Form/Vista.json'
import assetJson from '../../Data/Form/Asset.json'
import risJson from '../../Data/Form/Risultati.json'
import autoreJson from '../../Data/Form/Autore.json'
import attivitàJson from '../../Data/Form/Attività.json';



export default class Walls extends Component {
    constructor(props){
        super(props)
        this.state={
            nome:"",
            island:"",
            attività:"", 
            asset:"",
            autore:"",
            vista:"",
            ris:""

        }
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event){
        const{name,value,type,checked}=event.target
        type === "checkbox"?this.setState({[name]:checked}):this.setState({[name]:value})
    }
    handleSubmit=(event)=>{
        event.preventDefault()
        const data = this.state
        
        console.log(data)
    }
    render() {
        let vistaOption = vistaJson;
        let assetOption = assetJson;
        let autoreOption = autoreJson;
        let attivitàOption = attivitàJson;
        let islandOption = islandJson;
        let risOption = risJson;
        return (
            <div>
                <MPheader/>
                <Container fluid className="MainPageRow2">
                    <Row className="Col_margin py-4 px-2">
                        <Col className="Col_margin px-1">
                            <Form onSubmit={this.handleSubmit}>
                                <Form.Row>
                                    <Col md={4} className="Col_margin  px-1">
                                        <Form.Label className="checktext">Nome</Form.Label>
                                        <Form.Control type="text" placeholder="" onChange={this.handleChange} value={this.state.nome} name="nome"/>
                                    </Col>
                                    <Col md={4} className="Col_margin px-1">
                                        <Form.Label className="checktext">Island</Form.Label>
                                        <Form.Control as="select" onChange={this.handleChange} value={this.state.island} name="island">
                                            {islandOption.map(o => <option key={o.id} value={o.id}>{o.text}</option>)}
                                        </Form.Control>
                                    </Col>
                                    <Col md={4} className="Col_margin px-1">
                                        <Form.Label className="checktext">Attività</Form.Label>
                                        <Form.Control as="select" onChange={this.handleChange} value={this.state.attività} name="attività">
                                            {attivitàOption.map(o => <option key={o.id} value={o.id}>{o.text}</option>)}
                                        </Form.Control>
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Col md={4} className="Col_margin px-1">
                                        <Form.Label className="checktext">Attività</Form.Label>
                                        <Form.Control as="select" onChange={this.handleChange} value={this.state.attività} name="attività">
                                            {attivitàOption.map(o => <option key={o.id} value={o.id}>{o.text}</option>)}
                                        </Form.Control>
                                    </Col>
                                    <Col md={4} className="Col_margin px-1">
                                        <Form.Label className="checktext">Asset</Form.Label>
                                        <Form.Control as="select" onChange={this.handleChange} value={this.state.asset} name="asset">
                                            {assetOption.map(o => <option key={o.id} value={o.id}>{o.text}</option>)}
                                        </Form.Control>
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Col md={4} className="Col_margin px-1">
                                        <Form.Label className="checktext">Autore</Form.Label>
                                        <Form.Control as="select" onChange={this.handleChange} value={this.state.autore} name="autore">
                                            {autoreOption.map(o => <option key={o.id} value={o.id}>{o.text}</option>)}
                                        </Form.Control>
                                    </Col>
                                    <Col md={4} className="Col_margin px-1">
                                        <Form.Label className="checktext">Filtra per</Form.Label>
                                        <Form.Control as="select" onChange={this.handleChange} value={this.state.vista} name="vista">
                                            {vistaOption.map(o => <option key={o.id} value={o.id}>{o.text}</option>)}
                                        </Form.Control>
                                    </Col>
                                    <Col md={4} className="Col_margin px-1">
                                        <Form.Label className="checktext">Risultati per pagina</Form.Label>
                                        <Form.Control as="select" onChange={this.handleChange} value={this.state.ris} name="ris">
                                            {risOption.map(o => <option key={o.id} value={o.id}>{o.text}</option>)}
                                        </Form.Control>
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Col md={3} className="Col_margin py-5"> 
                                        <button className="myButton" type="submit" style={{padding:"3px 150px"}}>Filtra</button>
                                    </Col>
                                </Form.Row>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}