const archivementData = [
    {
        id:1,
        titolo:"Titolo",
        sottoTitolo:"sottoTitolo",
        titolo_2:"Titolo2",
        sottoTitolo_2:"sottoSecondo"
    },
    {
        id:2,
        titolo:"Titolo",
        sottoTitolo:"sottoTitolo",
        titolo_2:"Titolo2",
        sottoTitolo_2:"sottoSecondo"
    }
]
export default archivementData