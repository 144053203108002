import React, { Component } from 'react'
import { Container,Col,Row } from 'reactstrap'
import CustomCalendario from '../../components/Sales/CustomCalendario'
import MPheaderButton from '../../components/MPheaderButton'
import Form from 'react-bootstrap/Form'
import SalesTodoTable from '../../components/DataTable/SalesTableTodo'
import nazioneJson from '../../Data/Form/Nazione.json'
import statoJson from '../../Data/Form/Stato.json'
import {FormGroup, Label} from 'reactstrap'
import DatePicker from "reactstrap-date-picker";

export default class TodoSales extends Component {
    constructor(props){
        super(props)
        this.state={
            inizio: new Date().toISOString(), 
            fine: new Date().toISOString(),
            nome:"",
            nazione:"",
            opportunità:"",
            contatto:"",
            stato:""
        }
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event){
        const{name,value,type,checked}=event.target
        type === "checkbox"?this.setState({[name]:checked}):this.setState({[name]:value})
    }
    handleSubmit=(event)=>{
        event.preventDefault()
        const data = this.state
        console.log(data)
    }
    render() {
        let statoOption = statoJson;
        let nazioneOption = nazioneJson;
        return (
            <div>
                <MPheaderButton link="./Todo/Aggiungi Todo" nome="Aggiungi Todo"/> 
                <Container fluid>
                    <Row className="Col_margin px-1 py-5">
                        <Col className="Col_margin px-1" md={6}>
                            <CustomCalendario/>
                        </Col>
                        <Col className="Col_margin px-1 py-5">
                            <Form onSubmit={this.handleSubmit}>
                                    <Form.Row className="Col_margin py-2 px-1">
                                        <Col md={6} className="Col_margin px-1">
                                            <Form.Label className="checktext">Nome</Form.Label>
                                            <Form.Control type="text" placeholder="Nome" onChange={this.handleChange} value={this.state.nome} name="nome"/>
                                        </Col>
                                        <Col md={6} className="Col_margin px-1">
                                            <Form.Label className="checktext">Nazione</Form.Label>
                                            <Form.Control as="select" onChange={this.handleChange} value={this.state.nazione} name="nazione">
                                                {nazioneOption.map(o => <option key={o.id} value={o.id}>{o.text}</option>)}
                                            </Form.Control>
                                        </Col>
                                    </Form.Row>
                                    <Form.Row className="Col_margin py-2 px-1">
                                        <Col md={6} className="Col_margin px-1">
                                            <Form.Label className="checktext">Opportunità</Form.Label>
                                            <Form.Control as="select" onChange={this.handleChange} value={this.state.aggrega} name="opportunità">
                                                <option value="giorno">none</option>
                                            </Form.Control>
                                        </Col>
                                        <Col md={6} className="Col_margin px-1">
                                            <Form.Label className="checktext">Contatto</Form.Label>
                                            <Form.Control type="text" placeholder="Nome" onChange={this.handleChange} value={this.state.contatto} name="contatto"/>
                                        </Col>
                                    </Form.Row>
                                    <Form.Row className="Col_margin py-2 px-1">
                                        <Col md={6} className="Col_margin px-1">
                                            <FormGroup>
                                                <Label className="checktext">Data inizio</Label>
                                                <DatePicker id      = "example-datepicker" 
                                                            value   = {this.state.inizio} 
                                                            onChange= {(v,f) => this.handleChangeDue(v, f)} />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6} className="Col_margin px-1">
                                            <FormGroup>
                                                <Label className="checktext">Data fine</Label>
                                                <DatePicker id      = "example-datepicker" 
                                                            value   = {this.state.fine} 
                                                            onChange= {(v,f) => this.handleChangeDue(v, f)} />
                                            </FormGroup>
                                        </Col>
                                    </Form.Row>
                                    <Form.Row className="Col_margin py-2 px-1">
                                        <Col md={6} className="Col_margin px-1">
                                            <Form.Label className="checktext">Stato</Form.Label>
                                            <Form.Control as="select" onChange={this.handleChange} value={this.state.stato} name="stato">
                                                {statoOption.map(o => <option key={o.id} value={o.id}>{o.text}</option>)}
                                            </Form.Control>
                                        </Col>
                                    </Form.Row>
                                    <Form.Row className="Col_margin px-1 py-2">
                                        <Col md={3} className="Col_margin py-1"> 
                                            <button className="myButton" type="submit" style={{padding:"3px 150px"}}>Filtra</button>
                                        </Col>
                                    </Form.Row>
                                </Form>  
                        </Col>
                    </Row>
                    <Row className="Col_margin py-5 px-1">
                        <SalesTodoTable/>
                    </Row>
                </Container>
            </div>
        )
    }
}
