import React from 'react';
import ProgettiTable from '../../components/DataTable/ProgettiTable'
import { Container, Row, Col } from 'react-grid-system';
import '../../index.css';
import MPheaderButton from '../../components/MPheaderButton'
import SelectDa3 from '../../components/Forms/SelectDa3'
import Form from 'react-bootstrap/Form'
import TextForm from '../../components/Forms/TextForm';

class Progetti extends React.Component{
    handleSubmit(){
    }
    render(){
        return(
            <div>
           <MPheaderButton link="./Progetti/Aggiungi Progetto" nome="Aggiungi Progetto"/> 
            
            
            <Container fluid className="MainPageRow2">
                <Form onSubmit={this.handleSubmit}>
                    <Form.Row className="Col_margin py-4 px-1">
                        <Col md={4} className="Col_margin px-1">
                            <TextForm nome="Nome" />
                        </Col>
                        <Col md={4} className="Col_margin px-1">
                            <SelectDa3 nome="Risultati per pagina" option1="10" option2="100" option3="1000"/>
                        </Col>
                        <Col md={4} className="Col_margin px-1">
                        </Col>
                    </Form.Row>
                    <Form.Row className="Col_margin px-1 py-2">
                        <button className="myButton" type="submit">Filtra</button>
                    </Form.Row>
                </Form>
            </Container>
            <Container fluid className="MainPageRow2">
                <Row className="Col_margin py-4 px-2 text-center">
                    <ProgettiTable/>
                </Row>
            </Container>   

            </div> 

            )
        }
                    
    }
export default Progetti