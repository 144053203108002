import React from 'react';
import '../index.css';




class Assets extends React.Component{
    constructor() {
        super();
        this.state = {
          name: "React"
        };
      }
    handleSubmit(){
        //const data = this.state
    }
    render(){
        return(
            <div>
                <h4 className="checktext">Hai idee di business? Hai competenze o capitali da investire?
                 Aggiungere al tuo profilo gli elementi di cui sei apportatore ti darà maggiore 
                 visibilità nella community e potrà far nascere nuove opportunità di business.</h4>

            </div> 

            )
        }
                    
    }
export default Assets