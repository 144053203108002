import React, { Component } from 'react'
import { Col } from 'reactstrap'

export default class IslandDiv extends Component {
    render() {
        return (
            <Col md={4}  className="px-1 py-2">
                <div className="islandDiv">
                    <header className="immagineHeader"></header>
                    
                    <a href="#/#" alt="">
                        <div className="immagineIsola"></div>
                        <div className="checktext spanDiv">                    
                            NOME
                        </div>
                    </a>

                </div>
            </Col>
        )
    }
}
