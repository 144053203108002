import React, { Component } from 'react'
import { Container,Col,Row } from 'reactstrap'
import CustomCalendario from '../../components/Sales/CustomCalendario'
import MPheaderButton from '../../components/MPheaderButton'
import Form from 'react-bootstrap/Form'
import ProjectMilestoneTable from '../../components/DataTableNew/ProjectMilestoneTable'
import CalendarioReact from '../../components/CalendarioReact'


export default class MilestoneProject extends Component {
    constructor(props){
        super(props)
        this.state={
            nome:"",
            attivita:"",
            ris:""
        }
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event){
        const{name,value,type,checked}=event.target
        type === "checkbox"?this.setState({[name]:checked}):this.setState({[name]:value})
    }
    handleSubmit=(event)=>{

    }
    render() {
        return (
            <div>
               <MPheaderButton link="./Milestone/Aggiungi Milestone" nome="Aggiungi Milestone"/> 
                <Container fluid>
                    <Row className="Col_margin px-1 py-5">
                        <Col className="Col_margin px-1" md={6}>
                            <CalendarioReact/>
                        </Col>
                        <Col className="Col_margin px-1 py-5">
                            <Form onSubmit={this.handleSubmit}>
                                    <Form.Row className="Col_margin py-2 px-1">
                                        <Col md={6} className="Col_margin px-1">
                                            <Form.Label className="checktext">Nome</Form.Label>
                                            <Form.Control type="text" placeholder="Nome" onChange={this.handleChange} value={this.state.nome} name="nome"/>
                                        </Col>
                                        <Col md={6} className="Col_margin px-1">
                                            <Form.Label className="checktext">Attività</Form.Label>
                                            <Form.Control as="select" onChange={this.handleChange} value={this.state.company} name="attivita">
                                                <option value="Attività">Attività</option>
                                            </Form.Control>
                                        </Col>
                                    </Form.Row>
                                    <Form.Row className="Col_margin py-2 px-1">
                                    <Col md={6} className="Col_margin px-1">
                                            <Form.Label className="checktext">Risultati per pagina</Form.Label>
                                            <Form.Control as="select" onChange={this.handleChange} value={this.state.assegnato} name="ris">
                                                <option value="10">10</option>
                                                <option value="100">100</option>
                                                <option value="1000">1000</option>
                                            </Form.Control>
                                        </Col>
                                    </Form.Row>
                                
                                    <Form.Row className="Col_margin px-1 py-2">
                                        <button className="myButton" type="submit">Filtra</button>
                                </Form.Row>
                                </Form>  
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}> 

                            <ProjectMilestoneTable/>
                        </Col>                   
                    </Row>
                </Container>
            </div>
        )
    }
}
