import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import '../../index.css';
import IslandersHeader from '../../components/Islanders/IslandersHeader'
import MPheader from '../../components/MPheader'
import IslandersDiv from '../../components/Islanders/IslandersDiv'
class Islanders extends React.Component{
    render(){
        return(
            <div>
            <MPheader/>
            <IslandersHeader nome="HUI ISLANDERS" sottoheader="Explore our worldwide community and get in touch with startup founders, professionals, investors, business connectors and more"/>
            <Container>
                <Row className="py-5">
                    <Col md={3} className="py-5"><IslandersDiv/></Col>
                    <Col md={3} className="py-5"><IslandersDiv/></Col>
                    <Col md={3} className="py-5"><IslandersDiv/></Col>
                    <Col md={3} className="py-5"><IslandersDiv/></Col>
                </Row>
                <Row className="py-5">
                    <Col md={3} className="py-5" ><IslandersDiv/></Col>
                    <Col md={3} className="py-5"><IslandersDiv/></Col>
                    <Col md={3} className="py-5" ><IslandersDiv/></Col>
                    <Col md={3} className="py-5"><IslandersDiv/></Col>
                </Row>
            </Container>
            </div>
            )
        }
                    
    }
export default Islanders