import React, { Component } from 'react'
import { BsFillPersonFill,BsFillPersonLinesFill,BsFillBootstrapFill,BsPeopleCircle} from "react-icons/bs";
export default class HeaderProfileDue extends Component {
    render() {
        return (
            <div>
                    <div className="divsideDue">
                        <input type="checkbox" id="check30"/>
                        <label className="lab_2Due" for="check30">PROFILO</label>
                        <ul>
                            <li ><a href="/Profilo"><BsFillPersonFill/>Profilo</a></li>
                            <li><a href="/ModificaProfilo"><BsFillPersonLinesFill/>Modifica Profilo</a></li>
                            <li><a href="#/action-3"><BsFillBootstrapFill/>Blog</a></li>
                        </ul>
                    </div>
            </div>
        )
    }
}
