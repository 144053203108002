import React from 'react';
import { Container, Row,Col} from 'react-grid-system';
import '../index.css';
import MPheader from '../components/MPheader'
import ImageProfile from '../components/imageProfile'
import hui from '../assets/hui.png';

class Profilo extends React.Component{
    render(){
        return(
            <div>
                <MPheader/>


                <Container fluid>
                    <Row className="profiloHeader"><ImageProfile/></Row>
                        
                    <Row>
                        <Col right md={4} className="text center"></Col>
                        <Col right md={4} className="text center"><h2 className="nomeProfilo">Ali Toccacieli</h2></Col>
                        <Col right md={4} className="text center"></Col>
                    </Row>
                    <Row className="Col_header py-5"> 
                        <Col>
                            <h5 className="py-3 Col_Header">Descrizione dell’islander:</h5>
                            <p className="checktext Col_Header">Studente universitario alla Sapienza , in corso al terzo e ultimo anno di triennale , 
                            durante l’anno abbiamo coperto piu aspetti della programmazione e gestione di base dei dati , linguaggi trattati durante
                             questi 3 anni in modo piu approfondito (non sono tutti i linguaggi usati ma i piu approfonditi) sono : Python , Java, Javascript 
                             , Html, XML , PHP , PosgreSql piu molte funzionalita usate in esse. Per DataBase abbiamo avuto 2 corsi che hanno coperto 
                             l’aspetto sia Dell’analista che del progettista avendo quindi svolto requisiti , specifiche , ER , UML case , PosgreSQL.</p>
                            <h5 className="py-3 Col_Header">Ali lavora alle seguenti Isole:</h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={2}><a href="/#HUI"><img src={hui} alt="" width="200px"/></a></Col>
                    </Row>
                    <Row>
                        <Col>
                            <h5 className="py-3 Col_Header">Gli item di Ali:</h5>
                        </Col>
                    </Row>
                    
                </Container>
            </div> 

            )
        }
                    
    }
export default Profilo
