import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import '../../index.css';
import MPheader from '../../components/MPheader'
import TextForm from '../../components/Forms/TextForm';
import Form from 'react-bootstrap/Form'
import assegnatoJson from '../../Data/Ui/assegnato.json'
import unitJson from '../../Data/Ui/unit.json'
import funzioneJson from '../../Data/Ui/funzione.json'
import islandJson from '../../Data/Ui/island.json'
import progettoJson from '../../Data/Ui/progetto.json'
import autoreJson from '../../Data/Ui/autore.json'
import teamJson from '../../Data/Ui/team.json'
import statusJson from '../../Data/Ui/status.json'
import vistaJson from '../../Data/Ui/vista.json'
import risultatiJson from '../../Data/Ui/risultati.json'
import prioritàJson from '../../Data/Ui/priorità.json'
import tipoJson from '../../Data/Ui/tipo.json'
import { Select ,Input,Dropdown} from 'semantic-ui-react'

import {FormGroup, Label} from 'reactstrap'
import DatePicker from "reactstrap-date-picker";






class Gantt extends React.Component{
    constructor(props){
        super(props)
        this.state={
            
            avvio: new Date().toISOString(), 
            fine: new Date().toISOString(),
            nome:"",
            unit:"",
            funzione:"",
            island:"",
            autore:"",
            assegnato:"",
            team:"",
            status:"",
            vista:"",
            risultati:""

        }
        this.handleChange = this.handleChange.bind(this)
    }
    handleChangeUi = (e, result) => {
        const { name, value } = result;
        this.setState({
           [name]: value
          });
      };
    handleChange(event){
        const{name,value,type,checked}=event.target
        type === "checkbox"?this.setState({[name]:checked}):this.setState({[name]:value})
    }
    handleSubmit=(event)=>{
        event.preventDefault()
        const data = this.state
        console.log(data)
    }
    handleChangeDue(value, formattedValue) {
        this.setState({
          value: value, // ISO String, ex: "2016-11-19T12:00:00.000Z"
          formattedValue: formattedValue // Formatted String, ex: "11/19/2016"
        })
      }
      componentDidUpdate() {
        // Access ISO String and formatted values from the DOM.
        var hiddenInputElement = document.getElementById("example-datepicker");
        console.log(hiddenInputElement.value); // ISO String, ex: "2016-11-19T12:00:00.000Z"
        console.log(hiddenInputElement.getAttribute('data-formattedvalue')) // Formatted String, ex: "11/19/2016"
      }
    render(){
        return(
            <div>
            <MPheader/>  
            
            
            <Container fluid className="MainPageRow2">
                <Form onSubmit={this.handleSubmit}>
                    <Form.Row className="Col_margin py-2 px-1">
                        <Col md={3} className="Col_margin px-1">
                            <Form.Label className="checktext">Nome</Form.Label>
                            <Input fluid  placeholder='Nome' onChange={this.handleChangeUi} name="nome" />
                        </Col>
                        <Col md={3} className="Col_margin px-1">
                            <Form.Label className="checktext">Unit</Form.Label>
                            <Select placeholder="" options={unitJson}  onChange={this.handleChangeUi} name="unit" fluid/>
                        </Col>
                        <Col md={3} className="Col_margin px-1">
                            <Form.Label className="checktext">Funzione</Form.Label>
                            <Select placeholder="" options={funzioneJson}  onChange={this.handleChangeUi} name="funzione" fluid/>
                        </Col>
                        <Col md={3} className="Col_margin px-1">
                            <Form.Label className="checktext">Tipo</Form.Label>
                            <Dropdown placeholder='Scegli uno o più tipi' fluid multiple search selection options={tipoJson}/>
                        </Col>
                    </Form.Row>
                    <Form.Row className="Col_margin py-1 px-1">
                        <Col md={2} className="Col_margin px-1">
                            <Form.Label className="checktext">Island</Form.Label>
                            <Select placeholder="" options={islandJson}  onChange={this.handleChangeUi} name="island" fluid/>
                        </Col>
                        <Col md={2} className="Col_margin px-1">
                            <Form.Label className="checktext">Progetto</Form.Label>
                            <Select placeholder="" options={progettoJson}  onChange={this.handleChangeUi} name="progetto" fluid/>
                        </Col>
                        <Col md={2} className="Col_margin px-1">
                            <Form.Label className="checktext">Autore</Form.Label>
                            <Select placeholder="" options={autoreJson}  onChange={this.handleChangeUi} name="autore" fluid/>
                        </Col>
                        <Col md={2} className="Col_margin px-1">
                            <Form.Label className="checktext">Assegnato a</Form.Label>
                            <Select placeholder="" options={assegnatoJson}  onChange={this.handleChangeUi} name="assegnato" fluid/>
                        </Col>
                        <Col md={2} className="Col_margin px-1">
                            <Form.Label className="checktext">Team Member</Form.Label>
                            <Select placeholder="" options={teamJson}  onChange={this.handleChangeUi} name="team" fluid/>
                        </Col>
                        <Col md={2} className="Col_margin px-1">
                            <Form.Label className="checktext">Priorità (Projects Only)</Form.Label>
                            <Dropdown placeholder='Scegli le priorità' fluid multiple search selection options={prioritàJson}/>
                        </Col>
                        
                    </Form.Row>
                    <Form.Row className="Col_margin py-1 px-1">
                        <Col md={2} className="Col_margin px-1">
                            <Form.Label className="checktext">Status</Form.Label>
                            <Select placeholder="" options={statusJson}  onChange={this.handleChangeUi} name="status" fluid/>
                        </Col>
                        <Col md={2} className="Col_margin px-1">
                            <FormGroup>
                                <Label className="checktext">Avvio</Label>
                                <DatePicker id      = "example-datepicker" 
                                            value   = {this.state.avvio} 
                                            onChange= {(v,f) => this.handleChangeDue(v, f)} />
                            </FormGroup>
                        </Col>
                        <Col md={2} className="Col_margin px-1">
                            <FormGroup>
                                <Label className="checktext">Fine</Label>
                                <DatePicker id      = "example-datepicker" 
                                            value   = {this.state.fine} 
                                            onChange= {(v,f) => this.handleChangeDue(v, f)} />
                            </FormGroup>
                        </Col>
                        <Col md={2} className="Col_margin px-1">
                        <Form.Label className="checktext">Raggruppa per</Form.Label>
                        <Select placeholder="" options={vistaJson}  onChange={this.handleChangeUi} name="vista" fluid/>
                        </Col>
                        <Col md={2} className="Col_margin px-1">
                            <Form.Label className="checktext">Risultati per pagina</Form.Label>
                            <Select placeholder="" options={risultatiJson}  onChange={this.handleChangeUi} name="risultati" fluid/>
                        </Col>
                        
                    </Form.Row>
                    <Form.Row className="Col_margin px-1 py-2">
                        <button className="myButton" type="submit" style={{padding:"3px 150px"}}>Filtra</button>
                    </Form.Row>
                </Form>
            </Container>
            <Container fluid className="MainPageRow2">
                <Row className="Col_margin py-4 px-2 text-center">
                    
                </Row>
            </Container>  
            
            </div> 

            )
        }
                    
    }
export default Gantt
