import React, { Component } from 'react'
import MPheaderButton from '../../components/MPheaderButton'
import MappaGoogle from '../../components/MappaGoogle'
import { Container, Row, Col } from 'reactstrap'
import Form from 'react-bootstrap/Form'
import SuppliersSRMTable from '../../components/DataTable/SuppliersSRMTable'

export default class SRMMap extends Component {
    constructor(props){
        super(props)
        this.state={
            nome:"",
            email:"",
            beni:"",
            tipi:"",
            stati:"", 
            nazione:"",
            ris:""
        }
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event){
        const{name,value,type,checked}=event.target
        type === "checkbox"?this.setState({[name]:checked}):this.setState({[name]:value})
    }
    handleSubmit=(event)=>{

    }
    render() {
        return (
            <div>
                <MPheaderButton link="./SRM Map/Aggiungi Contatto" nome="Aggiungi Contatto"/>
                <Container fluid>
                    <Row className="Col_margin py-4 px-1">
                        <Col className="Col_margin px-1" md={6}><MappaGoogle/></Col>
                        <Col className="Col_margin px-1" md={6}>
                            <Form onSubmit={this.handleSubmit}>
                                <Form.Row className="Col_margin py-2 px-1">
                                    <Col md={6} className="Col_margin px-1">
                                        <Form.Label className="checktext">Nome</Form.Label>
                                        <Form.Control type="text" placeholder="Nome" onChange={this.handleChange} value={this.state.nome} name="nome"/>
                                    </Col>
                                    <Col md={6} className="Col_margin px-1">
                                        <Form.Label className="checktext">Email</Form.Label>
                                        <Form.Control type="text" placeholder="Email" onChange={this.handleChange} value={this.state.email} name="email"/>
                                    </Col>
                                </Form.Row>
                                <Form.Row className="Col_margin py-2 px-1">
                                    <Col md={6} className="Col_margin px-1">
                                        <Form.Label className="checktext">Tipi di Fornitura</Form.Label>
                                        <Form.Control as="select" onChange={this.handleChange} value={this.state.tipi} name="tipi">
                                            <option value="Asse">Asse</option>
                                        </Form.Control>
                                    </Col>
                                    <Col md={6} className="Col_margin px-1">
                                        <Form.Label className="checktext">Stati di Fornitura</Form.Label>
                                        <Form.Control as="select" onChange={this.handleChange} value={this.state.stati} name="stati">
                                            <option value=""></option>
                                        </Form.Control>
                                    </Col>
                                </Form.Row>
                                <Form.Row className="Col_margin py-2 px-1">
                                    <Col md={12} className="Col_margin px-1">
                                        <Form.Label className="checktext">Lista dei Beni</Form.Label>
                                        <Form.Control type="text" placeholder="Tag" onChange={this.handleChange} value={this.state.beni} name="beni"/>
                                    </Col>
                                </Form.Row>
                                
                                <Form.Row className="Col_margin py-2 px-1">
                                <Col md={6} className="Col_margin px-1">
                                        <Form.Label className="checktext">Nazione</Form.Label>
                                        <Form.Control as="select" onChange={this.handleChange} value={this.state.nazione} name="nazione">
                                            <option value="Tutte le Nazioni">Tutte le Nazioni</option>
                                        </Form.Control>
                                    </Col>
                                <Col md={6} className="Col_margin px-1">
                                
                                        <Form.Label className="checktext">Risultati per Pagina</Form.Label>
                                        <Form.Control as="select" onChange={this.handleChange} value={this.state.ris} name="ris">
                                            <option value="10">10</option>
                                            <option value="100">100</option>
                                            <option value="1000">1000</option>
                                        </Form.Control>
                                </Col>
                                </Form.Row>
                                <Form.Row className="Col_margin px-1 py-2">
                                    <button className="myButton" type="submit">Filtra</button>
                            </Form.Row>
                            </Form>
                        </Col>
                    </Row>
                    <Row className="Col_margin px-1">
                        <SuppliersSRMTable/>
                    </Row>
                </Container>
                
            </div>
        )
    }
}
