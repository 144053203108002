import React, { Component } from 'react'
import Form from 'react-bootstrap/Form'
import { Container, Row, Col } from 'reactstrap'
import DataValoriTable from '../../components/DataTable/DataValoriTable'
import MPheaderButton from '../../components/MPheaderButton'

export default class ValoriData extends Component {
    constructor(props){
        super(props)
        this.state={
            nome:"",
            tutti:""
        }
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event){
        const{name,value,type,checked}=event.target
        type === "checkbox"?this.setState({[name]:checked}):this.setState({[name]:value})
    }
    handleSubmit=(event)=>{

    } 
    render() {
        return (
            <div>
                <MPheaderButton link="./Valori" nome="Aggiungi Valori"/>
                <Form onSubmit={this.handleSubmit}>
                    <Form.Row className="Col_margin py-2 px-1">
                        <Col md={6} className="Col_margin px-1">
                            <Form.Label className="checktext">Nome</Form.Label>
                            <Form.Control type="text" placeholder="Nome" onChange={this.handleChange} value={this.state.nome} name="nome"/>
                        </Col>
                    </Form.Row>
                    <Form.Row className="Col_margin px-1 py-2">
                        <button className="myButton" type="submit">Filtra</button>
                    </Form.Row>
                </Form>
                <Container fluid>
                    <Row className="Col_margin px-1">
                        <DataValoriTable/>
                    </Row>
                </Container>
            </div>
        )
    }
}