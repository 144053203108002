import React, { Component } from 'react'
import MPheaderButton from '../components/MPheaderButton'
import { Container, Row, Col } from 'react-grid-system'
import {FormGroup, Label} from 'reactstrap'
import { Input,} from 'rsuite';
import getDate from 'date-fns/getDate'
import data from '../Data/Form/ProvaNuovi.json'
import "rsuite/dist/styles/rsuite-default.css"
import SortTable from '../components/SortTable'
import Commento from '../components/Commento'
import Commenti from '../components/Commenti'
import CommentiJson from '../Data/Commenti.json'
import SortTable2 from '../components/SortTable2'
import CalendarioSuite from '../components/CalendarioReact'
import ProjectMilestoneTable from '../components/DataTableNew/ProjectMilestoneTable'

import '../index.css'

//import Example from './Semantic/example'

export default class NuoviForm extends Component {
    constructor(props){
        super(props)
        this.state={
            showCommento:false,
            buttonAggiungi:true,
            annullaCommento:true
        }
        this.handleChange = this.handleChange.bind(this)
    }

    hideComponent(name) {
        console.log(name);
        switch (name) {
          case "showCommento":
            this.setState({ showCommento: true,buttonAggiungi: false });
            break;
          case "annullaCommento":
            this.setState({ showCommento: false,buttonAggiungi: true });
            break;
          case "inviaCommento":
          this.setState({ showCommento: false,buttonAggiungi: true });
          break;
          default:
            this.setState({ buttonAggiungi: true });
            break;
        }
      }
    handleChange(event){
        const{name,value,type,checked}=event.target
        type === "checkbox"?this.setState({[name]:checked}):this.setState({[name]:value})
    }
    handleSubmit=(event)=>{
        event.preventDefault()
        const data = this.state
        console.log(data)
    }
    render() {
        return (
            <div>
                <MPheaderButton link="" nome=""/> 
                <Container fluid>
                    <Row className="Col_margin py-4 px-1">
                        <Col className="Col_margin px-1" md={4}>
                            <div className="box">
                                <label className="labelNuovo">Nome</label>
                                <select>
                                    <option>Ciao</option>
                                    <option>Come stai</option>
                                </select>
                            </div>
                        </Col>
                        <Col className="Col_margin px-1" md={6}>
                            <div className="box">
                                <label className="labelNuovo">Nome</label>
                                <select>
                                    <option>Ciao</option>
                                    <option>Come stai</option>
                                </select>
                            </div>
                        </Col>
                        <Col className="Col_margin px-1" md={2}>
                            <div className="box">
                                <label className="labelNuovo">Nome</label>
                                <select>
                                    <option>Ciao</option>
                                    <option>Come stai</option>
                                </select>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container fluid>
                    <SortTable/>
                </Container>
                <Container fluid className="py-5">
                    {CommentiJson.map(o=> <Commenti testo={o.commento} autore={o.Autore}/>)}
                    {this.state.showCommento && <Commento onClick={(name) => this.hideComponent(name)}/>}
                    {this.state.buttonAggiungi && <button type="submit" className="bottoneCommentiInvia" onClick={() => this.hideComponent("showCommento")}>Aggiungi Commento</button>}
                </Container>
                <Container>
                    <CalendarioSuite/>
                </Container>

            </div>
        )
    }
}
