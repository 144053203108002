import React, { Component } from 'react'
import 'antd/dist/antd.css';
import Form from 'react-bootstrap/Form'
import {Container,Row,Col} from 'react-grid-system';
import MPheader from '../../components/MPheader'

export default class NuovaSellingTip extends Component {
    constructor(props){
        super(props)
        this.state={
            prezzo:"",
            prodotto:"",
            provvigione:"",
            tipi:"",
            listino:""
        }
        this.handleChange = this.handleChange.bind(this)
    }
    handleChangeDue(value, formattedValue) {
        this.setState({
          value: value, // ISO String, ex: "2016-11-19T12:00:00.000Z"
          formattedValue: formattedValue // Formatted String, ex: "11/19/2016"
        })
      }
    handleChange(event){
        const{name,value,type,checked}=event.target
        type === "checkbox"?this.setState({[name]:checked}):this.setState({[name]:value})
    }
    handleSubmit=(event)=>{
        event.preventDefault()
        const data = this.state
        
        console.log(data)
    }
    render() {

        return (
            <div>
                <MPheader/>
                <Container fluid className="MainPageRow2">
                    <Row className="Col_margin py-4 px-2">
                        <Col className="Col_margin px-1">
                            <Form onSubmit={this.handleSubmit}>
                                <Form.Row>
                                    <Col md={6} className="Col_margin px-1">
                                        <Form.Label className="checktext">Prodotto/Servizio</Form.Label>
                                        <Form.Control type="text" placeholder="" onChange={this.handleChange} value={this.state.prodotto} name="prodotto"/>
                                    </Col>
                                </Form.Row>   
                                <Form.Row>
                                    <Col md={6} className="Col_margin px-1 ">
                                        <Form.Label className="checktext">Tipi</Form.Label>
                                        <Form.Control as="select" onChange={this.handleChange} value={this.state.tipi} name="tipi">
                                            <option value="Tutti gli stati">Prodotti</option>
                                            <option value="Lost">Servizio</option>
                                        </Form.Control>
                                    </Col>
                                </Form.Row>                  
                                <Form.Row>
                                    <Col md={6} className="Col_margin px-1 ">
                                        <Form.Label className="checktext">Listino</Form.Label>
                                        <Form.Control as="select" onChange={this.handleChange} value={this.state.tipi} name="tipi">
                                            <option value=""></option>
                                        </Form.Control>
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Col md={6} className="Col_margin px-1">
                                        <Form.Label className="checktext">Prezzo</Form.Label>
                                        <Form.Control type="text" placeholder="" onChange={this.handleChange} value={this.state.prezzo} name="prezzo"/>
                                    </Col>
                                </Form.Row>   
                                <Form.Row>
                                    <Col md={6} className="Col_margin px-1">
                                        <Form.Label className="checktext">Provvigione</Form.Label>
                                        <Form.Control type="text" placeholder="" onChange={this.handleChange} value={this.state.provvigione} name="provvigione"/>
                                    </Col>
                                </Form.Row>
                                <Form.Row className="Col_margin px-1 py-2">
                                        <button className="myButton" type="submit" style={{padding:"3px 150px"}}>Salva</button>
                                </Form.Row>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}