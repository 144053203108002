import React, { Component } from 'react'
import 'antd/dist/antd.css';
import Form from 'react-bootstrap/Form'
import {Container,Row,Col} from 'react-grid-system';
import MPheader from '../../components/MPheader'



export default class AggiungiContattoFundraiser extends Component {
    constructor(props){
        super(props)
        this.state={
            email:"",
            nome:"",
            fisso:"", 
            mobile:"",
            data:"",
            influcence:"",
            fundraiser:"",
            legal:"",
            humans:"",
            suppliers:""

        }
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event){
        const{name,value,type,checked}=event.target
        type === "checkbox"?this.setState({[name]:checked}):this.setState({[name]:value})
    }
    handleSubmit=(event)=>{
        event.preventDefault()
        const data = this.state
        
        console.log(data)
    }
    render() {
        return (
            <div>
                <MPheader/>
                <Container fluid className="MainPageRow2">
                    <Row className="Col_margin py-4 px-2">
                        <Col className="Col_margin px-1">
                            <Form onSubmit={this.handleSubmit}>
                                <Form.Row>
                                    <Col md={4} className="Col_margin py-1 px-1">
                                        <Form.Label className="checktext">Email</Form.Label>
                                        <Form.Control type="text" placeholder="Email" onChange={this.handleChange} value={this.state.email} name="email"/>
                                    </Col>
                                    <Col md={4} className="Col_margin py-1 px-1">
                                        <Form.Label className="checktext">Nome</Form.Label>
                                        <Form.Control type="text" placeholder="Nome" onChange={this.handleChange} value={this.state.nome} name="nome"/>
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Col md={4} className="Col_margin py-1 px-1">
                                        <Form.Label className="checktext">Telefono fisso</Form.Label>
                                        <Form.Control type="text" placeholder="" onChange={this.handleChange} value={this.state.fisso} name="fisso"/>
                                    </Col>
                                    <Col md={4} className="Col_margin py-1 px-1">
                                        <Form.Label className="checktext">Telefono mobile</Form.Label>
                                        <Form.Control type="text" placeholder="" onChange={this.handleChange} value={this.state.mobile} name="mobile"/>
                                    </Col>
                                </Form.Row>
                                <div className="ContenitoreContatto">
                                    <Form.Row>
                                        <Col md={4} className="Col_margin py-1 px-1">
                                                <Form.Label className="checktext">Data</Form.Label>
                                                <Form.Control as="select" onChange={this.handleChange} value={this.state.data} name="data">
                                                    <option value="Si">Si</option>
                                                    <option value="Ni">No</option>
                                                </Form.Control>
                                        </Col>
                                        <Col md={4} className="Col_margin py-1 px-1">
                                            <Form.Label className="checktext">Influence</Form.Label>
                                            <Form.Control as="select" onChange={this.handleChange} value={this.state.influencer} name="influencer">
                                                <option value="Si">Si</option>
                                                <option value="Ni">No</option>
                                            </Form.Control>
                                        </Col>
                                        <Col md={4} className="Col_margin py-1 px-1">
                                            <Form.Label className="checktext">Fundaraiser</Form.Label>
                                            <Form.Control as="select" onChange={this.handleChange} value={this.state.fundraiser} name="fundraiser">
                                                <option value="Si">Si</option>
                                                <option value="Ni">No</option>
                                            </Form.Control>
                                        </Col>
                                    </Form.Row>
                                    <Form.Row>
                                        <Col md={4} className="Col_margin py-1 px-1">
                                                <Form.Label className="checktext">Legal</Form.Label>
                                                <Form.Control as="select" onChange={this.handleChange} value={this.state.legal} name="legal">
                                                    <option value="Si">Si</option>
                                                    <option value="Ni">No</option>
                                                </Form.Control>
                                        </Col>
                                        <Col md={4} className="Col_margin py-1 px-1">
                                            <Form.Label className="checktext">Humans</Form.Label>
                                            <Form.Control as="select" onChange={this.handleChange} value={this.state.humans} name="humans">
                                                <option value="Si">Si</option>
                                                <option value="Ni">No</option>
                                            </Form.Control>
                                        </Col>
                                        <Col md={4} className="Col_margin py-1 px-1">
                                            <Form.Label className="checktext">Suppliers</Form.Label>
                                            <Form.Control as="select" onChange={this.handleChange} value={this.state.suppliers} name="suppliers">
                                                <option value="Si">Si</option>
                                                <option value="Ni">No</option>
                                            </Form.Control>
                                        </Col>
                                    </Form.Row>
                                </div>
                                <Form.Row>
                                    <Col md={3} className="Col_margin py-5"> 
                                        <button className="myButton" type="submit" style={{padding:"3px 150px"}}>Salva</button>
                                    </Col>
                                </Form.Row>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}