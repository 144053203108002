import React from 'react'
import { Container,Col } from 'reactstrap'
import Utente from '../classes/Utente'
import Breadcrumbs from '../components/Breadcrumbs'
const dati = Utente("Ali","Toccacieli")

function MPheader(props){
    return(
        <Container fluid className="MainPage">
                <Col md={12} className="Col_margin"><section className="sectionMain"><Breadcrumbs/></section></Col>
        </Container>
    )
}

export default MPheader