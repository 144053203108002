import React, { Component } from 'react'
import 'antd/dist/antd.css';
import Form from 'react-bootstrap/Form'
import {Container,Row,Col} from 'react-grid-system';
import MPheader from '../../components/MPheader'



export default class AggiungiDem extends Component {
    constructor(props){
        super(props)
        this.state={
            dem:"",
            account:"",
            editor:"", 
            oggetto:"",
            lista:"",
            destinatari:""

        }
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event){
        const{name,value,type,checked}=event.target
        type === "checkbox"?this.setState({[name]:checked}):this.setState({[name]:value})
    }
    handleSubmit=(event)=>{
        event.preventDefault()
        const data = this.state
        
        console.log(data)
    }
    render() {
        return (
            <div>
                <MPheader/>
                <Container fluid className="MainPageRow2">
                    <Row className="Col_margin py-4 px-2">
                        <Col className="Col_margin px-1">
                            <Form onSubmit={this.handleSubmit}>
                                <Form.Row>
                                    <Col md={4} className="Col_margin py-1 px-1">
                                        <Form.Label className="checktext">Manual Dem</Form.Label>
                                        <Form.Control type="text" placeholder="Email" onChange={this.handleChange} value={this.state.dem} name="dem"/>
                                    </Col>
                                    <Col md={4} className="Col_margin py-1 px-1">
                                                <Form.Label className="checktext">Account di Invio</Form.Label>
                                                <Form.Control as="select" onChange={this.handleChange} value={this.state.account} name="account">
                                                    <option value=""></option>
                                                </Form.Control>
                                    </Col>
                                    <Col md={4} className="Col_margin py-1 px-1">
                                                <Form.Label className="checktext">Editor (dopo averlo modificato , salvare)</Form.Label>
                                                <Form.Control as="select" onChange={this.handleChange} value={this.state.editor} name="editor">
                                                    <option value="Classic Editor">Classic Editor</option>
                                                    <option value="HTML">HTML</option>
                                                </Form.Control>
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Col md={12} className="Col_margin py-1 px-1">
                                        <Form.Label className="checktext">Oggetto</Form.Label>
                                        <Form.Control type="text" placeholder="Email" onChange={this.handleChange} value={this.state.oggetto} name="oggetto"/>
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Col md={12} className="Col_margin py-1 px-1">
                                        <Form.Label className="checktext">Lista dal List Manager</Form.Label>
                                        <Form.Control type="text" placeholder="Email" onChange={this.handleChange} value={this.state.lista} name="lista"/>
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Col md={12} className="Col_margin py-1 px-1">
                                        <Form.Label className="checktext">Destinatari aggiuntivi (1 per riga)</Form.Label>
                                        <Form.Control type="text" placeholder="Email" onChange={this.handleChange} value={this.state.destinatari} name="destinatari"/>
                                    </Col>
                                </Form.Row>
                            
                                <Form.Row>
                                    <Col md={3} className="Col_margin py-5"> 
                                        <button className="myButton" type="submit" style={{padding:"3px 150px"}}>Salva</button>
                                    </Col>
                                </Form.Row>
                            </Form>
                        </Col>
                    </Row> 
                </Container>
            </div>
        )
    }
}