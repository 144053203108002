import React, { Component } from 'react'
import { Divider } from 'semantic-ui-react'
import { Container, Row, Col } from 'react-grid-system'
import { RiUploadCloud2Line } from "react-icons/ri";
import CommentiOptions from './CommentiOptions'
import { Form, TextArea,Checkbox } from 'semantic-ui-react'

export default class Commenti extends Component {
    constructor(props){
        super(props)
        this.state ={
            modificaCommento:true,
            salvaCommento:false,
            annullaCommento:false
        }
    }

    hideComponent(name) {
        console.log(name);
        switch (name) {
          case "modificaCommento":
            this.setState({ modificaCommento: false,salvaCommento: true,annullaCommento:true });
            break;
          case "annullaCommento":
            this.setState({ modificaCommento: true,salvaCommento: false,annullaCommento:false});
            break;
          case "salvaCommento":
            this.setState({ modificaCommento: true,salvaCommento: false,annullaCommento:false });
            break;
          default:
            this.setState({ modificaCommento: true });
            break;
        }
      }

    render() {
        return (
            <div className="commentiDiv">
            <section className="commentiSection">
                {this.state.salvaCommento && <CommentiOptions style={{padding:"3px 20px",float: "left",zIndex:"1"}}/>}
                {this.state.modificaCommento && <p className="checktext">{this.props.autore}</p>}
            </section>
            <Container fluid className="py-3" >
                <p className="checktext">{this.props.testo}</p>
                <Form>
                    {this.state.salvaCommento && <TextArea placeholder='A cosa stai pensando' className="textCommenti" style={{ minHeight: 400, resize: "none" }} />}
                    <Divider/>
                    <Row className="noMar"> 
                        <Col md={2} className="noPad">
                            {this.state.modificaCommento && <button type="button" className="bottoneCommentiInvia" onClick={() => this.hideComponent("modificaCommento")}>Modifica</button>}
                            {this.state.salvaCommento && <button type="submit" className="bottoneCommentiInvia" onClick={() => this.hideComponent("salvaCommento")}>Salva</button>}
                        </Col>
                        <Col md={2} className="noPad">
                            {this.state.annullaCommento && <button type="button" className="bottoneCommentiAnnulla" onClick={() => this.hideComponent("annullaCommento")}>Annulla</button>}
                            
                        </Col>
                    </Row>  
                </Form>  
            </Container>
        </div>
        )
        }
    }