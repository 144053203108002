import '@fortawesome/fontawesome-free/css/all.min.css'; 
import 'bootstrap-css-only/css/bootstrap.min.css'; 
import 'mdbreact/dist/css/mdb.css';
import './index.css'
import React,{Component} from 'react';
import { Container, Row, Col} from 'react-grid-system';
import styled from 'styled-components';
import Header from './pages/Header';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Sidebar} from './components/Sidebar';
import Routes from './Routes';
import {BrowserRouter as Router} from "react-router-dom";
import { Footer } from 'rsuite';
import "rsuite/dist/styles/rsuite-default.css"

const Styles = styled.div`
  .bkg{
    background:white;
    background-size:cover;
    background-attachment:fixed;
    position:relative;
    height:100vh;
  }

`;

class App extends Component {
  constructor(props){
    super(props)
    this.state={
      items:[],
      isLoaded:false
    }
  }

  componentDidMount(){
    fetch('')
      .then(res=>res.json())
      .then(json=>{
        this.setState({
          isLoaded:true,
          items:json,
        })
      })
  }

  /* render(){
      var{isLoaded,items}=this.state;
      if (!isLoaded){
        return Carica;
      }
      else{
        return(CORPO);}*/


  render(){
    return (
      <Router>
      <React.Fragment>
        <Styles>
          <Container fluid className="bkg">
            <Row>
              <Col className="Col_Header" ><Header/></Col>
            </Row>
            <Row className="minHeight">
              <Col sm="content" className="Col_Header">
                <Sidebar/>
              </Col>
                
              <Col className="Col_Header_1">
                  <Routes/>
              </Col>
            </Row>
            <Footer>
                <hr class="solid"></hr>
                <p className="checktext footer hui">HUI©<br/></p>
                <p className="checktext footer">Worldwide Distributed Platform for Business Incubation & Acceleration<br/></p> 
                <p className="checktext footer"><a href="#/Privacy" alt="">Privacy | </a><a href="#/Termini" alt="">Termini e Condizioni di Servizi | </a><a href="#/Cookie Policy" alt="">Cookie Policy | </a><a href="#/HUI Blog" alt="">HUI Blog  </a><br/></p>
            </Footer>
          </Container>
        </Styles>
      </React.Fragment>
      </Router>
    
    );
    }
  }
export default App;








