import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import '../index.css';
import MPheaderButton from '../components/MPheaderButton'
import TextForm from '../components/Forms/TextForm';
import SelectDa4 from '../components/Forms/SelectDa4'
import SelectDa3 from '../components/Forms/SelectDa3'
import Form from 'react-bootstrap/Form'
import IslandersHeader from '../components/Islanders/IslandersHeader'
import MarketDiv from '../components/Community/MarketDiv'

import dati from '../Data/Dati.json'






class AttivaCommunity extends React.Component{
    handleSubmit(){
    }
    constructor(props){
        super(props)
        this.state={
            titolo:"",
            costo:"",
            attività:"",
            assets:"",
            descrizione:"",
            search:""
        }
    
    }
    updateSearch(event){
        this.setState({search:event.target.value.substr(0,20)})
    }
    handleSubmit=(event)=>{
        event.preventDefault()
        const data = this.state
        console.log(data)
    }

    render(){
        let offers = dati.filter(
            (offer)=>{return offer.titolo.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1}
        );
        return(
            <div>
            <MPheaderButton link="/Marketing/Marketing/Nuovo prodotto" nome="Aggiungi"/> 
            <IslandersHeader nome="HUI MARKETPLACE" sottoheader="Buy all Hui apps and services and sell your Island’s goods and services"/>
            <Container fluid className="MainPageRow2">
                <Form onSubmit={this.handleSubmit}>
                    <Form.Row className="Col_margin py-5 px-1">
                        <Col md={2} className="Col_margin px-1">
                            <TextForm nome="Nome"/>
                        </Col>
                        <Col md={2} className="Col_margin px-1">
                            <SelectDa4 nome="Island" option1="Tutte" option2="Approvato" option3="In attesa" option4="Rifiutato"/>
                            
                        </Col>
                        <Col md={2} className="Col_margin px-1">
                            <SelectDa4 nome="Attività" option1="Prodotto" option2="Mercato" option3="Foundraising" option4="Corporate"/>
                        </Col>
                        <Col md={2} className="Col_margin px-1">
                            <SelectDa4 nome="Asset" option1="Prodotto" option2="Mercato" option3="Foundraising" option4="Corporate"/>
                        </Col>
                        <Col md={2} className="Col_margin px-1">
                            <SelectDa3 nome="Risultati per Pagina" option1="10" option2="20" option3="30"/>
                        </Col>

                        <Col md={2} className="Col_margin px-1">
                            <Form.Label className="checktext">Cerca</Form.Label>
                            <Form.Control type="text" placeholder={this.props.nome} value={this.state.search} onChange={this.updateSearch.bind(this)} name="cerca"/>
                        </Col>
                    </Form.Row>
                    <Form.Row className="Col_margin px-1 py-2">
                        <button className="myButton" type="submit" style={{marginLeft:"30px",padding:"3px 150px"}}>Filtra</button>
                    </Form.Row>
                </Form>
            </Container>
            <Container fluid className="MainPageRow2">
                <Row className="Col_margin py-1 px-1">
                    {offers.map((offerta)=> <MarketDiv key={offerta.id} titolo={offerta.titolo} costo={offerta.costo} descrizione={offerta.descrizione} attività={offerta.attività} assets={offerta.assets}/>)}
                </Row>

            </Container>  
            </div> 

            )
        }
                    
    }
export default AttivaCommunity 
