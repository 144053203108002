import { Table } from 'rsuite';
import fakeData from '../Data/fakeData.json'
import React, { Component } from 'react'
import { Link } from "react-router-dom"

const { Column, HeaderCell, Cell, Pagination } = Table;


export default class SortTable extends React.Component {
    constructor(props) {
      super(props);
      const data = fakeData.filter((v, i) => i < 20);
      this.state = {
        addColumn: false,
        data
      };
      this.handleSortColumn = this.handleSortColumn.bind(this);
    }
  
    getData() {
      const { data, sortColumn, sortType } = this.state;
  
      if (sortColumn && sortType) {
        return data.sort((a, b) => {
          let x = a[sortColumn];
          let y = b[sortColumn];
          if (typeof x === 'string') {
            x = x.charCodeAt();
          }
          if (typeof y === 'string') {
            y = y.charCodeAt();
          }
          if (sortType === 'asc') {
            return x - y;
          } else {
            return y - x;
          }
        });
      }
      return data;
    }
  
    handleSortColumn(sortColumn, sortType) {
      this.setState({
        loading: true
      });
  
      setTimeout(() => {
        this.setState({
          sortColumn,
          sortType,
          loading: false
        });
      }, 500);
    }
  
    render() {
      return (
        <div>
          <Table
            height={420}
            data={this.getData()}
            sortColumn={this.state.sortColumn}
            sortType={this.state.sortType}
            onSortColumn={this.handleSortColumn}
            loading={this.state.loading}
            onRowClick={data => {
              console.log(data);
            }}
          >
            <Column width={70} align="center" fixed sortable>
              <HeaderCell>Id</HeaderCell>
              <Cell>
                {rowData => (
                    <a className="checktext" href={`/NuoviForm/${rowData.firstName}`}>{rowData.id}</a>
                )}
              </Cell>
            </Column>
  
            <Column width={130} fixed sortable>
              <HeaderCell>First Name</HeaderCell>
              <Cell>
                {rowData => (
                    <a className="checktext" href={`/NuoviForm/${rowData.firstName}`}>{rowData.firstName}</a>
                )}
              </Cell>
            </Column>
  
            <Column width={130} sortable>
              <HeaderCell>Last Name</HeaderCell>
              <Cell>
                {rowData => (
                    <a className="checktext" href={`/NuoviForm/${rowData.firstName}`}>{rowData.lastName}</a>
                )}
              </Cell>
            </Column>
  
            <Column width={200} sortable>
              <HeaderCell>City</HeaderCell>
              <Cell>
                {rowData => (
                    <a className="checktext" href={`/NuoviForm/${rowData.firstName}`}>{rowData.city}</a>
                )}
              </Cell>
            </Column>
  
            <Column width={200}>
              <HeaderCell>Company Name</HeaderCell>
              <Cell>
                {rowData => (
                    <a className="checktext" href={`/NuoviForm/${rowData.firstName}`}>{rowData.companyName}</a>
                )}
              </Cell>
            </Column>
          </Table>
        </div>
      );
    }
  }