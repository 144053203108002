import React, { Component } from 'react'
import MPheaderButton from '../../components/MPheaderButton'
import { Container, Row, Col } from 'reactstrap'
import Form from 'react-bootstrap/Form'
import SalesOpportunitàTable from '../../components/DataTable/SalesOpportunitàTable'
import { Link } from 'react-router-dom'
import { BsFillPlusSquareFill} from "react-icons/bs";
import nazioneJson from '../../Data/Form/Nazione.json'
import autoreJson from '../../Data/Form/Autore.json'
import assegnatoJson from '../../Data/Form/Assegnato.json'

export default class Opportunità extends Component {
    constructor(props){
        super(props)
        this.state={
            nome:"",
            contratto:"",
            tipi:"",
            stati:"",
            listino:"",
            nazione:"",
            ris:"",
            autore:"",
            assegnato:""
        }
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event){
        const{name,value,type,checked}=event.target
        type === "checkbox"?this.setState({[name]:checked}):this.setState({[name]:value})
    }
    handleSubmit=(event)=>{
        event.preventDefault()
        const data = this.state
        console.log(data)
    }
    render() {
        let assegnatoOption = assegnatoJson;
        let autoreOption = autoreJson;
        let nazioneOption = nazioneJson;
        return (
            <div>
                <MPheaderButton link="./Opportunità/Aggiungi Opportunità" nome="Aggiungi Opportunità"/> 

                <Container fluid>
                            <Form onSubmit={this.handleSubmit}>
                                <Form.Row className="Col_margin py-2 px-1">
                                    <Col md={3} className="Col_margin px-1">
                                        <Form.Label className="checktext">Nome</Form.Label>
                                        <Form.Control type="text" placeholder="Nome" onChange={this.handleChange} value={this.state.nome} name="nome"/>
                                    </Col>
                                    <Col md={3} className="Col_margin px-1">
                                        <Form.Label className="checktext">Contratto</Form.Label>
                                        <Form.Control type="text" placeholder="Contratto" onChange={this.handleChange} value={this.state.contratto} name="contratto"/>
                                    </Col>
                                    <Col md={3} className="Col_margin px-1">
                                        <Form.Label className="checktext">Stati di Opportunità</Form.Label>
                                        <Form.Control as="select" onChange={this.handleChange} value={this.state.stati} name="stati">
                                            <option value="Tutti gli stati">Tutti gli stati</option>
                                            <option value="Lost">Lost</option>
                                            <option value="Pending">Pending</option>
                                            <option value="Success">Success</option>
                                        </Form.Control>
                                    </Col>
                                    <Col md={3} className="Col_margin px-1">
                                        <Form.Label className="checktext">Tipi di Opportunità</Form.Label>
                                        <Form.Control as="select" onChange={this.handleChange} value={this.state.tipi} name="tipi">
                                            <option value="Tutti i tipi">Tutti i tipi</option>
                                            <option value="Assistence">Assistence</option>
                                            <option value="Partnership">Partnership</option>
                                            <option value="Sales">Sales</option>
                                        </Form.Control>
                                    </Col>
                                </Form.Row>
                                <Form.Row className="Col_margin py-2 px-1">
                                    <Col md={3} className="Col_margin px-1">
                                        <Form.Label className="checktext">Autore</Form.Label>
                                        <Form.Control as="select" onChange={this.handleChange} value={this.state.autore} name="autore">
                                            {autoreOption.map(o => <option key={o.id} value={o.id}>{o.text}</option>)}
                                        </Form.Control>
                                    </Col>
                                    <Col md={3} className="Col_margin px-1">
                                        <Form.Label className="checktext">Assegnato a</Form.Label>
                                        <Form.Control as="select" onChange={this.handleChange} value={this.state.assegnato} name="assegnato">
                                            {assegnatoOption.map(o => <option key={o.id} value={o.id}>{o.text}</option>)}
                                        </Form.Control>
                                    </Col>
                                    <Col md={3} className="Col_margin px-1">
                                        <Form.Label className="checktext">Listino</Form.Label>
                                        <Form.Control as="select" onChange={this.handleChange} value={this.state.listino} name="listino">
                                            <option value="Tutti i Listini">Tutti i Listini</option>
                                        </Form.Control>
                                    </Col>
                                    <Col md={3} className="Col_margin px-1">
                                        <Form.Label className="checktext">Nazione</Form.Label>
                                        <Form.Control as="select" onChange={this.handleChange} value={this.state.nazione} name="nazione">
                                            {nazioneOption.map(o => <option key={o.id} value={o.id}>{o.text}</option>)}
                                        </Form.Control>
                                    </Col>
                                </Form.Row>
                                <Form.Row className="Col_margin px-1 py-2">
                                <Col md={3} className="Col_margin py-1"> 
                                    <button className="myButton" type="submit" style={{padding:"3px 150px"}}>Filtra</button>
                                </Col>
                            </Form.Row>
                            </Form>
                            <Row className="Col_margin py-5 px-1">
                                <SalesOpportunitàTable/>
                            </Row>
                </Container>
                
            </div>
        )
    }
}
