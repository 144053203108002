import React from 'react';
import Typography from '@material-ui/core/Typography';
import Breadcrumb from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import {withRouter} from 'react-router-dom'
import HomeIcon from '@material-ui/icons/Home';

const Breadcrumbs = props=> {
  const  {history,location} =props;
  const {pathname}=location;
  const pathnames = pathname.split('/').filter(x=>x);

  return (
    <Breadcrumb aria-label="breadcrumb" className="bread">
      <Link className="bread" onClick={()=>history.push("/")}><Typography className="bread">Home</Typography></Link>
      {pathnames.map((name,index)=>{
        //const routeTo = `/${pathnames.slice(0,index+1).join("/")}`
        return <Typography className="bread">{name}</Typography>;//<Link onClick={()=>history.push(routeTo)}>{name}</Link>;

      })}
    </Breadcrumb>
  );
};
export default withRouter(Breadcrumbs)