/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import '../index.css';
import image from '../assets/isola.jpg'
import "react-sweet-progress/lib/style.css";
 






function MarketplaceDiv(props){
        return(
            
            <div className="market">
                <section className="sectionMain"><a href="#/link">{props.descrizione}</a></section>
                
                <div><img src={image} alt=""></img></div>
                <button className="myButton margine" type="submit">Aquista</button>
            </div>
        )
                    
    }
export default MarketplaceDiv