import { Link } from "react-router-dom"
import _ from 'lodash'
import React from 'react'
import { Table } from 'semantic-ui-react'

const tableData = [
  { nome: 'Person1', età: 15, sesso: 'femmina'},
  { nome: 'Person2', età: 40, sesso: 'femmina'},
  { nome: 'Person3', età: 25, sesso: 'maschio' },
  { nome: 'Person4', età: 70, sesso: 'maschio' },
]
const color=['cyan']

function exampleReducer(state, action) {
  switch (action.type) {
    case 'CHANGE_SORT':
      if (state.column === action.column) {
        return {
          ...state,
          data:_.reverse(_.clone(state.data)),
          direction:
            state.direction === 'ascending' ? 'descending' : 'ascending',
        }
      }

      return {
        column: action.column,
        data: _.sortBy(state.data, [action.column]),
        direction: 'ascending',
      }
    default:
      throw new Error()
  }
}

function TableExampleSortable() {
  const [state, dispatch] = React.useReducer(exampleReducer, {
    column: null,
    data: tableData,
    direction: null,
  })
  const { column, data, direction } = state

  return (
    <Table sortable selectable celled fixed color={color}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell
            sorted={column === 'nome' ? direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'nome' })}
          >
            Name
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={column === 'età' ? direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'età' })}
          >
            Età
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={column === 'sesso' ? direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'sesso' })}
          >
            Sesso
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {data.map(({ età, sesso, nome}) => (
          <Table.Row key={nome}>
            <Table.Cell><Link to={`/NuoviForm/${nome}`} className="checktext" style={{textDecoration:"none"}}>{nome}</Link></Table.Cell>
            <Table.Cell><Link to={`/NuoviForm/${nome}`} className="checktext" style={{textDecoration:"none"}}>{età}</Link></Table.Cell>
            <Table.Cell><Link to={`/NuoviForm/${nome}`} className="checktext" style={{textDecoration:"none"}}>{sesso}</Link></Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  )
}

export default TableExampleSortable