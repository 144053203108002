import React, { Component } from 'react'
import 'antd/dist/antd.css';
import Form from 'react-bootstrap/Form'
import {Container,Row,Col} from 'react-grid-system';
import MPheader from '../../components/MPheader'
import BoardTipiTable from '../../components/DataTable/BoardTipiTable'

export default class AggiungiTipiDocumentiBoard extends Component {
    constructor(props){
        super(props)
        this.state={
            tipo:""
        }
        this.handleChange = this.handleChange.bind(this)
    }
    handleChangeDue(value, formattedValue) {
        this.setState({
          value: value, // ISO String, ex: "2016-11-19T12:00:00.000Z"
          formattedValue: formattedValue // Formatted String, ex: "11/19/2016"
        })
      }
    handleChange(event){
        const{name,value,type,checked}=event.target
        type === "checkbox"?this.setState({[name]:checked}):this.setState({[name]:value})
    }
    handleSubmit=(event)=>{
        event.preventDefault()
        const data = this.state
        
        console.log(data)
    }
    render() {

        return (
            <div>
                <MPheader/>
                <Container fluid className="MainPageRow2">
                    <Row className="Col_margin py-4 px-2">
                        <Col className="Col_margin px-1">
                            <Form onSubmit={this.handleSubmit}>
                                <Form.Row>
                                    <Col md={4} className="Col_margin px-1">
                                            <Form.Label className="checktext">Tipo di Documento</Form.Label>
                                            <Form.Control type="text" placeholder="" onChange={this.handleChange} value={this.state.tipo} name="tipo"/>
                                        </Col>
                                
                                </Form.Row>              
                                <Form.Row>
                                    <Col md={3} className="Col_margin py-5"> 
                                        <button className="myButton" type="submit" style={{padding:"3px 150px"}}>Salva</button>
                                    </Col>
                                </Form.Row>
                            </Form>
                        </Col>
                    </Row>
                    <Row className="Col_margin px-2">
                        <BoardTipiTable/>
                    </Row>
                </Container>
            </div>
        )
    }
}