import React from 'react';
import Form from 'react-bootstrap/Form'
import '../../index.css';


class SelectDa3 extends React.Component{
    constructor(props){
        super(props)
        this.state={
            id:"",
        }
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event){
        const{name,value,type,checked}=event.target
        type === "checkbox"?this.setState({[name]:checked}):this.setState({[name]:value})
    }
    render(){
        return(
            <div>
                <Form.Label className="checktext">{this.props.nome}</Form.Label>
                <Form.Control as="select" onChange={this.handleChange} value={this.state.primo} name={this.props.id}>
                    <option value={this.props.option1}>{this.props.option1}</option>
                    <option value={this.props.option2}>{this.props.option2}</option>
                    <option value={this.props.option3}>{this.props.option3}</option>
                </Form.Control>
            </div> 

        )
    }
        
}
export default SelectDa3

//<h2>QUI:{this.state.primo}</h2>