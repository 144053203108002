import React, { Component } from 'react'
import { Container,Col,Row } from 'reactstrap'
import CustomCalendario from '../../components/Sales/CustomCalendario'
import MPheaderButton from '../../components/MPheaderButton'
import Form from 'react-bootstrap/Form'

export default class TodoLegal extends Component {
    constructor(props){
        super(props)
        this.state={
            nome:"",
            assegnato:"",
            contatto:"",
            documento:"",
            inizio:"",
            fine:"",
            stato:"",
            ris:""
        }
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event){
        const{name,value,type,checked}=event.target
        type === "checkbox"?this.setState({[name]:checked}):this.setState({[name]:value})
    }
    handleSubmit=(event)=>{

    }
    render() {
        return (
            <div>
                <MPheaderButton link="./Todo/Aggiungi Todo" nome="Aggiungi Todo"/>
                <Container fluid>
                    <Row className="Col_margin px-1 py-5">
                        <Col className="Col_margin px-1" md={6}>
                            <CustomCalendario/>
                        </Col>
                        <Col className="Col_margin px-1 py-5">
                            <Form onSubmit={this.handleSubmit}>
                                    <Form.Row className="Col_margin py-2 px-1">
                                        <Col md={6} className="Col_margin px-1">
                                            <Form.Label className="checktext">Nome</Form.Label>
                                            <Form.Control type="text" placeholder="Nome" onChange={this.handleChange} value={this.state.nome} name="nome"/>
                                        </Col>
                                        <Col md={6} className="Col_margin px-1">
                                            <Form.Label className="checktext">Asegnato a</Form.Label>
                                            <Form.Control as="select" onChange={this.handleChange} value={this.state.assegnato} name="assegnato">
                                                <option value="Asegnato">Asegnato</option>
                                            </Form.Control>
                                        </Col>
                                    </Form.Row>
                                    <Form.Row className="Col_margin py-2 px-1">
                                    <Col md={6} className="Col_margin px-1">
                                            <Form.Label className="checktext">Contatto</Form.Label>
                                            <Form.Control type="text" placeholder="Nome" onChange={this.handleChange} value={this.state.nome} name="contatto"/>
                                        </Col>
                                        <Col md={6} className="Col_margin px-1">
                                            <Form.Label className="checktext">data da definire</Form.Label>
                                            <Form.Control type="text" placeholder="Nome" onChange={this.handleChange} value={this.state.nome} name="inzio"/>
                                        </Col>
                                    </Form.Row>
                                    <Form.Row className="Col_margin py-2 px-1">
                                    <Col md={6} className="Col_margin px-1">
                                        <Form.Label className="checktext">data da definire</Form.Label>
                                            <Form.Control type="text" placeholder="Nome" onChange={this.handleChange} value={this.state.nome} name="fine"/>
                                        </Col>
                                        <Col md={6} className="Col_margin px-1">
                                        <Form.Label className="checktext">Documento</Form.Label>
                                            <Form.Control as="select" onChange={this.handleChange} value={this.state.documento} name="documento">
                                                <option value="Documento">Documento</option>
                                            </Form.Control>
                                        </Col>
                                    </Form.Row>
                                    <Form.Row className="Col_margin py-2 px-1">
                                        <Col md={6} className="Col_margin px-1">
                                            <Form.Label className="checktext">Stato</Form.Label>
                                            <Form.Control as="select" onChange={this.handleChange} value={this.state.assegnato} name="stato">
                                                <option value="10">Tutte</option>
                                                <option value="100">Scadute</option>
                                                <option value="1000">Aperte</option>
                                                <option value="1000">Chiuse</option>
                                            </Form.Control>
                                        </Col>
                                        <Col md={6} className="Col_margin px-1">
                                            <Form.Label className="checktext">Risultati per pagina</Form.Label>
                                            <Form.Control as="select" onChange={this.handleChange} value={this.state.assegnato} name="ris">
                                                <option value="10">10</option>
                                                <option value="100">100</option>
                                                <option value="1000">1000</option>
                                            </Form.Control>
                                        </Col>
                                    </Form.Row>
                                    <Form.Row className="Col_margin px-1 py-2">
                                        <button className="myButton" type="submit">Filtra</button>
                                </Form.Row>
                                </Form>  
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
