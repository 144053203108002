import React from 'react';
import 'rsuite/lib/styles/index.less';
import '../index.css';
import { MdDashboard} from "react-icons/md";
import { BsClipboardData,BsFillPeopleFill,BsClipboard,BsDot} from "react-icons/bs";
import { GoListUnordered,GoDatabase,GoMailRead} from "react-icons/go";
import {FaUmbrellaBeach,FaProjectDiagram,FaHandsHelping,FaStore} from "react-icons/fa";
import { GiArtificialIntelligence,GiHumanPyramid,GiInjustice } from "react-icons/gi";
import { RiCommunityLine,RiHandHeartLine } from "react-icons/ri";
import {AiOutlineFundProjectionScreen} from "react-icons/ai"; 
import { GiIsland,GiLightBackpack,GiTrophyCup } from "react-icons/gi";




export const Sidebar = () => (
    <nav className="navside">
        <div className="divside">
            <input type="checkbox" id="check1"/>
            <label className="lab" for="check1">.Desk</label>
            <ul>
                <li>
                    <div className="divside">
                        <input type="checkbox" id="check4"/>
                        <label className="lab_2 margine_lab_2" for="check4"><MdDashboard/> Dashboard</label>
                        <ul>
                            <li className="margineUlteriore"><a href="/Dashboard/Overview"><BsDot/>Overview</a></li>
                            <li className="margineUlteriore"><a href="/Dashboard/Todo"><BsDot/>Todo</a></li>
                            <li className="margineUlteriore"><a href="/Dashboard/Eventi"><BsDot/>Eventi</a></li>
                            <li className="margineUlteriore"><a href="/Dashboard/Gantt"><BsDot/>Gantt</a></li>
                            <li className="margineUlteriore"><a href="/Dashboard/Widget"><BsDot/>Widget</a></li>
                        </ul>
                    </div>
                </li>
                <li>
                    <div className="divside">
                        <input type="checkbox" id="check5"/>
                        <label className="lab_2 margine_lab_2" for="check5"><GiArtificialIntelligence/> Intelligence</label>
                        <ul>
                            <li className="margineUlteriore"><a href="/Intellingence/Overview"><BsDot/> Overview</a></li>
                        </ul> 
                    </div>
                </li>
                <li>
                    <div className="divside">
                        <input type="checkbox" id="check6"/>

                        <label className="lab_2 margine_lab_2" for="check6"><RiCommunityLine/> Community</label>
                        <ul>
                            <li className="margineUlteriore"><a href="/Marketing/Marketing"><BsDot/> Attiva Community</a></li> 
                        </ul>
                    </div>
                </li>
                <li>
                    <div className="divside">
                        <input type="checkbox" id="check7"/>
                        <label className="lab_2 margine_lab_2" for="check7"><RiHandHeartLine/> Sales</label>

                        <ul>
                            <li className="margineUlteriore"><a href="/Sales/CRM Map"><BsDot/> CRM Map</a></li> 
                            <li className="margineUlteriore"><a href="/Sales/Opportunità"><BsDot/> Opportunità</a></li> 
                            <li className="margineUlteriore"><a href="/Sales/Provvigioni"><BsDot/> Provvigioni</a></li> 
                            <li className="margineUlteriore"><a href="/Sales/Todo"><BsDot/> Todo</a></li> 
                            <li className="margineUlteriore"><a href="/Sales/Selling"><BsDot/> Selling Tip</a></li> 
                            <li className="margineUlteriore"><a href="/Sales/Grafici"><BsDot/> Grafici</a></li> 
                            <li>
                                <div className="divside">
                                    <input type="checkbox" id="check17"/>
                                    <label className="lab_2 margineUlteriore" for="check17"><BsDot/> Configurazione</label>
                                    <ul>
                                        <li className="margineUlterioreDue"><a href="/Sales/Listino"><BsDot/> Listino</a></li> 
                                        <li className="margineUlterioreDue"><a href="/Sales/Prodotti"><BsDot/> Prodotti</a></li> 
                                        <li className="margineUlterioreDue"><a href="/Sales/Tipi"><BsDot/> Tipi</a></li> 
                                        <li className="margineUlterioreDue"><a href="/Sales/Stati"><BsDot/> Stati</a></li> 
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                </li>
                <li>
                    <div className="divside">
                        <input type="checkbox" id="check8"/>
                        <label className="lab_2 margine_lab_2" for="check8"><GoDatabase/> Data</label>
                        <ul>
                            <li className="margineUlteriore"><a href="/Data/Rubrica"><BsDot/> Rubrica</a></li> 
                            <li className="margineUlteriore"><a href="/Data/Liste"><BsDot/> Liste</a></li> 
                            <li className="margineUlteriore"><a href="/Data/DB Need"><BsDot/> DB Need</a></li> 
                            <li className="margineUlteriore"><a href="/Data/Grafici"><BsDot/> Grafici</a></li>
                            <li>
                                <div className="divside">
                                    <input type="checkbox" id="check18"/>
                                    <label className="lab_2 margineUlteriore" for="check18"><BsDot/> Configurazione</label>
                                    <ul>
                                        <li className="margineUlterioreDue"><a href="/Data/Categorie"><BsDot/> Categorie</a></li> 
                                        <li className="margineUlterioreDue"><a href="/Data/Campi"><BsDot/> Campi</a></li> 
                                        <li className="margineUlterioreDue"><a href="/Data/Valori"><BsDot/> Valori</a></li> 
                                        <li className="margineUlterioreDue"><a href="/Data/PDI"><BsDot/> PDI</a></li> 
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                </li>
                <li>
                    <div className="divside">
                        <input type="checkbox" id="check9"/>
                        <label className="lab_2 margine_lab_2" for="check9"><GoMailRead/> Mail</label>
                        <ul>
                            <li className="margineUlteriore"><a href="/Mail/AutoDem"><BsDot/> AutoDem(Read)</a></li> 
                            <li className="margineUlteriore"><a href="/Mail/Manual DEM"><BsDot/> Manual DEM</a></li> 
                            <li className="margineUlteriore"><a href="/Mail/Account di Invio"><BsDot/> Account di Invio</a></li> 
                             
                        </ul>
                    </div>
                </li>
                <li>
                    <div className="divside">
                        <input type="checkbox" id="check10"/>
                        <label className="lab_2 margine_lab_2" for="check10"><BsClipboardData/> Docs</label>
                        <ul>
                            <li className="margineUlteriore"><a href="/Docs/Documenti"><BsDot/> Documenti</a></li> 
                        </ul>
                    </div>
                </li>
                <li>
                    <div className="divside">
                        <input type="checkbox" id="check11"/>
                        <label className="lab_2 margine_lab_2" for="check11"><AiOutlineFundProjectionScreen/> Fundraiser</label>
                        <ul>
                            <li className="margineUlteriore"><a href="/Fundaraiser/FRM Map"><BsDot/> FRM Map</a></li> 
                            <li className="margineUlteriore"><a href="/Fundaraiser/FN"><BsDot/> FN</a></li> 
                            <li className="margineUlteriore"><a href="/Fundaraiser/FRO"><BsDot/> FRO</a></li> 
                            <li className="margineUlteriore"><a href="/Fundaraiser/Documenti"><BsDot/> Documenti</a></li> 
                            <li className="margineUlteriore"><a href="/Fundaraiser/Todo"><BsDot/> Todo</a></li> 
                            <li className="margineUlteriore"><a href="/Fundaraiser/Grafici"><BsDot/> Grafici</a></li> 
                            <li>
                                <div className="divside">
                                    <input type="checkbox" id="check19"/>
                                    <label className="lab_2 margineUlteriore" for="check19"><BsDot/> Configurazione</label>
                                    <ul>
                                        <li className="margineUlterioreDue"><a href="/Fundaraiser/Tipi di Documento"><BsDot/> Tipi di Documento</a></li> 
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                </li>
                <li>
                    <div className="divside">
                        <input type="checkbox" id="check12"/>
                        <label className="lab_2 margine_lab_2" for="check12"><BsClipboard/> Board</label>
                        <ul>
                            <li className="margineUlteriore"><a href="/Board/Company"><BsDot/> Company</a></li> 
                            <li className="margineUlteriore"><a href="/Board/Milestone"><BsDot/> Milestone</a></li> 
                            <li className="margineUlteriore"><a href="/Board/Todo"><BsDot/> Todo</a></li> 
                            <li className="margineUlteriore"><a href="/Board/Documenti"><BsDot/> Documenti</a></li> 
                            <li>
                                <div className="divside">
                                    <input type="checkbox" id="check20"/>
                                    <label className="lab_2 margineUlteriore" for="check20"><BsDot/> Configurazione</label>
                                    <ul>
                                        <li className="margineUlterioreDue"><a href="/Board/Tipi di Documento"><BsDot/> Tipi di Documento</a></li> 
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                </li>
                <li>
                    <div className="divside">
                        <input type="checkbox" id="check13"/>
                        <label className="lab_2 margine_lab_2" for="check13"><GiHumanPyramid/> Humans</label>
                        <ul>
                            <li className="margineUlteriore"><a href="/Humans/HRM Map"><BsDot/> HRM Map</a></li> 
                            <li className="margineUlteriore"><a href="/Humans/Opportunities"><BsDot/> Opportunities</a></li> 
                            <li className="margineUlteriore"><a href="/Humans/Todo"><BsDot/> Todo</a></li>  
                        </ul>
                    </div>
                </li>
                <li>
                    <div className="divside">
                        <input type="checkbox" id="check14"/>
                        <label className="lab_2 margine_lab_2" for="check14"><GiInjustice/> Legal</label>
                        <ul>
                            <li className="margineUlteriore"><a href="/Legal/Legal Map"><BsDot/> Legal Map</a></li> 
                            <li className="margineUlteriore"><a href="/Legal/Position"><BsDot/> Position</a></li> 
                            <li className="margineUlteriore"><a href="/Legal/Documenti"><BsDot/> Documenti</a></li> 
                            <li className="margineUlteriore"><a href="/Legal/Todo"><BsDot/> Todo</a></li> 
                            <li>
                                <div className="divside">
                                    <input type="checkbox" id="check21"/>
                                    <label className="lab_2 margineUlteriore" for="check21"><BsDot/> Configurazione</label>
                                    <ul>
                                        <li className="margineUlterioreDue"><a href="/Legal/Company"><BsDot/> Company</a></li> 
                                        <li className="margineUlterioreDue"><a href="/Legal/Direzioni"><BsDot/> Direzioni</a></li> 
                                        <li className="margineUlterioreDue"><a href="/Legal/Fori"><BsDot/> Fori</a></li> 
                                        <li className="margineUlterioreDue"><a href="/Legal/Diritti"><BsDot/> Diritti</a></li> 
                                        <li className="margineUlterioreDue"><a href="/Legal/Livelli"><BsDot/> Livelli</a></li> 
                                        <li className="margineUlterioreDue"><a href="/Legal/Tipi di Documento"><BsDot/> Tipi di Documento</a></li> 
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                </li>
                <li>
                    <div className="divside">
                        <input type="checkbox" id="check2"/>
                        <label className="lab_2 margine_lab_2" for="check2"><FaProjectDiagram/> Projects</label>
                        <ul>
                            <li className="margineUlteriore"><a href="/Projects/Attivita"><BsDot/> Attività</a></li>
                            <li className="margineUlteriore"><a href="/Projects/Progetti"><BsDot/> Progetti</a></li>
                            <li className="margineUlteriore"><a href="/Projects/Milestone"><BsDot/> Milestone</a></li>
                            <li className="margineUlteriore"><a href="/Projects/Todo"><BsDot/> Todo</a></li> 
                            
                        </ul>
                    </div>
                </li>
                <li>
                    <div className="divside">
                        <input type="checkbox" id="check15"/>
                        <label className="lab_2 margine_lab_2" for="check15"><BsFillPeopleFill/> Influencer</label>
                        <ul>
                            <li className="margineUlteriore"><a href="/Influence/PRM Map"><BsDot/> PRM Map</a></li> 
                            <li className="margineUlteriore"><a href="/Influence/PRN"><BsDot/> PRN</a></li> 
                            <li className="margineUlteriore"><a href="/Influence/PRO"><BsDot/> PRO</a></li> 
                            <li className="margineUlteriore"><a href="/Influence/Contenuti"><BsDot/> Contenuti</a></li> 
                            <li className="margineUlteriore"><a href="/Influence/Todo"><BsDot/> Todo</a></li> 
                            <li className="margineUlteriore"><a href="/Influence/Grafici"><BsDot/> Grafici</a></li>
                            <li>
                                <div className="divside">
                                    <input type="checkbox" id="check22"/>
                                    <label className="lab_2 margineUlteriore" for="check22"><BsDot/> Configurazione</label>
                                    <ul>
                                        <li className="margineUlterioreDue"><a href='/Influence/Reach-Età'><BsDot/> Reach-Età</a></li> 
                                        <li className="margineUlterioreDue"><a href="/Influence/Reach-Campi"><BsDot/> Reach-Campi</a></li> 
                                        <li className="margineUlterioreDue"><a href="/Influence/Reach-Tipi"><BsDot/> Reach-Tipi</a></li> 
                                        
                                    </ul>
                                </div>
                            </li> 
                        </ul>
                    </div>
                </li>
                <li>
                    <div className="divside">
                        <input type="checkbox" id="check16"/>
                        <label className="lab_2 margine_lab_2" for="check16"><FaHandsHelping/> Suppliers</label>
                        <ul>
                            <li className="margineUlteriore"><a href="/Suppliers/SRM Map"><BsDot/> SRM Map</a></li> 
                            <li className="margineUlteriore"><a href="/Suppliers/Fornitura"><BsDot/> Fornitura</a></li> 
                            <li className="margineUlteriore"><a href="/Suppliers/Todo"><BsDot/> Todo</a></li> 
                            <li className="margineUlteriore"><a href="/Suppliers/Grafici"><BsDot/> Grafici</a></li> 
                            <li>
                                <div className="divside">
                                    <input type="checkbox" id="check23"/>
                                    <label className="lab_2 margineUlteriore" for="check23"><BsDot/> Configurazione</label>
                                    <ul>
                                        <li className="margineUlterioreDue"><a href='/Suppliers/Lista Beni'><BsDot/> Lista Beni</a></li> 
                                        <li className="margineUlterioreDue"><a href="/Suppliers/Prodotti"><BsDot/> Prodotti</a></li> 
                                        <li className="margineUlterioreDue"><a href="/Suppliers/Tipi"><BsDot/> Tipi</a></li> 
                                        <li className="margineUlterioreDue"><a href="/Suppliers/Stati"><BsDot/> Stati</a></li> 
                                        
                                    </ul>
                                </div>
                            </li> 
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
        <div className="divside">

            <input type="checkbox" id="check3"/>
            <label className="lab" for="check3">.Play</label>
            <ul>

                <li className="margine_lab_2"><a href="/Play/Wall"><GoListUnordered/> Wall</a></li>
                <li className="margine_lab_2"><a href="/Play/Island"> <GiIsland/> Islands</a></li>  
                <li className="margine_lab_2"><a href="/Play/Islanders"><FaUmbrellaBeach/> Ilanders</a></li>
                <li className="margine_lab_2"><a href="/Play/Archivements"><GiTrophyCup/> Archivements</a></li>   
                <li className="margine_lab_2"><a href="/Play/Opportunities"><GiLightBackpack/> Opportunities</a></li>   
                <li className="margine_lab_2"><a href="/Play/Marketplace"><FaStore/> Marketplace</a></li>  
            </ul>
        </div>
    </nav>

    

)