import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import '../../index.css';
import Form from 'react-bootstrap/Form'
import TextForm from '../../components/Forms/TextForm';
import islandJson from '../../Data/Ui/island.json'
import appJson from '../../Data/Ui/app.json'
import WidgetTable from '../../components/DataTable/WidgetTable'
import MPheaderButton from '../../components/MPheaderButton'
import { Select,Input } from 'semantic-ui-react'

class Widget extends React.Component{
    constructor(props){
        super(props)
        this.state={

            nome:"",
            app:"",
            island:""

        }
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event){
        const{name,value,type,checked}=event.target
        type === "checkbox"?this.setState({[name]:checked}):this.setState({[name]:value})
    }
    handleSubmit=(event)=>{
        event.preventDefault()
        const data = this.state
        console.log(data)
    }   
    handleChangeUi = (e, result) => {
        const { name, value } = result;
        this.setState({
           [name]: value
          });
      };
    render(){
        return(
            <div>
            <MPheaderButton link="/Dashboard/Aggiungi widget" nome="Aggiungi Widget"/> 
            
            
            <Container fluid className="MainPageRow2">
                <Form onSubmit={this.handleSubmit}>
                    <Form.Row className="Col_margin py-2 px-1">
                        <Col md={9} className="Col_margin px-1">
                            <Form.Label className="checktext">Nome</Form.Label>
                            <Input fluid  placeholder='Nome' onChange={this.handleChangeUi} name="nome" />
                        </Col>
                    </Form.Row>
                    <Form.Row className="Col_margin py-1 px-1">
                        <Col md={3} className="Col_margin px-1">
                            <Form.Label className="checktext">Island</Form.Label>
                            <Select placeholder="" options={islandJson}  onChange={this.handleChangeUi} name="island" fluid/>
                        </Col>
                        <Col md={6} className="Col_margin px-1">
                            <Form.Label className="checktext">App</Form.Label>
                            <Select placeholder="" options={appJson}  onChange={this.handleChangeUi} name="app" fluid/>
                        </Col>
                        <Col md={3} className="Col_margin px-1">
                        </Col>
                    </Form.Row>
                    <Form.Row className="Col_margin px-1 py-4">
                                    <button className="myButton" type="submit" style={{padding:"3px 150px"}}>Filtra</button>
                    </Form.Row>
                </Form>
            </Container>
            <Container fluid className="MainPageRow2">
                <Row className="Col_margin py-4 px-2 text-center">
                    <WidgetTable/>
                </Row>
            </Container>   

            </div> 

            )
        }
                    
    }
export default Widget