/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import '../../index.css';
import image from '../../assets/isola.jpg'
 






class IslandersDiv extends React.Component{
    render(){
        return(
            <div className="card">
                <a href="/Profilo"><img src={image} alt="John" width="100%" /></a>
                <h3>Ali Toccacieli</h3><hr/>
                <p className="checktext">Islands su cui è approdato</p><hr/>
                <p className="checktext">Items</p>
            </div>
        )
                    
    }
}
export default IslandersDiv