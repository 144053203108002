import React from 'react';
import styled from 'styled-components';
import hui from '../../assets/hui.png';
import {FaUmbrellaBeach} from "react-icons/fa";
import SimplePopover from '../SimplePopover'

const Styles = styled.div`
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800');
@font-face {
  font-family: 'Lexend Deca';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/lexend-deca-v1-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Lexend Deca Regular'), local('LexendDeca-Regular'),
       url('./fonts/lexend-deca-v1-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/lexend-deca-v1-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/lexend-deca-v1-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./fonts/lexend-deca-v1-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/lexend-deca-v1-latin-regular.svg#LexendDeca') format('svg'); /* Legacy iOS */
}

    .dropdown{
        display:inline-block;
        text-decoration: none;
        border: none;
        outline: 0 !important;
    }

    .dropdown button{
    padding:30px 70px;
    background:url(${hui});
    transition:.3s;
    background-repeat: no-repeat;
    background-size:cover;
    text-decoration: none;
    border: none;
    outline: 0 !important;
    }

    .dropdown button:hover{  
        text-decoration: none;
        border: none;
        outline: 0 !important;

    }
    
    
    .dropdown div{
    background-color:rgb(102, 153, 153, .4);
    z-index:1;
    visibility:hidden;
    min-width:100%;
    transition:.3s;
    width:30px;
    text-decoration: none;
    border: none;
    outline: 0 !important;
    }
    
    .dropdown:hover div{
    visibility:visible;
    text-decoration: none;
    border: none;
    outline: 0 !important;
    }
    
    .dropdown div a{
    font-family: 'Lexend Deca', sans-serif;
    background-color:white;
    display:block;
    padding:2px;
    font-size:12px;
    text-align:center;
    color:#009BDF;
    transition:.1s;
    white-space:nowrap;
    text-decoration: none;
    border: none;
    outline: 0 !important;

    }
    
    .dropdown div a:hover{
    background-color:#009BDF;
    color:#fff;
    text-decoration: none;
    border: none;
    outline: 0 !important;
    }


@keyframes color{
  0%
  {
    background: #00c5df;
  }
  20%
  {
    background: #00bedf;
  }
  40%
  {
    background: #00dfcd;
  }
  60%
  {
    background: #00df9c;
  }
  80%
  {
    background: #00df82;
  }
  100%
  {
    background: #00c5df;
  }
}



`;


export const Logo = (props) => (
    <Styles>

        <div className="dropdown">
            <button></button>
                <div>
                    <a href="/Nuova Isola"><FaUmbrellaBeach/> Crea una nuova isola</a>
                </div>
        </div>
        <div className="floatta">
            <SimplePopover/>
        </div>        
        

    </Styles>
)