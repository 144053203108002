import React, { Component } from 'react'
import MPheaderButton from '../../components/MPheaderButton'
import { Container, Row, Col } from 'reactstrap'
import Form from 'react-bootstrap/Form'
import FNTable from '../../components/DataTable/FNTable'

export default class FN extends Component {
    constructor(props){
        super(props)
        this.state={
            nome:"",
            assegnato:"",
            autore:"",
            da:"",
            a:"",
            ris:""
        }
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event){
        const{name,value,type,checked}=event.target
        type === "checkbox"?this.setState({[name]:checked}):this.setState({[name]:value})
    }
    handleSubmit=(event)=>{

    }
    render() {
        return (
            <div>
                <MPheaderButton link="./FN/Aggiungi FN" nome="Aggiungi FN"/>  
                <Container fluid>
                        <Form onSubmit={this.handleSubmit}>
                            <Form.Row className="Col_margin py-2 px-1">
                                <Col md={3} className="Col_margin px-1">
                                    <Form.Label className="checktext">Nome</Form.Label>
                                    <Form.Control type="text" placeholder="Nome" onChange={this.handleChange} value={this.state.nome} name="nome"/>
                                </Col>
                                <Col md={3} className="Col_margin px-1">
                                    <Form.Label className="checktext">Importo FN(da)</Form.Label>
                                    <Form.Control type="text" placeholder="Nome" onChange={this.handleChange} value={this.state.tag} name="da"/>
                                </Col>
                                <Col md={3} className="Col_margin px-1">
                                    <Form.Label className="checktext">Importo FN(a)</Form.Label>
                                    <Form.Control type="text" placeholder="Nome" onChange={this.handleChange} value={this.state.tag} name="a"/>
                                </Col>
                            </Form.Row>
                            <Form.Row className="Col_margin py-2 px-1">
                                <Col md={3} className="Col_margin px-1">
                                        <Form.Label className="checktext">Autore</Form.Label>
                                        <Form.Control as="select" onChange={this.handleChange} value={this.state.nazione} name="autore">
                                            <option value="Autori">Autori</option>
                                        </Form.Control>
                                    </Col>
                                <Col md={3} className="Col_margin px-1">
                                        <Form.Label className="checktext">Assegnato a</Form.Label>
                                        <Form.Control as="select" onChange={this.handleChange} value={this.state.ris} name="assegnato">
                                            <option value="Assegnato">Assegnato</option>
                                        </Form.Control>
                                </Col>
                                <Col md={3} className="Col_margin px-1">
                                        <Form.Label className="checktext">Risultati per pagina</Form.Label>
                                        <Form.Control as="select" onChange={this.handleChange} value={this.state.ris} name="ris">
                                            <option value="10">10</option>
                                            <option value="100">100</option>
                                            <option value="1000">1000</option>
                                        </Form.Control>
                                </Col>
                            </Form.Row>
                            <Form.Row className="Col_margin px-1 py-2">
                                <button className="myButton" type="submit">Filtra</button>
                            </Form.Row>
                    </Form>
                    <Row className="Col_margin px-1">
                        <FNTable/>
                    </Row>
            </Container>
                
        </div>
        )
    }
}
