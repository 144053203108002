import React, { Component } from 'react'
import { Container,Col,Row } from 'reactstrap'
import CustomCalendario from '../../components/Sales/CustomCalendario'
import MPheaderButton from '../../components/MPheaderButton'
import Form from 'react-bootstrap/Form'

export default class TodoBoard extends Component {
    constructor(props){
        super(props)
        this.state={
            nome:"",
            unit:"",
            company:"",
            inizio:"",
            fine:"",
            effort:"",
            qeffort:"",
            stato:"",
            ris:""
        }
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event){
        const{name,value,type,checked}=event.target
        type === "checkbox"?this.setState({[name]:checked}):this.setState({[name]:value})
    }
    handleSubmit=(event)=>{

    }
    render() {
        return (
            <div>
                <MPheaderButton link="./Todo/Aggiungi Todo" nome="Aggiungi Todo"/>
                <Container fluid>
                    <Row className="Col_margin px-1 py-5">
                        <Col className="Col_margin px-1" md={6}>
                            <CustomCalendario/>
                        </Col>
                        <Col className="Col_margin px-1 py-5">
                            <Form onSubmit={this.handleSubmit}>
                                    <Form.Row className="Col_margin py-2 px-1">
                                        <Col md={6} className="Col_margin px-1">
                                            <Form.Label className="checktext">Nome</Form.Label>
                                            <Form.Control type="text" placeholder="Nome" onChange={this.handleChange} value={this.state.nome} name="nome"/>
                                        </Col>
                                        <Col md={6} className="Col_margin px-1">
                                            <Form.Label className="checktext">Unit</Form.Label>
                                            <Form.Control as="select" onChange={this.handleChange} value={this.state.unit} name="unit">
                                                <option value="Unit">Unit</option>
                                            </Form.Control>
                                        </Col>
                                    </Form.Row>
                                    <Form.Row className="Col_margin py-2 px-1">
                                        <Col md={6} className="Col_margin px-1">
                                            <Form.Label className="checktext">Autore</Form.Label>
                                            <Form.Control as="select" onChange={this.handleChange} value={this.state.autore} name="autore">
                                                <option value="Autore">Autore</option>
                                            </Form.Control>
                                        </Col>
                                        <Col md={6} className="Col_margin px-1">
                                            <Form.Label className="checktext">Assegnato a</Form.Label>
                                            <Form.Control as="select" onChange={this.handleChange} value={this.state.assegnato} name="assegnato">
                                                <option value="Assegnato">Assegnato</option>
                                            </Form.Control>
                                        </Col>
                                    </Form.Row>
                                    <Form.Row className="Col_margin py-2 px-1">
                                        <Col md={6} className="Col_margin px-1">
                                        <Form.Label className="checktext">Company</Form.Label>
                                            <Form.Control as="select" onChange={this.handleChange} value={this.state.company} name="company">
                                                <option value="Company">Company</option>
                                            </Form.Control>
                                        </Col>
                                    </Form.Row>
                                    <Form.Row className="Col_margin py-2 px-1">
                                        <Col md={6} className="Col_margin px-1">
                                            <Form.Label className="checktext">Data inizio</Form.Label>
                                            <Form.Control type="text" placeholder="inzio" onChange={this.handleChange} value={this.state.inzio} name="inzio"/>
                                        </Col>
                                        <Col md={6} className="Col_margin px-1">
                                            <Form.Label className="checktext">Data fine</Form.Label>
                                            <Form.Control type="text" placeholder="fine" onChange={this.handleChange} value={this.state.fine} name="fine"/>
                                        </Col>
                                    </Form.Row>
                                        <Form.Row className="Col_margin py-2 px-1">
                                        <Col md={6} className="Col_margin px-1">
                                            <Form.Label className="checktext">Effort</Form.Label>
                                            <Form.Control as="select" onChange={this.handleChange} value={this.state.effort} name="effort">
                                                <option value="Effort">Effort</option>
                                            </Form.Control>
                                        </Col>
                                        <Col md={6} className="Col_margin px-1">
                                            <Form.Label className="checktext">Qualità Effort</Form.Label>
                                            <Form.Control as="select" onChange={this.handleChange} value={this.state.qeffort} name="qeffort">
                                                <option value="Effort">Effort</option>
                                            </Form.Control>
                                        </Col>
                                    </Form.Row>
                                    <Form.Row className="Col_margin py-2 px-1">
                                        <Col md={6} className="Col_margin px-1">
                                            <Form.Label className="checktext">Stato</Form.Label>
                                            <Form.Control as="select" onChange={this.handleChange} value={this.state.stato} name="stato">
                                                <option value="Stato">Stato</option>
                                            </Form.Control>
                                        </Col>
                                        <Col md={6} className="Col_margin px-1">
                                            <Form.Label className="checktext">Risultati per pagina</Form.Label>
                                            <Form.Control as="select" onChange={this.handleChange} value={this.state.assegnato} name="ris">
                                                <option value="10">10</option>
                                                <option value="100">100</option>
                                                <option value="1000">1000</option>
                                            </Form.Control>
                                        </Col>
                                    </Form.Row>
                                    
                                    <Form.Row className="Col_margin px-1 py-2">
                                        <button className="myButton" type="submit">Filtra</button>
                                </Form.Row>
                                </Form>  
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
